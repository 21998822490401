import { createSlice } from '@reduxjs/toolkit';

export const retrievalSlice = createSlice({
  name: 'retrieval',

  initialState: {
    list: [],
  },
  reducers: {
    setRetrieval: (state, action) => {
      console.log('setRetrieval', action.payload);
      state.list = action.payload.list;
    },
  },
});

export const { setRetrieval } = retrievalSlice.actions;

export default retrievalSlice.reducer;
