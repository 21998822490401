import React, { useContext, useEffect } from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import NavbarPhone from "../components/NavbarPhone";
import ContentTitlePhone from "../components/ContentTitlePhone";
import FooterPhone from "../components/FooterPhone";
import HomePhone from "./HomePhone";
import TopicPhone from "./topicPhone";
import BannerPhone from "./BannerPhone";
import { useAuth } from "../utility/useAuth";
import { MainContext } from "../utility/context";

export default function Index() {
    const [_, dispatchContext] = useContext(MainContext);
    const auth = useAuth(true);

    useEffect(() => {
        dispatchContext({ type: "setAuth", value: auth });
    }, [])

    return (
        <>
            <div className="container-fluid pb-4">
                <ContentTitlePhone />
                <NavbarPhone category="首页" totalFlg />
                <Router>
                    <Switch>
                        <Route exact path="/">
                            <HomePhone />
                        </Route>
                        <Route exact path="/首页/banner/:id">
                            <BannerPhone />
                        </Route>
                        <Route exact path="/首页/:id/">
                            <TopicPhone />
                        </Route>
                    </Switch>
                </Router>
            </div>
            <FooterPhone />
        </>
    );
}
