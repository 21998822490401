import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import NavbarPhone from '../components/NavbarPhone';
import reducer from '../reducer';

export const Sidebar = ({ category }) => {
  const [state, dispatch] = React.useReducer(reducer, { length: 0 });

  React.useEffect(() => {
    // 通知/公告
    fetch('/api/bulletin?option=bulletin-by-tag&tag=企业用户')
      .then((response) => response.json())
      .then((data) => {
        dispatch({
          type: 'set',
          payload: { key: 'length', value: data.length || 0 },
        });
        // console.log(data, 'bulletin');
      });
  }, []);
  return (
    <div className="list-group bg-white shadow sidebar signin-right-input-phone" style={{ fontSize: 12 }}>
      <a
        href="#消息/会话"
        className={`list-group-item list-group-item-action border-0 ${category === '会话' && 'text-primary'
          }`}
      >
        我的会话
        <span className="pull-right">
          <FontAwesomeIcon icon={faAngleRight} fixedWidth />
        </span>
      </a>
      <a
        href="#消息/邀请"
        className={`list-group-item list-group-item-action border-0 ${category === '邀请' && 'text-primary'
          }`}
      >
        面试邀请
        <span className="pull-right">
          <FontAwesomeIcon icon={faAngleRight} fixedWidth />
        </span>
      </a>
      <a
        href="#消息/系统"
        className={`list-group-item list-group-item-action border-0 ${category === '系统' && 'text-primary'
          }`}
      >
        平台消息
        {state.length > 0 ? (
          <span className="badge badge-pill badge-danger">{state.length}</span>
        ) : (
          <></>
        )}
        <span className="pull-right">
          <FontAwesomeIcon icon={faAngleRight} fixedWidth />
        </span>
      </a>
    </div>
  );
};

Sidebar.propTypes = {
  category: PropTypes.string.isRequired,
};

export const View = ({ totalFlg, category, children }) => (
  <>
    <NavbarPhone category="消息" totalFlg={totalFlg} />
    <div className="row px-1 mt-4">
      <div className="col-md-2 pb-2">
        <Sidebar category={category} />
      </div>
      <div className="col-md-10">{children}</div>
    </div>
  </>
);
View.propTypes = {
  category: PropTypes.string.isRequired,
  totalFlg: PropTypes.bool,
  children: PropTypes.element.isRequired,
};

View.defaultProps = {
  totalFlg: undefined,
};
