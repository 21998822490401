import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { Sidebar } from './ComponentsPhone';
import moment from "moment";
import { saveJournal } from '../commonFetch';

const Invitation = () => {
  const { id } = useParams();
  const auth = JSON.parse(sessionStorage.getItem('auth'));
  const [sendin, setSendin] = useState({ id: 0 });
  const [job, setJob] = useState({ id: 0 });
  const [resume, setResume] = useState({ id: 0 });
  const [openid, setOpenid] = React.useState(0);
  const [required, setRequired] = React.useState({
    address: false,
    phone1: false,
    // luxian: false,
    mianshishijian: false,
  });

  useEffect(() => {
    fetch(`/api/simple/biz/sendin/${id}`)
      .then((response) => {
        if (response.status < 400) return response.json();
        else throw new Error('请求数据失败');
      })
      .then((data) => {
        setSendin(data);
      })
      .catch((err) => window.console.error(err));
  }, [id]);

  useEffect(() => {
    // 获取投递记录后，获取简历
    if (sendin.id > 0) {
      fetch(`/api/simple/biz/resume/${sendin.resumeId}/${sendin.resumeUuid}`)
        .then((response) => {
          if (response.status < 400) return response.json();
          else throw new Error('请求数据失败');
        })
        .then((data) => {
          setResume(data);
        })
        .catch((err) => window.console.error(err));
      fetch(`/api/simple/biz/job/${sendin.jobId}/${sendin.jobUuid}`)
        .then((response) => {
          if (response.status < 400) return response.json();
          else throw new Error('请求数据失败');
        })
        .then((data) => {
          setJob(data);
        })
        .catch((err) => window.console.error(err));
    }
  }, [sendin]);

  useEffect(() => {
    // 获取简历对应用户的wx_openid
    if (resume.id > 0) {
      fetch(`/api/simple/biz/candidate/${resume.commonUserId}`)
        .then((response) => {
          if (response.status < 400) return response.json();
          else throw new Error('请求数据失败');
        })
        .then((data) => {
          console.log(data);
          setOpenid(data.wxOpenId);
        })
        .catch((err) => window.console.error(err));
    }
  }, [resume]);

  const handleInvite = () => {
    const auth1 = JSON.parse(sessionStorage.getItem('auth'));
    const param = {
      recruitment_id: job.id,
      common_user_id: resume.commonUserId,
      address: document.getElementById('address').value,
      phone1: document.getElementById('phone1').value,
      phone2: document.getElementById('phone2').value,
      luxian: document.getElementById('luxian').value,
      mianshishijian: document.getElementById('datime').value,
      remark: document.getElementById('remark').value,
    };
    const _jsonData = {
      touser: openid,
      mp_template_msg: {
        appid: 'wx79586a354703320a',
        template_id: 'fj6JMuZS4A7ZaJb5S0pgDHhQrXNqcQFNf9s5qO8uoMc',
        url: 'http://weixin.qq.com/download',
        miniprogram: {
          appid: 'wxbf9bb377ed519ed8',
          pagepath: '',
        },
        data: {
          first: {
            value: `亲，很高兴通知您请在${moment(param.mianshishijian).format(
              'YYYY-MM-DD H:mm',
            )}到${auth1.name}面试${job.name}职位`,
            color: '#173177',
          },
          keyword1: {
            value: param.address,
            color: '#173177',
          },
          keyword2: {
            value: param.phone1,
            color: '#173177',
          },
          remark: {
            value: '请您进入小程序查看具体详情！',
            color: '#173177',
          },
        },
      },
    };
    let flg = false;
    const _req = {};
    Object.getOwnPropertyNames(required).forEach((key) => {
      if (!param[key] || param[key] === '') {
        _req[key] = '请填写内容!';
        flg = true;
      } else {
        _req[key] = false;
      }
    });

    if (flg) {
      setRequired(_req);
      return;
    }

    if (moment().isSameOrAfter(moment(param.mianshishijian))) {
      _req.mianshishijian = '无效的时间';
      setRequired(_req);
      return;
    }

    fetch('/api/wx-minip/token/', {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify(_jsonData),
    });

    fetch('./api/offer/', {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify(param),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.content) {
          window.alert('已发出面试邀请,请到消息确认');
          saveJournal({
            ref_id: auth.id,
            ref_id2: resume.id,
            category: '邀请面试',
            tag: '企业用户',
            ref_uuid: auth.uuid,
            ref_uuid2: resume.uuid,
          });
          fetch(`/gin-api/sendin/${id}`, {
            method: 'PUT',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({ ...sendin, status: '已邀请' }),
          })
            .then((response) => {
              if (response.status >= 400) throw new Error('请求数据失败');
            })
            .catch((err) => window.console.error(err));
          // fetch('./api/delivery/status/', {
          //   method: 'PUT',
          //   headers: { 'content-type': 'application/json' },
          //   body: JSON.stringify({
          //     id,
          //     status: '已邀请',
          //   }),
          // });
          window.history.back();
        }
      });
  };

  return (
    <div className="row px-1 mt-4">
      <div className="col-md-2 pb-2">
        <Sidebar category="列表" />
      </div>
      <div className="col-md-10">
        <div className="bg-white shadow signin-right-input-phone">
          <div className="card signin-right-input-phone">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <h5 className="pull-left"><strong>面试邀请</strong></h5>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col" style={{ color: "#4a4a4a", fontSize: 12 }}>
                  <div className="form-group">
                    <label>面试职位</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      defaultValue={job.name}
                      readOnly
                    />
                  </div>
                  <div className="form-group">
                    <span className="text-danger">*</span>
                    <label>联系电话1</label>
                    <input
                      id="phone1"
                      type="text"
                      className={`form-control form-control-sm ${required.phone1 ? 'is-invalid' : ''
                        }`}
                    />
                    <div className="invalid-feedback">{required.phone1}</div>
                  </div>
                  <div className="form-group">
                    <label>联系电话2(选填)</label>
                    <input
                      id="phone2"
                      type="text"
                      className="form-control form-control-sm"
                    />
                  </div>
                  <div className="form-group">
                    <span className="text-danger">*</span>
                    <label>面试时间</label>
                    <input
                      id="datime"
                      type="datetime-local"
                      className={`form-control form-control-sm
        ${required.mianshishijian ? 'is-invalid' : ''}`}
                    />
                    <div className="invalid-feedback">{required.mianshishijian}</div>
                  </div>
                  <div className="form-group">
                    <span className="text-danger">*</span>
                    <label>面试地点</label>
                    <textarea
                      id="address"
                      className={`form-control form-control-sm ${required.address ? 'is-invalid' : ''
                        }`}
                    />
                    <div className="invalid-feedback">{required.address}</div>
                  </div>
                  <div className="form-group">
                    {/* <span className="text-danger">*</span> */}
                    <label>交通路线</label>
                    <textarea
                      id="luxian"
                      className={`form-control form-control-sm ${required.luxian ? 'is-invalid' : ''
                        }`}
                    />
                    {/* <div className="invalid-feedback">{required.luxian}</div> */}
                  </div>
                  <div className="form-group">
                    <label>备注</label>
                    <textarea id="remark" className="form-control form-control-sm" />
                  </div>
                </div>
              </div>
              <div className="">
                <button
                  onClick={handleInvite}
                  className="btn btn-primary w-100 signin-right-input-phone"
                  style={{ fontSize: 12 }}
                  type="button"
                >
                  确定
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Invitation;