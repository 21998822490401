import React, { useContext, useEffect, useReducer, useState } from "react";
import reducer from "../reducer";
import {
  TextField1,
  TextField,
  SelectField,
  SelectField1,
} from "../components/InputField";
import RichEditor from "../components/RichEditor";
import { View1 } from "./Components";
import { MainContext } from "../utility/context";

export default function Save() {
  const [_, dispatchContext] = useContext(MainContext);
  // eslint-disable-next-line
  const [state, dispatch] = useReducer(reducer, {
    INDUSTRY_LIST: [],
    POSITION_LIST: [],
    SETTING_LIST: [],
  });
  const auth = JSON.parse(sessionStorage.getItem("auth"));
  const [data, setData] = useState({
    name: "",
    category: "",
    industry: "",
    position: "",
    status: "",
    education: "",
    salary1: "",
    salary2: "",
    qty: "",
    address1: "",
    address2: "",
    address3: "",
    description: "",
    requirement: "",
  });
  const [required, setRequired] = useState({
    name: false,
    salary1: false,
    salary2: false,
    address1: false,
    address2: false,
    address3: false,
  });
  const [city, setCity] = useState([]);
  const [area, setArea] = useState([]);
  const [level, setLevel] = useState([]);
  const [address, setAddress] = useState([]);
  const [number, setNumber] = useState(0);
  const [number1, setNumber1] = useState(0);
  const handleChange = (e) => {
    const { value, name } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };
  const handleIntroChange = (e) => {
    const { value, name } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
    const value1 = value.replace(/<\/?.+?>/g, "");
    setNumber(value1.length);
    if (value1.length > 200) {
      document.getElementById("number").style.color = "#ff2121";
      document.getElementById("hide").style.display = "block";
      document.getElementById("preservation").disabled = true;
    } else {
      document.getElementById("number").style.color = "#7a858c";
      document.getElementById("hide").style.display = "none";
      document.getElementById("preservation").disabled = false;
    }
  };
  const handleIntroChange1 = (e) => {
    const { value, name } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
    const value1 = value.replace(/<\/?.+?>/g, "");
    setNumber1(value1.length);
    if (value1.length > 200) {
      document.getElementById("number1").style.color = "#ff2121";
      document.getElementById("hide1").style.display = "block";
    } else {
      document.getElementById("number1").style.color = "#7a858c";
      document.getElementById("hide1").style.display = "none";
    }
  };
  const handleSave = () => {
    let flg = false;
    const _req = {};
    Object.getOwnPropertyNames(required).forEach((key) => {
      if (!data[key] || data[key] === "") {
        if (data.address2 === "东莞市" || data.address3 === "中山市") {
          _req[key] = false;
        } else {
          _req[key] = "请填写内容!";
          flg = true;
        }
      } else {
        _req[key] = false;
      }
    });
    if (flg) {
      window.console.info(_req);
      setRequired(_req);
      return;
    }
    const reg = /^[0-9]*[1-9][0-9]*$/;
    if (reg.test(data.salary1) === false) {
      setRequired({ salary1: "格式错误，请您重新输入" });
      return;
    } else if (reg.test(data.salary2) === false) {
      setRequired({ salary2: "格式错误，请您重新输入" });
      return;
    }
    // fetch("./api/recruitment/", {
    fetch("/gin-api/job", {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({
        ...data,
        employerId: auth.ref_id,
        employerUuid: auth.ref_uuid,
        addressLevel1: data.address1,
        addressLevel2: data.address2,
        addressLevel3: data.address3,
      })
    })
      .then((response) => {
        if (response.status < 400) {
          alert("操作成功");
          dispatchContext({ type: "setJobList", value: null });
          window.location.href = "#岗位/列表";
        } else {
          alert("请您检查填写信息是否规范");
        }
      })
  };
  const handleProvince = (e) => {
    const { value } = e.target;
    window.console.info(value);
    if (value !== "") {
      setData({
        ...data,
        address1: value,
        address2: "",
        address3: "",
      });
      const a1 = level.find((item) => item.name === value);
      if (a1) {
        setCity(
          Object.getOwnPropertyNames(address)
            .filter(
              (it) =>
                a1.code.slice(0, 2) === it.slice(0, 2) &&
                it.slice(4, 7) === "00" &&
                it !== a1.code
            )
            .map((code) => ({
              code,
              name: address[code],
            }))
        );
      }
      setArea([]);
    } else {
      setData({
        ...data,
        address1: value,
        address2: value,
        address3: value,
      });
      setCity([]);
      setArea([]);
    }
  };
  const handleCity = (e) => {
    const { value } = e.target;
    if (value !== "") {
      setData({
        ...data,
        address2: value,
        address3: "",
      });
      const a2 = city.find((item) => item.name === value);
      if (a2) {
        setArea(
          Object.getOwnPropertyNames(address)
            .filter((it) => a2.code.slice(0, 4) === it.slice(0, 4) && it !== a2.code)
            .map((code) => ({
              code,
              name: address[code],
            }))
        );
      }
    } else {
      setData({
        ...data,
        address2: value,
        address3: value,
      });
      setArea([]);
    }
  };

  useEffect(() => {
    fetch("/crate-api/setting?option=filterBy-category-refId&category=行业&refId=0")
      .then((response) => response.json())
      .then((setting) => {
        dispatch({
          type: "set",
          payload: { key: "INDUSTRY_LIST", value: setting },
        });
        let industry = setting.find((element) => element.detail.name === data["industry"]);
        if (industry !== undefined) {
          fetch(
            `/crate-api/setting?option=filterBy-category-refId&category=行业&refId=${industry.id}`
          )
            .then((response) => response.json())
            .then((data) => {
              dispatch({
                type: "set",
                payload: { key: "POSITION_LIST", value: data },
              });
            });
        }
      });
  }, [data["industry"]]);

  useEffect(() => {
    const auth = JSON.parse(sessionStorage.getItem("auth"));
    if (auth !== null) {
      setData((p) => ({
        ...p,
        enterprise_id: auth.ref_id,
        enterprise_uuid: auth.ref_uuid,
        user_id: auth.id,
      }));
    }
    fetch("/lib/address.json")
      .then((res) => res.json())
      .then((res) => {
        setAddress(res);
        setLevel(
          Object.getOwnPropertyNames(res)
            .filter((item) => item.slice(2, 7) === "0000")
            .map((code) => ({
              code,
              name: res[code],
            }))
        );
      });
  }, []);

  useEffect(() => {
    if (number > 200 || number1 > 200) {
      document.getElementById("preservation").disabled = true;
    } else {
      document.getElementById("preservation").disabled = false;
    }
  }, [number, number1]);

  return (
    <View1 category="我的职位">
      <div className="row bg-white shadow signin-right-input">
        <div className="col card signin-right-input">
          <div className="card-body">
            <div className="row">
              <div className="col">
                <h4 className="pull-left" style={{ color: "#4a4a4a" }}><strong>新增岗位</strong></h4>
                <p className="text-danger pt-2">(带有*符号的选项为必填项)</p>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col">
                <TextField1
                  category="职位名称"
                  name="name"
                  value={data.name}
                  req={required.name}
                  handleChange={handleChange}
                  required
                />
              </div>
              <div className="col mb-3">
                <label className="form-label">所属行业</label>
                <select
                  value={data.industry}
                  name="industry"
                  className="form-control form-control-sm signin-right-input"
                  onChange={handleChange}
                >
                  <option value={data.industry}>{data.industry}</option>
                  {0 < state.INDUSTRY_LIST.length &&
                    state.INDUSTRY_LIST.map((current, index) => (
                      <option key={index} value={current.detail.name}>
                        {current.detail.name}
                      </option>
                    ))}
                  <option value=""></option>
                </select>
              </div>
              <div className="col mb-3">
                <label className="form-label">职位</label>
                <select
                  value={data.position}
                  name="position"
                  className="form-control form-control-sm signin-right-input"
                  onChange={handleChange}
                >
                  <option value={data.position}>{data.position}</option>
                  {0 < state.POSITION_LIST.length &&
                    state.POSITION_LIST.map((current, index) => (
                      <option key={index} value={current.detail.name}>
                        {current.detail.name}
                      </option>
                    ))}
                  <option value=""></option>
                </select>
              </div>
              <div className="col">
                <SelectField
                  category="职位类型"
                  name="category"
                  value={data.category}
                  handleChange={handleChange}
                >
                  <option> </option>
                  <option>全职</option>
                  <option>兼职</option>
                  <option>实习</option>
                </SelectField>
              </div>
              <div className="col">
                <SelectField
                  category="学历要求"
                  name="education"
                  value={data.education}
                  handleChange={handleChange}
                >
                  <option> </option>
                  <option>不限</option>
                  <option>高中及以上</option>
                  <option>大专及以上</option>
                  <option>本科及以上</option>
                  <option>硕士及以上</option>
                  <option>博士</option>
                </SelectField>
              </div>
              <div className="col">
                <TextField
                  category="招聘人数"
                  name="qty"
                  value={data.qty}
                  handleChange={handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-2">
                <SelectField1
                  category="省"
                  name="address1"
                  value={data.address1}
                  req={required.address1}
                  handleChange={handleProvince}
                  required
                >
                  <option> </option>
                  {level.map((item) => (
                    <option key={item.name}>{item.name}</option>
                  ))}
                </SelectField1>
              </div>
              <div className="col-2">
                <SelectField1
                  category="市"
                  name="address2"
                  value={data.address2}
                  req={required.address2}
                  handleChange={handleCity}
                  required
                >
                  <option> </option>
                  {city.map((item, inx) => (
                    <option key={item.id + inx.toString()}>{item.name}</option>
                  ))}
                </SelectField1>
              </div>
              <div className="col-2">
                <SelectField1
                  category="区/县"
                  name="address3"
                  value={data.address3}
                  req={required.address3}
                  handleChange={handleChange}
                  required
                >
                  <option> </option>
                  {area.map((item, index) => (
                    <option key={index}>{item.name}</option>
                  ))}
                </SelectField1>
              </div>
              <div className="col-4">
                <label>
                  <span className="text-danger">*</span>
                  薪资要求
                  <span className="text-danger">(例如：4000-6000)</span>
                </label>
                <div className="row pl-3 pr-3">
                  <input
                    type="text"
                    name="salary1"
                    value={data.salary1}
                    onChange={handleChange}
                    className={`col form-control form-control-sm signin-right-input ${required.salary1 ? "is-invalid" : ""
                      }`}
                  />
                  <div className="invalid-feedback col-3">{required.salary1}</div>
                  -
                  <input
                    type="text"
                    name="salary2"
                    value={data.salary2}
                    onChange={handleChange}
                    className={`col form-control form-control-sm signin-right-input ${required.salary2 ? "is-invalid" : ""
                      }`}
                  />
                  <div className="invalid-feedback col-3">{required.salary2}</div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label>职位要求</label>
                  <RichEditor
                    value={data.requirement}
                    name="requirement"
                    handleChange={handleIntroChange}
                  />
                  <span
                    id="number"
                    className="pull-right"
                    style={{ color: "#7a858c" }}
                  >
                    {number}/200
                  </span>
                  <span
                    id="hide"
                    className="pull-left"
                    style={{ color: "#ff2121", display: "none" }}
                  >
                    请输入1-200个字符
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label>工作内容</label>
                  <RichEditor
                    value={data.description}
                    name="description"
                    handleChange={handleIntroChange1}
                  />
                  <span
                    id="number1"
                    className="pull-right"
                    style={{ color: "#7a858c" }}
                  >
                    {number1}/200
                  </span>
                  <span
                    id="hide1"
                    className="pull-left"
                    style={{ color: "#ff2121", display: "none" }}
                  >
                    请输入1-200个字符
                  </span>
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col">
                <a className="btn btn-primary signin-right-input" href="#岗位/列表">
                  返回
                </a>
              </div>
              <div className="col">
                <button
                  id="preservation"
                  className="pull-right btn btn-success signin-right-input"
                  onClick={handleSave}
                  type="button"
                >
                  保存
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </View1>
  );
}
