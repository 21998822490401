import React, { useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { View } from "./Components";
import reducer from "../reducer";
import dayjs from "dayjs";

const ChatRow = ({ name, active, total, text, handleClick, handleKeyDown }) => (
  <div
    className={`row chat-item border-bottom ${active && "chat-item-active"} `}
    onClick={handleClick}
    onKeyDown={handleKeyDown}
    role="button"
    tabIndex="0"
  >
    <div className="col p-3">
      <div className="chat-text-box">
        {name}
        <br />
        <span className="text-muted">
          {!active && total !== 0 ? (
            <span className="badge badge-pill badge-danger">{total}</span>
          ) : (
            <></>
          )}{" "}
          &nbsp;
          {text}
        </span>
      </div>
    </div>
  </div>
);

ChatRow.propTypes = {
  name: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  total: PropTypes.bool,
  text: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleKeyDown: PropTypes.func,
};

ChatRow.defaultProps = {
  total: undefined,
  handleKeyDown: undefined,
};

const LetfMessage = ({ name, datime, content }) => (
  <div className="row p-2">
    <div className="col">
      <div className="pull-left message-text shadow border rounded p-2">
        {name} {datime}
        <br />
        &nbsp;&nbsp;&nbsp;
        {content}
      </div>
    </div>
  </div>
);

LetfMessage.propTypes = {
  name: PropTypes.string.isRequired,
  datime: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

const RightMessage = ({ name, datime, content }) => (
  <div className="row p-2">
    <div className="col">
      <div className="pull-right message-text shadow border rounded p-2">
        {name} {datime}
        <br />
        &nbsp;&nbsp;&nbsp;
        {content}
      </div>
    </div>
  </div>
);

RightMessage.propTypes = {
  name: PropTypes.string.isRequired,
  datime: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

const Audition = () => {
  const [text, setText] = React.useState("");
  const auth = JSON.parse(sessionStorage.getItem("auth"));
  const [state, dispatch] = React.useReducer(reducer, {
    UNREAD: [],
    READ: [],
    progress: 0,
    conversation: [],
    content: [],
    title: "",
    subscriber_id: 0,
  });

  useEffect(() => {
    let timer1 = setTimeout(() => {
      let url = [
        "/api/miscellaneous/message",
        "?option=by-ref_id-ref_id2-category",
        `&ref_id=${state.subscriber_id}`,
        `&ref_id2=${auth.id}`,
        "&category=咨询企业",
      ];
      fetch(url.join(""))
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          dispatch({ type: "set", payload: { key: "content", value: data } });
        });
    }, 2000)
    return () => clearTimeout(timer1);
  }, [state.content]);

  const handlePick = (event) => {
    dispatch({ type: "set", payload: { key: "content", value: [] } });
    dispatch({
      type: "set",
      payload: {
        key: "subscriber_id",
        value: event.target.getAttribute("data-subscriber-id"),
      },
    });
    dispatch({
      type: "set",
      payload: {
        key: "title",
        value: event.target.getAttribute("data-subscriber-name"),
      },
    });
    let id = event.target.getAttribute("data-subscriber-id");
    let url = [
      "/api/miscellaneous/message",
      "?option=by-ref_id-ref_id2-category",
      `&ref_id=${id}`,
      `&ref_id2=${auth.id}`,
      "&category=咨询企业",
    ];
    fetch(url.join(""))
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        dispatch({ type: "set", payload: { key: "content", value: data } });
      });
  };
  const handleKeyDown = (event) => {
    if (event.keyCode !== 13) return;
    handleReply();
  };
  const handleReply = () => {
    fetch("/api/miscellaneous/message", {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({
        ref_id: auth.id,
        ref_id2: state.subscriber_id,
        dtime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
        category: "咨询企业",
        tag: "企业用户",
        content: text,
      }),
    }).then((response) => {
      if (response.status === 200) {
        setText("");
        let url = [
          "/api/miscellaneous/message",
          "?option=by-ref_id-ref_id2-category",
          `&ref_id=${state.subscriber_id}`,
          `&ref_id2=${auth.id}`,
          "&category=咨询企业",
        ];
        fetch(url.join(""))
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
            dispatch({ type: "set", payload: { key: "content", value: data } });
          });
      } else alert("发送失败，请您稍后重试");
    });
  };
  const handleChange = (event) => {
    setText(event.target.value.trim());
  };

  useEffect(() => {
    if (state.progress === 0) {
      let url = [
        "/api/miscellaneous/message",
        "?option=group-ref_id-by-ref_id2-tag-category-status",
        `&ref_id2=${auth.id}`,
        "&tag=个人用户",
        "&category=咨询企业",
        "&status=未读",
      ];
      fetch(url.join(""))
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          dispatch({ type: "set", payload: { key: "UNREAD", value: data } });
          dispatch({
            type: "set",
            payload: { key: "progress", value: state.progress + 1 },
          });
        });
    } else if (state.progress === 1) {
      const list = state.UNREAD.map((current) => current.ref_id).join(",");
      let url = [
        "/api/miscellaneous/message",
        "?option=group-ref_id-by-ref_id2-tag-category-exclude_list",
        `&ref_id2=${auth.id}`,
        "&tag=个人用户",
        "&category=咨询企业",
        `&list=${list || 0}`,
      ];
      fetch(url.join(""))
        .then((response) => response.json())
        .then((data) => {
          dispatch({ type: "set", payload: { key: "READ", value: data } });
          dispatch({
            type: "set",
            payload: { key: "progress", value: state.progress + 1 },
          });
        });
    } else if (state.progress === 2) {
      const a = state.UNREAD.map((current) => current.ref_id);
      const b = state.READ.map((current) => current.ref_id);
      if (a.length > 0 || b.length > 0) {
        let url = [
          "/api/miscellaneous/message",
          "?option=group-ref_id2-by-ref_id-tag-category-exclude_list",
          `&ref_id=${auth.id}`,
          "&tag=企业用户",
          "&category=咨询企业",
          `&list=${a.concat(b).join(",")}`,
        ];
        fetch(url.join(""))
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
            dispatch({
              type: "set",
              payload: { key: "READ", value: state.READ.concat(data) },
            });
            dispatch({
              type: "set",
              payload: { key: "progress", value: state.progress + 1 },
            });
          });
      }
    } else if (state.progress === 3) {
      const a = state.UNREAD.map((current) => current.ref_id);
      const b = state.READ.map((current) => {
        if (current.tag === "个人用户") return current.ref_id;
        else if (current.tag === "企业用户") return current.ref_id2;
      });
      fetch(`/gin-api/resume?option=filterBy-refIds&refIds=${a.concat(b).join(",")}`)
        .then((response) => {
          if (response.status < 400) return response.json();
          else throw new Error("请求数据失败");
        })
        .then((data) => {
          let lt = [];
          state.UNREAD.forEach((current) => {
            const r = data.find((element) => element.commonUserId === current.ref_id);
            if (r !== null && r !== undefined) {
              if (r.name) {
                lt.push({
                  id: current.id,
                  ref_id: r.commonUserId,
                  name: r.name,
                  dtime: current.dtime,
                  status: current.status,
                  tag: current.tag,
                  content: current.content,
                  qty: current.qty || 0,
                });
              } else {
                lt.push({
                  id: current.id,
                  ref_id: r.commonUserId,
                  name: "该用户暂未填写姓名",
                  dtime: current.dtime,
                  status: current.status,
                  tag: current.tag,
                  content: current.content,
                  qty: current.qty || 0,
                });
              }
            }
            else {
              lt.push({
                id: current.id,
                ref_id: 0,
                name: "该用户暂未创建简历",
                dtime: current.dtime,
                status: current.status,
                tag: current.tag,
                content: current.content,
                qty: current.qty,
              });
            }
          });
          dispatch({ type: "set", payload: { key: "UNREAD", value: lt } });
          const ltRead = [];
          state.READ.forEach((current) => {
            const r = data.find((element) => element.commonUserId === current.ref_id);
            if (r !== undefined) {
              if (r.name) {
                ltRead.push({
                  id: current.id,
                  ref_id: r.commonUserId,
                  name: r.name,
                  dtime: current.dtime,
                  status: current.status,
                  tag: current.tag,
                  qty: current.qty || 0,
                });
              } else {
                ltRead.push({
                  id: current.id,
                  ref_id: r.commonUserId,
                  name: "该用户暂未填写姓名",
                  dtime: current.dtime,
                  status: current.status,
                  tag: current.tag,
                  qty: current.qty || 0,
                });
              }
            }
          });
          dispatch({ type: "set", payload: { key: "READ", value: ltRead } });
          dispatch({
            type: "set",
            payload: { key: "progress", value: state.progress + 1 },
          });
        })
        .catch((err) => window.console.error(err));
    }
  }, [state.progress]);

  useEffect(() => {
    const div = document.getElementById("chat-body");
    div.scrollTop = div.scrollHeight;
  }, [state.content]);

  useEffect(() => {
    if (state.content.length === 0) return;
    let timer = setTimeout(() => {
      const lt = state.content
        .filter((element) => element.tag === "个人用户")
        .map((current) => current.id);
      let url = [`/api/miscellaneous/message/${auth.id}`, "?option=status-by-id_list"];
      fetch(url.join(""), {
        method: "PUT",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({
          id_list: lt.join(","),
          status: "已读",
        }),
      }).then((response) => {
        if (response.status === 200) {
          let i = state.UNREAD.findIndex(
            (element) => (element.ref_id2 = state.subscriber_id)
          );
          let lt = [];
          state.UNREAD.forEach((current, index) => {
            if (i === index) current.qty = 0;
            lt.push(current);
          });
          dispatch({ type: "set", payload: { key: "UNREAD", value: lt } });
        }
      });
    }, 2000);
    return () => clearTimeout(timer);
  }, [state.content]);

  return (
    <View category="会话">
      <div className="row bg-white shadow">
        <div className="col-2 border">
          <div className="row  border-bottom">
            <div className="col p-2">近期消息</div>
          </div>
          <div className="row chat-list">
            <div className="col">
              {state.progress === 4 &&
                state.UNREAD.map((current) => (
                  <div
                    className={`row chat-item border-bottom ${current.active && "chat-item-active"
                      } `}
                    onClick={handlePick}
                    onKeyDown={handleKeyDown}
                    role="button"
                    tabIndex="0"
                    key={current.ref_id}
                    data-subscriber-id={current.ref_id}
                    data-subscriber-name={current.name}
                  >
                    <div
                      className="col p-3"
                      data-subscriber-id={current.ref_id}
                    >
                      <div
                        className="chat-text-box"
                        data-subscriber-id={current.ref_id}
                        data-subscriber-name={current.name}
                      >
                        {current.name}
                        <br />
                        <span
                          className="text-muted"
                          data-subscriber-id={current.ref_id}
                          data-subscriber-name={current.name}
                        >
                          {" "}
                          {!current.active && current.qty !== 0 ? (
                            <span
                              className="badge badge-pill badge-danger"
                              data-subscriber-id={current.ref_id}
                              data-subscriber-name={current.name}
                            >
                              {current.qty}
                            </span>
                          ) : (
                            <></>
                          )}{" "}
                          &nbsp;
                          {current.content}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              {state.progress === 4 &&
                state.READ.map((current) => (
                  <div
                    className={`row chat-item border-bottom ${current.active && "chat-item-active"
                      } `}
                    onClick={handlePick}
                    onKeyDown={handleKeyDown}
                    role="button"
                    tabIndex="0"
                    key={current.ref_id}
                    data-subscriber-id={current.ref_id}
                    data-subscriber-name={current.name}
                  >
                    <div
                      className="col p-3"
                      data-subscriber-id={current.ref_id}
                    >
                      <div
                        className="chat-text-box"
                        data-subscriber-id={current.ref_id}
                        data-subscriber-name={current.name}
                      >
                        {current.name}
                        <br />
                        <span
                          className="text-muted"
                          data-subscriber-id={current.ref_id}
                          data-subscriber-name={current.name}
                        >
                          {" "}
                          {!current.active && current.qty !== 0 ? (
                            <span
                              className="badge badge-pill badge-danger"
                              data-subscriber-id={current.ref_id}
                              data-subscriber-name={current.name}
                            >
                              {current.qty}
                            </span>
                          ) : (
                            <></>
                          )}{" "}
                          &nbsp;
                          {current.content}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="col-10 border">
          {state.subscriber_id ? (
            <div className="row  border-bottom">
              <div className="col text-center p-2">{state.title || "姓名"}</div>
            </div>
          ) : (
            ""
          )}
          <div id="chat-body" className="row border-bottom chat-body">
            <div className="col mt-3">
              {state.content.length > 0 &&
                state.content.map((current) => {
                  return current.ref_id2 === Number(auth.id) ? (
                    <LetfMessage
                      key={current.id}
                      name={state.title}
                      datime={moment(current.dtime).format(
                        "YYYY-MM-DD HH:mm"
                      )}
                      content={current.content}
                    />
                  ) : (
                    <RightMessage
                      key={current.id}
                      name="我"
                      datime={moment(current.dtime).format(
                        "YYYY-MM-DD HH:mm"
                      )}
                      content={current.content}
                    />
                  );
                })}
            </div>
          </div>
          {state.content.length > 0 ? (
            <div className="row textarea-row border-bottom">
              <div className="col-12 textarea-col">
                <textarea
                  value={text}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  className="message-textarea"
                  placeholder="请填写内容"
                />
              </div>

              <div className="col-12 ">
                {text && (
                  <button
                    className="btn btn-sm btn-primary pull-right signin-right-input"
                    type="button"
                    onClick={handleReply}
                  >
                    发送
                  </button>
                )}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </View>
  );
};

export default Audition;
