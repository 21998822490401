import React from 'react';

import { View } from './Components';

const Feeback = () => {
  const [list, setList] = React.useState([]);
  const auth = JSON.parse(sessionStorage.getItem('auth')) || {};

  React.useEffect(() => {
    fetch(
      `/api/miscellaneous/feedback?option=by-employer_id-and-tag&id=${auth.id}&tag=企业用户`,
    )
      .then((response) => response.json())
      .then((data) => {
        setList(data);
      });
  }, []);

  return (
    <View category="投诉">
      <div className="row bg-white shadow signin-right-input">
        <div className="col card signin-right-input">
          <div className="card-body">
            <h4 className="pull-left" style={{ color: "#4a4a4a" }}><strong>(意见反馈/投诉)记录</strong></h4>
            <table className="table table-hover" style={{ color: "#4a4a4a" }}>
              <thead>
                <tr>
                  <th scope="col">提交时间</th>
                  <th scope="col">内容</th>
                </tr>
              </thead>
              <tbody>
                {list &&
                  list.map((item) => (
                    <tr key={item.id}>
                      <td>{item.dtime}</td>
                      <td>{item.content}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </View>
  );
};

export default Feeback;
