import React from 'react';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';

// import Title from "../components/Title";
import Footer from '../components/Footer';
import Register from './Register';
import Certification from './Certification';
import JobFair from './JobFair';
import Post from './Post';
import Resume from './Resume';
import Message from './Message';
import Record from './Record';
import User from './User';

const Index = () => {
  return (
    <>
      <div className="container-fluid pb-4">
        <Router>
          <Switch>
            <Route exact path="/操作手册/注册">
              <Register />
            </Route>
            <Route exact path="/操作手册/企业认证">
              <Certification />
            </Route>
            <Route exact path="/操作手册/报名招聘会">
              <JobFair />
            </Route>
            <Route exact path="/操作手册/岗位管理">
              <Post />
            </Route>
            <Route exact path="/操作手册/简历管理">
              <Resume />
            </Route>
            <Route exact path="/操作手册/消息">
              <Message />
            </Route>
            <Route exact path="/操作手册/操作记录">
              <Record />
            </Route>
            <Route exact path="/操作手册/我的">
              <User />
            </Route>
          </Switch>
        </Router>
      </div>
      <Footer />
    </>
  );
};

export default Index;
