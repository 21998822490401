import { createSlice } from '@reduxjs/toolkit';

export const topicSlice = createSlice({
  name: 'topic',

  initialState: {
    list: [],
  },
  reducers: {
    setTopic: (state, action) => {
      state.list = action.payload.list;
    },
  },
});

export const { setTopic } = topicSlice.actions;

export default topicSlice.reducer;
