import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

export default function TopicPhone() {
  const [data, setData] = React.useState(0);
  const { id } = useParams();
  const uuid = new URLSearchParams(useLocation().search).get('u_id');

  React.useEffect(() => {
    if (id && uuid) {
      fetch(`/api/bulletin/${id}?option=topic&uuid=${uuid}`)
        .then((response) => response.json())
        .then((data) => {
          setData(data);
        });
    }
  }, [id, uuid]);

  return (
    <div className="row px-1 mt-4" style={{ fontSize: 12 }}>
      {data && (
        <div className="card col rounded-0 shadow px-4">
          <a href="#/">
            <h5 className="mt-4">
              <FontAwesomeIcon icon={faChevronLeft} size="lg" fixedWidth />
              {data.title}
            </h5>
          </a>
          <hr />
          <div
            className="px-5"
            dangerouslySetInnerHTML={{ __html: data.content }}
          ></div>
        </div>
      )}
    </div>
  );
}
