import React, { useContext, useEffect } from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from "../components/Navbar";
import ContentTitle from "../components/ContentTitle";
import Footer from "../components/Footer";
import Home from "./Home";
import Topic from "./topic";
import Banner from "./Banner";
import { useAuth } from "../utility/useAuth";
import { MainContext } from "../utility/context";

export default function Index() {
    const [_, dispatchContext] = useContext(MainContext);
    const auth = useAuth(true);

    useEffect(() => {
        dispatchContext({ type: "setAuth", value: auth });
    }, [])

    return (
        <>
            <div className="container-fluid pb-4">
                <ContentTitle />
                <Navbar category="首页" totalFlg />
                <Router>
                    <Switch>
                        <Route exact path="/">
                            <Home />
                        </Route>
                        <Route exact path="/首页/banner/:id">
                            <Banner />
                        </Route>
                        <Route exact path="/首页/:id/">
                            <Topic />
                        </Route>
                    </Switch>
                </Router>
            </div>
            <Footer />
        </>
    );
}
