import md5 from "blueimp-md5";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import React, { useContext, useEffect, useReducer } from "react";
import { useDispatch } from "react-redux";
import { setAuth } from "./authSlice";
import { saveJournal } from "./commonFetch";
import Hover from "./components/Hover";
import AutoCardsLogin from "./components/AutoCardsLogin";
import reducer from "./reducer";
import { MainContext } from "./utility/context";

export default function Login() {
  const [_, dispatchContext] = useContext(MainContext);
  const disp = useDispatch();
  const [state, dispatch] = useReducer(reducer, {
    username: "",
    password: "",
  });

  const handleSignIn = () => {
    fetch("/gin-api/employer-user/sign-in", {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({
        username: state.username,
        password: md5(state.password),
      }),
    })
      .then((response) => {
        if (response.status !== 200) throw new Error("账号或密码错误");
        else return response.json();
      })
      .then((data) => {
        // context
        dispatchContext({ type: "setAuth", value: data });
        sessionStorage.setItem("auth", JSON.stringify(data));
        disp(setAuth(data));
        saveJournal({
          ref_id: data.id,
          category: "登录",
          tag: "企业用户",
          ref_uuid: data.uuid,
        });
        if (data.phone === "") location.href = "#/我的/用户";
        else location.href = "#/";
      })
      .catch((err) => alert(err));
  };

  useEffect(() => {
    sessionStorage.removeItem("auth");
  }, []);

  const scrollToCooperation = (anchorName) => {
    if (anchorName) {
      // 找到锚点
      let anchorElement = document.getElementById(anchorName);
      // 如果对应id的锚点存在，就跳转到锚点
      if (anchorElement) { anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' }); }
    }
  };

  const scrollToAbout = (anchorName) => {
    if (anchorName) {
      // 找到锚点
      let anchorElement = document.getElementById(anchorName);
      // 如果对应id的锚点存在，就跳转到锚点
      if (anchorElement) { anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' }); }
    }
  };

  return (
    <div className="container-fluid bg-light body-login">
      <div className="px-5 fixed-top bg-white border-bottom body-title">
        <div className="row">
          <div className="col item-middle">
            <img
              className="pull-left logo2"
              alt=""
              src="./lib/img/logo3.png"
            />
          </div>
          <div className="login_nav">
            <ul>
              <li>
                <a
                  className="text-warning"
                  href="#注册"
                  style={{ fontSize: "16px", textDecoration: "none" }}
                >
                  企业注册
                </a>
              </li>
              <li>
                <a
                  className="text-secondary"
                  onClick={() => scrollToCooperation("about")}
                  style={{ fontSize: "16px", textDecoration: "none", cursor: "pointer" }}
                >
                  关于我们
                </a>
              </li>
              <li>
                <a
                  className="text-secondary"
                  onClick={() => scrollToAbout("cooperation")}
                  style={{ fontSize: "16px", textDecoration: "none", cursor: "pointer" }}
                >
                  合作企业
                </a>
              </li>
              <li>
                <span className="text-secondary border-0 bg-transparent img-weixin">
                  <FontAwesomeIcon icon={faQrcode} fixedWidth />
                  求职用户登录
                  <p>
                    <img className="" alt="" src="./lib/img/qrcode.jpg" />
                  </p>
                </span>
              </li>
              <li>
                <a
                  href="#操作手册/注册"
                  className="text-secondary"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                >
                  操作手册
                </a>
              </li>
            </ul>
          </div>
          {/* <div className="col-1 header-right" style={{ textAlign: "center" }}>
            <a
              className="text-warning pull-right"
              href="#注册"
              style={{ fontSize: "16px", textDecoration: "none" }}
            >
              企业注册
            </a>
          </div>
          <div className="col-1 header-right" style={{ textAlign: "center" }}>
            <a
              className="text-secondary pull-right"
              href="#注册"
              style={{ fontSize: "16px", textDecoration: "none" }}
            >
              关于我们
            </a>
          </div>
          <div className="col-2 header-right pull-left" style={{ textAlign: "center" }}>
            <span className="text-secondary border-0 bg-transparent img-weixin">
              <FontAwesomeIcon icon={faQrcode} fixedWidth />
              求职用户登录
              <p>
                <img className="" alt="" src="./lib/img/qrcode.jpg" />
              </p>
            </span>
          </div>
          <div className="col-1 header-right pull-left" style={{ textAlign: "left" }}>
            <a
              href="#操作手册/注册"
              className="text-secondary"
              style={{ textDecoration: "none" }}
              target="_blank"
            >
              操作手册
            </a>
          </div> */}
        </div>
      </div>

      <div
        className="row login-body px-5 header"
        style={{
          height: "calc(100vw * 0.33)",
          minHeight: "530px",
        }}
      >
        <div className="col w-25 mt-5">
          <div className="card pull-right signin-right-input">
            <div className="card-body text-center">
              <h4 style={{ color: "#4a4a4a" }}><strong>登录</strong></h4>
              <hr />
              <form>
                <div className="row px-4">
                  <div className="col form-group">
                    <input
                      type="text"
                      value={state.username}
                      placeholder="请输入手机号码或Email地址"
                      autoComplete="username"
                      className="mt-3 form-control signin-right-input"
                      onChange={(e) =>
                        dispatch({
                          type: "set",
                          payload: {
                            key: "username",
                            value: e.target.value,
                          },
                        })
                      }
                    />
                  </div>
                </div>
                <div className="row px-4">
                  <div className="col form-group">
                    <input
                      type="password"
                      value={state.password}
                      placeholder="请输入密码"
                      autoComplete="current-password"
                      className="mt-2 form-control signin-right-input"
                      onChange={(e) =>
                        dispatch({
                          type: "set",
                          payload: {
                            key: "password",
                            value: e.target.value,
                          },
                        })
                      }
                    />
                  </div>
                </div>
                <div className="row px-4" style={{ visibility: "hidden" }}>
                  <div className="col-7">
                    <input type="text" className="col form-control rounded-0" />
                  </div>
                  <div className="col">
                    <button
                      type="button"
                      className="btn btn-secondary rounded-0"
                      style={{ height: "100%", fontSize: "small" }}
                    >
                      发送验证码
                    </button>
                  </div>
                </div>
              </form>
              <div className="row mt-3 px-4 ">
                <div className="col">
                  <button
                    type="button"
                    className="mt-2 btn btn-login signin-right-input"
                    onClick={handleSignIn}
                    style={{ backgroundColor: "#fac93e" }}
                  >
                    登录
                  </button>
                </div>
              </div>
              <div className="row mt-2 px-4 ">
                <div className="col">
                  <a
                    className="text-dark pull-right"
                    href="#忘记密码"
                    style={{ fontSize: "small", textDecoration: "none" }}
                  >
                    忘记密码?
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="about">
        <Hover />
      </div>
      <div id="cooperation" className="between-box card shadow mt-3 pb-4">
        <div className="pt-3 pb-4">
          <h2>合作企业</h2>
        </div>
        <AutoCardsLogin />
        {/* <div>
            <img
              className="border between-content shadow-sm"
              alt=""
              src="./lib/img/1.jpg"
            />
            <img
              className="border between-content shadow-sm"
              alt=""
              src="./lib/img/4.jpg"
            />
            <img
              className="border between-content shadow-sm"
              alt=""
              src="./lib/img/10.png"
            />
            <img
              className="border between-content shadow-sm"
              alt=""
              src="./lib/img/5.jpg"
            />
            <img
              className="border between-content shadow-sm"
              alt=""
              src="./lib/img/17.png"
            />
            <img
              className="border between-content shadow-sm"
              alt=""
              src="./lib/img/19.png"
            />
          </div>
          <div>
            <img
              className="border between-content1 shadow-sm"
              alt=""
              src="./lib/img/2.jpg"
            />
            <img
              className="border between-content1 shadow-sm"
              alt=""
              src="./lib/img/3.jpg"
            />
            <img
              className="border between-content1 shadow-sm"
              alt=""
              src="./lib/img/7.png"
            />
            <img
              className="border between-content1 shadow-sm"
              alt=""
              src="./lib/img/8.jpg"
            />
            <img
              className="border between-content1 shadow-sm"
              alt=""
              src="./lib/img/9.jpg"
            />
            <img
              className="border between-content1 shadow-sm"
              alt=""
              src="./lib/img/13.jpg"
            />
            <img
              className="border between-content1 shadow-sm"
              alt=""
              src="./lib/img/15.png"
            />
            <img
              className="border between-content1 shadow-sm"
              alt=""
              src="./lib/img/16.jpg"
            />
            <img
              className="border between-content1 shadow-sm"
              alt=""
              src="./lib/img/21.jpg"
            />
            <img
              className="border between-content1 shadow-sm"
              alt=""
              src="./lib/img/22.jpg"
            />
            <img
              className="border between-content1 shadow-sm"
              alt=""
              src="./lib/img/23.jpg"
            />
            <img
              className="border between-content1 shadow-sm"
              alt=""
              src="./lib/img/24.jpg"
            />
          </div>
          <div>
            <img
              className="border between-content shadow-sm"
              alt=""
              src="./lib/img/6.png"
            />
            <img
              className="border between-content shadow-sm"
              alt=""
              src="./lib/img/11.jpg"
            />
            <img
              className="border between-content shadow-sm"
              alt=""
              src="./lib/img/12.jpg"
            />
            <img
              className="border between-content shadow-sm"
              alt=""
              src="./lib/img/14.jpg"
            />
            <img
              className="border between-content shadow-sm"
              alt=""
              src="./lib/img/18.jpg"
            />
            <img
              className="border between-content shadow-sm"
              alt=""
              src="./lib/img/20.jpg"
            />
          </div>
        </div> */}
      </div>
      <div className="row footer px-6 text-white bg-dark border-top pb-3">
        <div className="col mt-4">
          <div className="row flex-center">
            <a className="footer-word" target="_blank" href="/agt/law.html">
              法律声明&nbsp;
            </a>
            |
            <a className="footer-word" target="_blank" href="/agt/privacy.html">
              &nbsp;隐私政策&nbsp;
            </a>
            |
            <span>
              &nbsp;Copyright © 2020 哈尔滨乔汉科技有限公司.All Rights
              Reserved.&nbsp;
            </span>
            |
            <a
              className="footer-word"
              target="_blank"
              rel="noopener noreferrer"
              href="https://beian.miit.gov.cn/"
            >
              &nbsp;互联网ICP备案:黑ICP备20002542号
            </a>
          </div>
          <div className="row flex-center">
            <span>
              合作咨询热线：18944650800&nbsp;|&nbsp;举报邮箱:job51-hlj@foxmail.com
            </span>
            &nbsp;|&nbsp;
            <a
              className="footer-word"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.12377.cn/"
            >
              网上有害信息举报专区
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
