import React, { useContext, useEffect, useReducer, useState } from "react";
import watermark from "watermarkjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";

import reducer from "../reducer";
import { View } from "./Components";
import { TextField1, SelectField1 } from "../components/InputField";
import { saveJournal } from "../commonFetch";
import { MainContext } from "../utility/context";

export default function Update() {
  const [state, dispatch] = useReducer(reducer, {
    INDUSTRY_LIST: [],
  });
  const [data, setData] = useState({
    yingyezhizhao: "",
    faren: "",
    zhuceriqi: "",
    zhuziguimo: "",
    yuangongshuliang: "",
    yingyezhizhao_tu: "",
    addressLevel1: "",
    addressLevel2: "",
    addressLevel3: "",
    addressLevel4: "",
    phone: "",
    code: "",
    url: "",
    industry: "",
    intro: "",
  });
  const [required, setRequired] = useState({
    yingyezhizhao: false,
    faren: false,
    zhuceriqi: false,
    zhuziguimo: false,
    yuangongshuliang: false,
    yingyezhizhao_tu: false,
    addressLevel1: false,
    addressLevel2: false,
    addressLevel4: false,
    phone: false,
    intro: false,
  });
  const [auth, setAuth] = useState(0);
  const [level, setLevel] = useState([]);
  const [address, setAddress] = useState([]);
  const [city, setCity] = useState([]);
  const [area, setArea] = useState([]);
  // eslint-disable-next-line
  const [number, setNumber] = useState(0);

  useEffect(() => {
    const _auth = JSON.parse(sessionStorage.getItem("auth"));
    if (_auth === null) {
      location.href = "#登录";
      return;
    } else {
      setAuth(_auth);
      fetch(`/gin-api/employer/${_auth.ref_uuid}/${_auth.ref_id}`)
        .then((response) => {
          if (response.status < 400) return response.json();
          else throw new Error("请求数据失败");
        })
        .then((data) => {
          setData(data);
        })
        .catch((err) => window.console.error(err));
    }
    fetch("/lib/address.json")
      .then((res) => res.json())
      .then((res) => {
        setAddress(res);
        setLevel(
          Object.getOwnPropertyNames(res)
            .filter((item) => item.slice(2, 7) === "0000")
            .map((code) => ({
              code,
              name: res[code],
            }))
        );
      });
  }, []);

  useEffect(() => {
    fetch("/crate-api/setting?option=filterBy-category-refId&category=行业&refId=0")
      .then((response) => response.json())
      .then((data) => {
        dispatch({
          type: "set",
          payload: { key: "INDUSTRY_LIST", value: data },
        });
      });
  }, []);

  useEffect(() => {
    if (level.length > 0) {
      const a1 = level.find((item) => item.name === data.addressLevel1);
      if (a1) {
        setCity(
          Object.getOwnPropertyNames(address)
            .filter(
              (it) =>
                a1.code.slice(0, 2) === it.slice(0, 2) &&
                it.slice(4, 7) === "00" &&
                it !== a1.code
            )
            .map((code) => ({
              code,
              name: address[code],
            }))
        );
      }
    }
  }, [data, level, address]);

  useEffect(() => {
    if (city.length > 0) {
      const a2 = city.find((item) => item.name === data.addressLevel2);
      if (a2) {
        setArea(
          Object.getOwnPropertyNames(address)
            .filter((it) => a2.code.slice(0, 4) === it.slice(0, 4) && it !== a2.code)
            .map((code) => ({
              code,
              name: address[code],
            }))
        );
      }
    }
  }, [data, city, address]);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    let flg = false;
    const _req = {};
    Object.getOwnPropertyNames(required).forEach((key) => {
      if (!data[key] || data[key] === "") {
        _req[key] = "请填写内容!";
        flg = true;
      } else {
        _req[key] = false;
      }
    });
    if (flg) {
      window.console.info(_req);
      setRequired(_req);
      return;
    }
    if (data.phone.length > 20) {
      setRequired({ phone: "您输入的电话号码过长，请重新输入" });
      return;
    } else if (data.zhuziguimo.length > 20) {
      setRequired({ zhuziguimo: "您输入的注资规模过长，请重新输入" });
      return;
    } else if (data.yingyezhizhao.length > 30) {
      setRequired({ yingyezhizhao: "您输入的统一社会信用代码过长，请重新输入" });
      return;
    } else if (data.addressLevel4.length > 50) {
      setRequired({ address4: "您输入的详细地址过长，请重新输入" });
      return;
    }
    fetch(`/gin-api/employer/${auth.ref_uuid}/${auth.ref_id}`, {
      method: "PUT",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status < 400) {
          saveJournal({
            ref_id: auth.id,
            ref_id2: data.id,
            category: "编辑企业信息",
            tag: "企业用户",
            ref_uuid: auth.uuid,
            ref_uuid2: data.uuid,
          });
          window.alert("操作成功");
          location.href = "#我的/信息";
        } else throw new Error("操作失败");
      })
      .catch((err) => window.alert(err));
    // .then((res) => res.json())
    // .then((res) => {
    //   if (res.message) {
    //     window.alert("服务器错误，请稍后重试");
    //   } else {
    //     console.log(res);
    //     saveJournal({
    //       ref_id: auth.id,
    //       ref_id2: data.id,
    //       category: "编辑企业信息",
    //       tag: "企业用户",
    //       ref_uuid: auth.uuid,
    //       ref_uuid2: data.uuid,
    //     });
    //     window.alert("操作成功");
    //     location.href = "#我的/信息";
    //   }
    // });
  };

  const handleIntroChange = (e) => {
    const { value, name } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
    setNumber(value.length);
    if (value.length > 500) {
      document.getElementById("number").style.color = "#ff2121";
      document.getElementById("hide").style.display = "block";
      document.getElementById("preservation").disabled = true;
    } else {
      document.getElementById("number").style.color = "#7a858c";
      document.getElementById("hide").style.display = "none";
      document.getElementById("preservation").disabled = false;
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files.length === 0) {
      return;
    }
    if (e.target.files[0].size >= 307200) {
      window.alert("超出图片大小上限!(300KB)");
      return;
    }
    watermark([e.target.files[0]])
      .dataUrl(
        watermark.text.center(
          "仅用于公示,其他用途无效",
          "100px Josefin Slab",
          "#FE0000",
          0.5
        )
      )
      .then((url) => {
        setData({
          ...data,
          yingyezhizhao_tu: url,
        });
      });
  };

  const handleUpload = () => {
    document.getElementById("file").click();
  };

  const handleProvince = (e) => {
    const { value } = e.target;
    if (value !== "") {
      const a1 = level.find((item) => item.name === value);
      if (a1) {
        setCity(
          Object.getOwnPropertyNames(address)
            .filter(
              (it) =>
                a1.code.slice(0, 2) === it.slice(0, 2) &&
                it.slice(4, 7) === "00" &&
                it !== a1.code
            )
            .map((code) => ({
              code,
              name: address[code],
            }))
        );
      }
      setData({
        ...data,
        addressLevel1: value,
        addressLevel2: "",
        addressLevel3: "",
      });
      setArea([]);
    } else {
      setData({
        ...data,
        addressLevel1: value,
        addressLevel2: value,
        addressLevel3: value,
      });
      setCity([]);
      setArea([]);
    }
  };

  const handleCity = (e) => {
    const { value } = e.target;
    if (value !== "") {
      setData({
        ...data,
        addressLevel2: value,
        addressLevel3: "",
      });
      const a2 = city.find((item) => item.name === value);
      if (a2) {
        setArea(
          Object.getOwnPropertyNames(address)
            .filter((it) => a2.code.slice(0, 4) === it.slice(0, 4) && it !== a2.code)
            .map((code) => ({
              code,
              name: address[code],
            }))
        );
      }
    } else {
      setData({
        ...data,
        addressLevel2: value,
        addressLevel3: value,
      });
      setArea([]);
    }
  };

  return (
    <View category="企业信息">
      <div className="row bg-white shadow signin-right-input">
        <div className="col card signin-right-input">
          <div className="card-body">
            <div className="row">
              <div className="col">
                <h3 className="pull-left" style={{ color: "#4a4a4a" }}><strong>基本信息</strong></h3>
                <p className="text-danger pt-2">(带有*符号的选项为必填项)</p>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-3 col-md-4">
                <TextField1
                  category="统一社会信用代码"
                  name="yingyezhizhao"
                  value={data.yingyezhizhao || ""}
                  handleChange={handleChange}
                  req={required.yingyezhizhao}
                  required
                />
              </div>
              <div className="col-3 col-md-4">
                <TextField1
                  category="法人"
                  name="faren"
                  value={data.faren || ""}
                  handleChange={handleChange}
                  req={required.faren}
                  required
                />
              </div>
              <div className="col-3 col-md-4">
                <div className="form-group">
                  <label>
                    <span className="text-danger">*</span>
                    注册日期
                  </label>
                  <input
                    type="date"
                    name="zhuceriqi"
                    value={data.zhuceriqi}
                    onChange={handleChange}
                    className={`form-control form-control-sm rounded-0 ${required.zhuceriqi ? "is-invalid" : ""
                      }`}
                  />
                  <div className="invalid-feedback">{required.zhuceriqi}</div>
                </div>
              </div>
              <div className="col-3 col-md-4">
                <TextField1
                  category="注资规模"
                  name="zhuziguimo"
                  value={data.zhuziguimo || ""}
                  handleChange={handleChange}
                  req={required.zhuziguimo}
                  required
                />
              </div>
              <div className="col-3 col-md-4">
                <SelectField1
                  category="员工数量"
                  name="yuangongshuliang"
                  value={data.yuangongshuliang}
                  handleChange={handleChange}
                  req={required.yuangongshuliang}
                  required
                >
                  <option> </option>
                  <option>50 人以下</option>
                  <option>50-100 人</option>
                  <option>100-200 人</option>
                  <option>200-500 人</option>
                  <option>500 人以上</option>
                </SelectField1>
              </div>
              <div className="col-3 col-md-4">
                {/* <SelectField1
                  category="所属行业"
                  name="industry"
                  value={data.industry}
                  handleChange={handleChange}
                  req={required.industry}
                  required
                >
                  <option> </option>
                  {industry
                    .filter((item) => item.master_id === 0)
                    .map((item) => (
                      <option key={item.id}>{item.name}</option>
                    ))}
                </SelectField1> */}
                <div className="mb-3">
                  <label className="form-label">所属行业</label>
                  <select
                    name="industry"
                    value={data.industry}
                    className="form-control form-control-sm"
                    onChange={handleChange}
                  >
                    <option></option>
                    {0 < state.INDUSTRY_LIST.length &&
                      state.INDUSTRY_LIST.map((current) => (
                        <option
                          key={current.id}
                          value={current.detail.name}
                        >
                          {current.detail.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="col-3 col-md-4">
                <TextField1
                  category="公司固定电话"
                  name="phone"
                  value={data.phone || ""}
                  handleChange={handleChange}
                  req={required.phone}
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h3 className="pull-left">企业网址</h3>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <input
                    type="text"
                    name="url"
                    value={data.url || ""}
                    onChange={handleChange}
                    className="form-control form-control-sm rounded-0"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h3 className="pull-left">
                  <span className="text-danger">*</span>
                  公司简介
                </h3>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <textarea
                    name="intro"
                    value={data.intro}
                    onChange={handleIntroChange}
                    rows="4"
                    className={`form-control form-control-sm rounded-0 ${required.intro ? "is-invalid" : ""
                      }`}
                  />
                  <span
                    id="number"
                    className="pull-right"
                    style={{ color: "#7a858c" }}
                  >
                    {number}/500
                  </span>
                  <span
                    id="hide"
                    className="pull-left"
                    style={{ color: "#ff2121", display: "none" }}
                  >
                    请输入1-500个字符
                  </span>
                  <div className="invalid-feedback">{required.intro || ""}</div>
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col">
                <h3 className="pull-left">公司地址</h3>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-3 col-md-4">
                <SelectField1
                  category="省/直辖市"
                  name="addressLevel1"
                  value={data.addressLevel1}
                  handleChange={handleProvince}
                  req={required.addressLevel1}
                  required
                >
                  <option> </option>
                  {level.map((item, index) => (
                    <option key={index.toString()}>{item.name}</option>
                  ))}
                </SelectField1>
              </div>
              <div className="col-3 col-md-4">
                <SelectField1
                  category="市"
                  name="addressLevel2"
                  value={data.addressLevel2}
                  handleChange={handleCity}
                  req={required.addressLevel2}
                  required
                >
                  <option> </option>
                  {city.map((item, index) => (
                    <option key={index.toString()}>{item.name}</option>
                  ))}
                </SelectField1>
              </div>
              <div className="col-3 col-md-4">
                <SelectField1
                  category="区"
                  name="addressLevel3"
                  value={data.addressLevel3}
                  handleChange={handleChange}
                  req={required.addressLevel3}
                >
                  <option> </option>
                  {area.map((item, index) => (
                    <option key={index.toString()}>{item.name}</option>
                  ))}
                </SelectField1>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <TextField1
                  category="详细地址"
                  name="addressLevel4"
                  value={data.addressLevel4}
                  handleChange={handleChange}
                  req={required.addressLevel4}
                  required
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <h3 className="pull-left">
                  <span className="text-danger">*</span>
                  营业执照
                  <span className="text-danger" style={{ fontSize: 16 }}>
                    (请上传小于300KB的.jpg/.jpeg/.png格式图片)
                  </span>
                  <span className="text-danger">
                    {required.yingyezhizhao_tu
                      ? `(${required.yingyezhizhao_tu})`
                      : ""}
                  </span>
                </h3>
                <div className="pull-right">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={handleUpload}
                  >
                    <FontAwesomeIcon icon={faUpload} fixedWidth />
                    选择图片
                  </button>
                  <input
                    type="file"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    id="file"
                    accept="image/png, image/jpeg"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <img id="img" src={data.yingyezhizhao_tu} className="w-50" alt="" />
              </div>
            </div>
            <hr />
            <div className="row mt-2">
              <div className="col">
                <a className="pull-left btn btn-primary" href="#/我的/信息">
                  返回
                </a>
                <button
                  id="preservation"
                  className="pull-right btn btn-success"
                  type="button"
                  onClick={handleSave}
                >
                  保存并提交审核
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </View>
  );
}
