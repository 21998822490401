import React, { useEffect, useReducer, useState } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { View } from "./Components";
import { saveJournal } from "../commonFetch";
import reducer from "../reducer";

export default function Update() {
  const [state, dispatch] = useReducer(reducer, {
    JOB_LIST: [],
    title: "",
    publishTime: "",
    expireAt: "",
    content: "",
    addressLevel1: "",
    addressLevel2: "",
    progress: 0,
  });
  const auth = JSON.parse(sessionStorage.getItem("auth"));
  // const auth = useSelector((state) => state.auth);
  const [allFlg, setAllFlg] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { id } = useParams();
  const handleSave = () => {
    window.alert("操作已提交至服务器，请稍后查看结果。");
    window.history.back();
  };

  const handleChange = (event) => {
    updateStatus(
      event.target.getAttribute("data-id"),
      event.target.getAttribute("data-uuid"),
      event.target.checked
    );
    let flg = true;
    document.getElementsByName("check").forEach((event) => {
      if (!event.checked) {
        flg = false;
      }
    });
    setAllFlg(flg);
  };
  const allCheckChange = (event) => {
    document.getElementsByName("check").forEach((v) => {
      if (v.checked !== event.target.checked) {
        updateStatus(v.getAttribute("data-id"), v.getAttribute("data-uuid"), !v.checked);
        v.checked = event.target.checked;
      }
    });
    setAllFlg(event.target.checked);
  };
  const updateStatus = (jobId, jobUuid, checked) => {
    const url = ["/api/simple/biz/job", `/${jobId}`, `/${jobUuid}`];
    fetch(url.join(""))
      .then((response) => response.json())
      .then((data) => {
        let fairList = JSON.parse(data.fairId);
        if (!fairList) return;
        const idx = fairList.indexOf(id);
        const fetchData = (body) => {
          fetch(`/gin-api/job/${jobUuid}/${jobId}?option=fair`, {
            method: "PATCH",
            headers: { "content-type": "application/json" },
            body: JSON.stringify(body),
          });
        };
        if (checked) {
          if (idx === -1) {
            fairList.unshift(`${id}`);
            fetchData({ fairList: JSON.stringify(fairList) });
            saveJournal({
              ref_id: auth.id,
              ref_id2: id,
              category: "参加招聘会",
              tag: "企业用户",
              ref_uuid: auth.uuid,
              ref_uuid2: "",
            });
          }
        } else {
          if (idx > -1) {
            fairList.splice(idx, 1);
            fetchData({ fairList: JSON.stringify(fairList) });
            saveJournal({
              ref_id: auth.id,
              ref_id2: id,
              category: "退出招聘会",
              tag: "企业用户",
              ref_uuid: auth.uuid,
              ref_uuid2: "",
            });
          }
        }
      });
  };

  useEffect(() => {
    if (0 === auth.id) location.href = "#/登录";
  }, []);

  useEffect(() => {
    fetch(`/api/simple/bulletin/${id}`)
      .then((response) => response.json())
      .then((data) => {
        dispatch({ type: "set", payload: { key: "title", value: data.title } });
        dispatch({
          type: "set",
          payload: { key: "content", value: data.detail.content },
        });
        dispatch({
          type: "set",
          payload: { key: "publishTime", value: data.publish_time },
        });
        dispatch({
          type: "set",
          payload: { key: "expireAt", value: data.expire_at },
        });
        dispatch({
          type: "set",
          payload: { key: "addressLevel1", value: data.detail.addressLevel1 },
        });
        dispatch({
          type: "set",
          payload: { key: "addressLevel2", value: data.detail.addressLevel2 },
        });
        dispatch({
          type: "set",
          payload: { key: "progress", value: state.progress + 1 },
        });
      });
  }, []);

  useEffect(() => {
    if (1 === state.progress) {
      let url = [
        // "/api/biz/job",
        "/gin-api/job",
        // "?option=list-by-employer-id",
        "?option=filterBy-refId-status",
        `&refId=${auth.ref_id}`,
        "&status=在招",
        // `&id=${auth.ref_id}`,
        // `&uuid=${auth.ref_uuid}`,
      ];
      fetch(url.join(""))
        .then((response) => response.json())
        .then((data) => {
          if (data !== null) {
            dispatch({ type: "set", payload: { key: "JOB_LIST", value: data } });
            if (state.addressLevel2 !== "") {
              if (data.every((item) => item.addressLevel2 !== state.addressLevel2)) {
                window.alert(
                  "非常抱歉，您不符合本场招聘会参会要求，请您选择其他招聘会进行报名！"
                );
                setDisabled(true);
              }
            } else if (state.addressLevel1 !== "") {
              if (data.every((item) => item.addressLevel1 !== state.addressLevel1)) {
                window.alert(
                  "非常抱歉，您不符合本场招聘会参会要求，请您选择其他招聘会进行报名！"
                );
                setDisabled(true);
              }
            }
          } else {
            window.alert("系统检测您还未添加岗位，请您添加岗位后再报名招聘会！");
            window.history.back();
          }
        });
    }
  }, [state.progress]);

  return (
    <View category="校园招聘会场次">
      <div className="row p-3 pt-2 bg-white shadow signin-right-input" style={{ color: "#4a4a4a" }}>
        <div className="col">
          <h4><strong>{state.title}</strong></h4>
          <ul className="list-inline">
            <li className="list-inline-item">
              {dayjs(state.publishTime).format("YYYY-MM-DD HH:mm:ss")}开始报名
            </li>
            <li className="list-inline-item">
              {dayjs(state.expireAt).format("YYYY-MM-DD HH:mm:ss")}结束
            </li>
          </ul>
          <hr />
          <div className='editor-body' dangerouslySetInnerHTML={{ __html: state.content }} />
        </div>
      </div>

      <div className="row mt-3 bg-white shadow signin-right-input">
        <div className="col card signin-right-input">
          <div className="card-body">
            <h4 className="pull-left" style={{ color: "#4a4a4a" }}><strong>选择参会岗位</strong></h4>
            <table className="table table-hover" style={{ color: "#4a4a4a" }}>
              <thead>
                <tr>
                  <th scope="col">
                    <input
                      type="checkbox"
                      checked={allFlg}
                      onChange={allCheckChange}
                      disabled={disabled}
                    />
                  </th>
                  <th scope="col">编号</th>
                  <th scope="col">岗位名称</th>
                  <th scope="col">岗位类型</th>
                  <th scope="col">所属行业</th>
                  <th scope="col">所属职位</th>
                  <th scope="col">学历要求</th>
                  <th scope="col">工作地点</th>
                  <th scope="col">状态</th>
                  <th scope="col">刷新日期</th>
                </tr>
              </thead>
              <tbody>
                {0 < state.JOB_LIST.length &&
                  state.JOB_LIST.map((item) => (
                    <tr key={item.id}>
                      <td>
                        <input
                          name="check"
                          type="checkbox"
                          onChange={handleChange}
                          disabled={disabled}
                          value={item.id}
                          data-id={item.id}
                          data-uuid={item.uuid}
                          defaultChecked={
                            null !== item.job_fair_id &&
                            JSON.parse(item["fairId"]).find((it) => id === it)
                          }
                        />
                      </td>
                      <td>{item["id"]}</td>
                      <td>{item.name}</td>
                      <td>{item.category}</td>
                      <td>{item.industry}</td>
                      <td>{item.position}</td>
                      <td>{item.education}</td>
                      <td>
                        {item["addressLevel1"]}-{item["addressLevel2"]}-{item["addressLevel3"]}
                      </td>
                      <td>{item.status}</td>
                      <td>{dayjs(item["refreshTime"]).format("YYYY-MM-DD")}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div className="row">
              <div className="col">
                <a className="btn btn-primary" href="#招聘会/列表">
                  返回
                </a>
              </div>
              <div className="col">
                <div className="pull-right">
                  <button
                    className="btn btn-success"
                    type="button"
                    onClick={handleSave}
                  >
                    保存
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </View>
  );
}
