import React, { useContext, useEffect, useState } from "react";
import { View } from "./ComponentsPhone";
import { TextFieldPhone1, SelectFieldPhone1 } from "../components/InputField";
import { saveJournal } from "../commonFetch";
import { MainContext } from "../utility/context";
import { useAuth } from "../utility/useAuth";

const RevisePhone = () => {
  const [context, dispatchContext] = useContext(MainContext);
  const auth = useAuth(true);
  const [data, setData] = useState(context.employer || '');
  // const [data, setData] = useState({
  //     zhuziguimo: "",
  //     yuangongshuliang: "",
  //     phone: "",
  //     url: "",
  //     intro: "",
  // });
  const [required, setRequired] = useState({
    zhuziguimo: false,
    yuangongshuliang: false,
    phone: false,
    intro: false,
  });
  const [number, setNumber] = useState(0);

  useEffect(() => {
    if (!data) {
      fetch(`/gin-api/employer/${auth.ref_uuid}/${auth.ref_id}`)
        .then((response) => {
          if (response.status < 400) return response.json();
          else throw new Error("请求数据失败");
        })
        .then((data) => {
          console.log(data);
          setData(data);
        })
        .catch((err) => window.console.error(err));
    }
  }, []);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleIntroChange = (e) => {
    console.log(5);
    const { value, name } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
    setNumber(value.length);
    if (value.length > 500) {
      document.getElementById("number").style.color = "#ff2121";
      document.getElementById("hide").style.display = "block";
      document.getElementById("preservation").disabled = true;
    } else {
      document.getElementById("number").style.color = "#7a858c";
      document.getElementById("hide").style.display = "none";
      document.getElementById("preservation").disabled = false;
    }
  };
  const handleSave = () => {
    let flg = false;
    const _req = {};
    Object.getOwnPropertyNames(required).forEach((key) => {
      if (!data[key] || data[key] === "") {
        _req[key] = "请填写内容!";
        flg = true;
      } else {
        _req[key] = false;
      }
    });
    if (flg) {
      setRequired(_req);
      return;
    }
    fetch(`/gin-api/employer/${data.uuid}/${data.id}`, {
      method: "PUT",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status < 400) {
          saveJournal({
            ref_id: auth.id,
            ref_id2: data.id,
            category: "编辑企业信息",
            tag: "企业用户",
            ref_uuid: auth.uuid,
            ref_uuid2: data.uuid,
          });
          window.alert("操作成功");
          location.href = "#我的/信息";
        } else throw new Error("操作失败");
      })
      .catch((err) => window.alert(err));
  };

  return (
    <View category="企业信息">
      <div className="bg-white shadow signin-right-input-phone">
        <div className="card signin-right-input-phone">
          <div className="card-body" style={{ fontSize: 10 }}>
            <div className="row">
              <div className="col">
                <h5 className="pull-left" style={{ color: "#4a4a4a" }}><strong>基本信息</strong></h5>
                <p className="text-danger pt-2" style={{ paddingBottom: 0 }}>(带有*符号的选项为必填项)</p>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col col-md-4">
                <TextFieldPhone1
                  category="注资规模"
                  name="zhuziguimo"
                  value={data["zhuziguimo"] || ""}
                  handleChange={handleChange}
                  req={required.zhuziguimo}
                  required
                />
              </div>
              <div className="col col-md-4">
                <SelectFieldPhone1
                  category="员工数量"
                  name="yuangongshuliang"
                  value={data.yuangongshuliang || ""}
                  handleChange={handleChange}
                  req={required.yuangongshuliang}
                  required
                >
                  <option> </option>
                  <option>50 人以下</option>
                  <option>50-100 人</option>
                  <option>100-200 人</option>
                  <option>200-500 人</option>
                  <option>500 人以上</option>
                </SelectFieldPhone1>
              </div>

              <div className="col col-md-4">
                <TextFieldPhone1
                  category="电话号码"
                  name="phone"
                  value={data.phone || ""}
                  handleChange={handleChange}
                  req={required.phone}
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h5 className="pull-left">企业网址</h5>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <input
                    type="text"
                    name="url"
                    value={data.url || ""}
                    onChange={handleChange}
                    className="form-control form-control-sm signin-right-input"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h5 className="pull-left">
                  <span className="text-danger">*</span>
                  公司简介
                </h5>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <textarea
                    name="intro"
                    value={data.intro}
                    onChange={handleIntroChange}
                    rows="4"
                    className={`form-control form-control-sm signin-right-input ${required.intro ? "is-invalid" : ""
                      }`}
                    style={{ fontSize: 10 }}
                  />
                  <span
                    id="number"
                    className="pull-right"
                    style={{ color: "#7a858c" }}
                  >
                    {number}/500
                  </span>
                  <span
                    id="hide"
                    className="pull-left"
                    style={{ color: "#ff2121", display: "none" }}
                  >
                    请输入1-500个字符
                  </span>
                  <div className="invalid-feedback">{required.intro || ""}</div>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <a className="pull-left btn btn-primary signin-right-input-phone" style={{ fontSize: 10 }} href="#/我的/信息">
                  返回
                </a>
                <button
                  id="preservation"
                  className="pull-right btn btn-success signin-right-input-phone"
                  style={{ fontSize: 10 }}
                  type="button"
                  onClick={handleSave}
                >
                  保存
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </View>
  );
};

export default RevisePhone;
