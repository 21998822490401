import React, { useState, useEffect, useReducer } from "react";
import { useParams, useLocation } from "react-router-dom";
// import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

import reducer from "../reducer";
import { TextFieldPhone, TextFieldPhone1, SelectFieldPhone, SelectFieldPhone1 } from "../components/InputField";
import { ModelPhone } from "./ModelPhone";
import { saveJournal } from "../commonFetch";
import RichEditor from "../components/RichEditor";
import { View } from "./ComponentsPhone";
import dayjs from "dayjs";

export default function UpdatePhone() {
  const [state, dispatch] = useReducer(reducer, {
    RESUME_LIST: [],
    SETTING_LIST: [],
    INDUSTRY_LIST: [],
    POSITION_LIST: [],
    ADDRESS_LIST: [],
    ADDRESS_LEVEL1_LIST: [],
    ADDRESS_LEVEL2_LIST: [],
    ADDRESS_LEVEL3_LIST: [],
    name: "",
    category: "",
    industry: "",
    status: "",
    education: "",
    salary1: "",
    salary2: "",
    address1: "",
    address2: "",
    address3: "",
    position: "",
    description: "",
    requirement: "",
    qty: "",
    modalShow: false,
  });
  const { id } = useParams();
  const uuid = new URLSearchParams(useLocation().search).get("u_id");
  const auth = JSON.parse(sessionStorage.getItem("auth"));
  const [modalShow, setModalShow] = useState(false);
  const handleSave = () => {
    fetch(`/gin-api/job/${uuid}/${id}?option=`, {
      method: "PUT",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({
        name: state.name,
        qty: state.qty,
        description: state.description,
        requirement: state.requirement,
        addressLevel1: state.address1,
        addressLevel2: state.address2,
        addressLevel3: state.address3,
        salary1: state.salary1,
        salary2: state.salary2,
        education: state.education,
        category: state.category,
        industry: state.industry,
        position: state.position,
      }),
    }).then((response) => {
      if (200 === response.status) {
        saveJournal({
          ref_id: auth.id,
          ref_id2: id,
          category: "编辑岗位",
          tag: "企业用户",
          ref_uuid: auth.uuid,
          ref_uuid2: uuid,
        });
        history.back();
      } else alert("操作失败，请您稍后重试");
    });
  };
  const handleDataStatus = (v) => {
    fetch(`/gin-api/job/${uuid}/${id}?option=status`, {
      method: "PUT",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({
        status: v,
      }),
    }).then((response) => {
      if (response.status < 400) {
        saveJournal({
          ref_id: auth.id,
          ref_id2: id,
          category: "编辑岗位状态",
          tag: "企业用户",
          ref_uuid: auth.uuid,
          ref_uuid2: uuid,
        });
        alert("已提交至服务器，请稍后查看");
        location.reload(true);
      } else alert("操作失败，请您稍后重试");
    });
  };

  useEffect(() => {
    if (0 === auth.id) location.href = "#登录";
  }, []);

  useEffect(() => {
    fetch(`/gin-api/job/${uuid}/${id}`)
      .then((response) => response.json())
      .then((data) => {
        dispatch({
          type: "set",
          payload: { key: "address1", value: data["addressLevel1"] },
        });
        dispatch({
          type: "set",
          payload: { key: "address2", value: data["addressLevel2"] },
        });
        dispatch({
          type: "set",
          payload: { key: "address3", value: data["addressLevel3"] },
        });
        dispatch({
          type: "set",
          payload: { key: "category", value: data.category },
        });
        dispatch({ type: "set", payload: { key: "date", value: data.date } });
        dispatch({
          type: "set",
          payload: { key: "description", value: data.description },
        });
        dispatch({
          type: "set",
          payload: { key: "education", value: data.education },
        });
        dispatch({
          type: "set",
          payload: { key: "enterprise_id", value: data["employerId"] },
        });
        dispatch({
          type: "set",
          payload: { key: "enterprise_uuid", value: data["employerUuid"] },
        });
        dispatch({
          type: "set",
          payload: { key: "industry", value: data.industry },
        });
        dispatch({ type: "set", payload: { key: "name", value: data.name } });
        dispatch({
          type: "set",
          payload: { key: "position", value: data.position },
        });
        dispatch({
          type: "set",
          payload: { key: "requirement", value: data.requirement },
        });
        dispatch({
          type: "set",
          payload: { key: "salary1", value: data.salary1 },
        });
        dispatch({
          type: "set",
          payload: { key: "salary2", value: data.salary2 },
        });
        dispatch({
          type: "set",
          payload: { key: "status", value: data.status },
        });
        dispatch({ type: "set", payload: { key: "qty", value: data.qty } });
      });
  }, []);

  useEffect(() => {
    const url = [
      "/gin-api/sendin",
      "?option=filterBy-ref2Ids-timeRange",
      `&ref2Ids=${id}`,
      `&timeBegin=${dayjs().subtract(2, "year").format("YYYY-MM-DD")}`,
      `&timeEnd=${dayjs().add(1, "day").format("YYYY-MM-DD")}`
    ];
    fetch(url.join(""))
      .then((response) => response.json())
      .then((sendin) => {
        if (sendin !== null) {
          let id = sendin.map((c) => c["resumeId"]).join(",");
          fetch(`/gin-api/resume?option=filterBy-ids&ids=${id}`)
            .then((response) => response.json())
            .then((resume) => {
              const lt = sendin.map((s) => {
                const row = resume.find((r) => `${r.id}` === s.resumeId);
                return {
                  ...row,
                  publishTime: s["publishTime"],
                  status: s["status"],
                  sendinId: s["id"]
                }
              })
              dispatch({
                type: "set",
                payload: { key: "RESUME_LIST", value: lt },
              });
            });
        }
      });
  }, []);

  useEffect(() => {
    fetch("/lib/address.json")
      .then((response) => response.json())
      .then((data) => {
        dispatch({
          type: "set",
          payload: {
            key: "ADDRESS_LIST",
            value: Object.getOwnPropertyNames(data).map((code) => ({
              code,
              name: data[code],
            })),
          },
        });
        dispatch({
          type: "set",
          payload: {
            key: "ADDRESS_LEVEL1_LIST",
            value: Object.getOwnPropertyNames(data)
              .filter((item) => item.slice(2, 7) === "0000")
              .map((code) => ({
                code,
                name: data[code],
              })),
          },
        });
      });
  }, []);

  useEffect(() => {
    fetch("/crate-api/setting?option=filterBy-category-refId&category=行业&refId=0")
      .then((response) => response.json())
      .then((data) => {
        dispatch({
          type: "set",
          payload: { key: "INDUSTRY_LIST", value: data },
        });
        let industry = data.find((element) => element.detail.name === state.industry);
        if (industry !== undefined) {
          fetch(
            `/crate-api/setting?option=filterBy-category-refId&category=行业&refId=${industry.id}`
          )
            .then((response) => response.json())
            .then((data) => {
              dispatch({
                type: "set",
                payload: { key: "POSITION_LIST", value: data },
              });
            });
        }
      });
  }, [state.industry]);

  useEffect(() => {
    if (!state.address1) return;
    if (0 === state.ADDRESS_LIST.length) return;
    const a1 = state.ADDRESS_LIST.find((e) => e.name === state.address1);
    if (a1) {
      dispatch({
        type: "set",
        payload: {
          key: "ADDRESS_LEVEL2_LIST",
          value: state.ADDRESS_LIST.filter(
            (c) =>
              a1.code.slice(0, 2) === c.code.slice(0, 2) &&
              c.code.slice(4, 7) === "00" &&
              c.code !== a1.code
          ),
        },
      });
    }
  }, [state.address1]);

  const share = (e) => {
    setModalShow(e);
  };

  useEffect(() => {
    if (!state.address2) return;
    if (0 === state.ADDRESS_LIST.length) return;
    const a = state.ADDRESS_LIST.find((e) => e.name === state.address2);
    if (a) {
      dispatch({
        type: "set",
        payload: {
          key: "ADDRESS_LEVEL3_LIST",
          value: state.ADDRESS_LIST.filter(
            (c) => a.code.slice(0, 4) === c.code.slice(0, 4) && c.code !== a.code
          ),
        },
      });
    }
  }, [state.address2]);

  return (
    <>
      <View category="我的职位">
        <div className="bg-white shadow signin-right-input-phone">
          <div className="card signin-right-input-phone">
            <div className="card-body">
              <div className="row">
                <div className="col" style={{ padding: 0 }}>
                  <h6 className="pull-left" style={{ color: "#4a4a4a" }}><strong>编辑岗位</strong></h6>
                  <p className="text-danger pt-2" style={{ fontSize: 10, marginBottom: 0, display: "inline" }}>(带有*符号的选项为必填项)</p>
                </div>
                <div className="col-5" style={{ padding: 0 }}>
                  {state.status === "在招" ? (
                    <button
                      className="pull-right btn btn-link btn-lg text-danger"
                      onClick={() => handleDataStatus("停招")}
                      style={{ fontSize: 10 }}
                      type="button"
                    >
                      <FontAwesomeIcon icon={faBan} fixedWidth />
                      停招
                    </button>
                  ) : (
                    <button
                      className="pull-right btn btn-link btn-lg text-success"
                      style={{ fontSize: 10 }}
                      onClick={() => handleDataStatus("在招")}
                      type="button"
                    >
                      复招
                    </button>
                  )}
                  <button
                    className="pull-right btn btn-link btn-lg text-success"
                    onClick={() => share(true)}
                    style={{ fontSize: 10 }}
                  >
                    <FontAwesomeIcon icon={faExternalLinkAlt} fixedWidth />
                    分享
                  </button>
                </div>
              </div>
              <hr style={{ marginTop: 0 }} />
              <div className="row" style={{ fontSize: 10 }}>
                <div className="col">
                  <TextFieldPhone1
                    category="职位名称"
                    name="name"
                    value={state.name}
                    req={false}
                    handleChange={(e) =>
                      dispatch({
                        type: "set",
                        payload: { key: "name", value: e.target.value },
                      })
                    }
                    required
                  />
                </div>
                <div className="col mb-3">
                  <label className="form-label">所属行业</label>
                  <select
                    value={state.industry}
                    className="form-control form-control-sm signin-right-input-phone"
                    style={{ fontSize: 10 }}
                    onChange={(e) =>
                      dispatch({
                        type: "set",
                        payload: { key: "industry", value: e.target.value },
                      })
                    }
                  >
                    <option value={state.industry}>{state.industry}</option>
                    {0 < state.INDUSTRY_LIST.length &&
                      state.INDUSTRY_LIST.map((current) => (
                        <option key={current.id} value={current.detail.name}>
                          {current.detail.name}
                        </option>
                      ))}
                    <option value=""> </option>
                  </select>
                </div>
                <div className="col mb-3">
                  <label className="form-label">职位</label>
                  <select
                    value={state.position}
                    className="form-control form-control-sm signin-right-input-phone"
                    style={{ fontSize: 10 }}
                    onChange={(e) =>
                      dispatch({
                        type: "set",
                        payload: { key: "position", value: e.target.value },
                      })
                    }
                  >
                    <option value={state.position}>{state.position}</option>
                    {0 < state.POSITION_LIST.length &&
                      state.POSITION_LIST.map((current) => (
                        <option key={current.id} value={current.detail.name}>
                          {current.detail.name}
                        </option>
                      ))}
                    <option value=""> </option>
                  </select>
                </div>
              </div>
              <div className="row" style={{ fontSize: 10 }}>
                <div className="col">
                  <SelectFieldPhone
                    category="职位类型"
                    name="category"
                    value={state.category}
                    handleChange={(e) =>
                      dispatch({
                        type: "set",
                        payload: { key: "category", value: e.target.value },
                      })
                    }
                  >
                    <option> </option>
                    <option>全职</option>
                    <option>兼职</option>
                    <option>实习</option>
                  </SelectFieldPhone>
                </div>
                <div className="col">
                  <SelectFieldPhone
                    category="学历要求"
                    name="education"
                    value={state.education}
                    handleChange={(e) =>
                      dispatch({
                        type: "set",
                        payload: { key: "education", value: e.target.value },
                      })
                    }
                  >
                    <option> </option>
                    <option>不限</option>
                    <option>高中及以上</option>
                    <option>大专及以上</option>
                    <option>本科及以上</option>
                    <option>硕士及以上</option>
                    <option>博士</option>
                  </SelectFieldPhone>
                </div>
                <div className="col">
                  <TextFieldPhone
                    category="招聘人数"
                    name="qty"
                    value={state.qty}
                    handleChange={(e) =>
                      dispatch({
                        type: "set",
                        payload: { key: "qty", value: e.target.value },
                      })
                    }
                  />
                </div>
              </div>
              <div className="row" style={{ fontSize: 10 }}>
                <div className="col">
                  <SelectFieldPhone1
                    category="省/直辖市"
                    name="address1"
                    value={state.address1}
                    req={false}
                    handleChange={(e) =>
                      dispatch({
                        type: "set",
                        payload: { key: "address1", value: e.target.value },
                      })
                    }
                    required
                  >
                    <option value={state.address1 || ""}>
                      {state.address1 || ""}
                    </option>
                    {state.ADDRESS_LEVEL1_LIST.map((item) => (
                      <option key={item.name}>{item.name}</option>
                    ))}
                  </SelectFieldPhone1>
                </div>
                <div className="col">
                  <SelectFieldPhone1
                    category="市"
                    name="address2"
                    value={state.address2}
                    req={false}
                    handleChange={(e) =>
                      dispatch({
                        type: "set",
                        payload: { key: "address2", value: e.target.value },
                      })
                    }
                    required
                  >
                    <option value={state.address2 || ""}>
                      {state.address2 || ""}
                    </option>
                    {0 < state.ADDRESS_LEVEL2_LIST.length &&
                      state.ADDRESS_LEVEL2_LIST.map((item) => (
                        <option key={item.name}>{item.name}</option>
                      ))}
                  </SelectFieldPhone1>
                </div>
                <div className="col">
                  <SelectFieldPhone1
                    category="区/县"
                    name="address3"
                    value={state.address3}
                    req={false}
                    handleChange={(e) =>
                      dispatch({
                        type: "set",
                        payload: { key: "address3", value: e.target.value },
                      })
                    }
                    required
                  >
                    <option value={state.address3 || ""}>
                      {state.address3 || ""}
                    </option>
                    {0 < state.ADDRESS_LEVEL3_LIST.length &&
                      state.ADDRESS_LEVEL3_LIST.map((item) => (
                        <option key={item.name}>{item.name}</option>
                      ))}
                  </SelectFieldPhone1>
                </div>
                <div className="row" style={{ fontSize: 10 }}>
                  <div className="col">
                    <label>
                      <span className="text-danger">*</span>
                      薪资要求
                      <span className="text-danger">(例如：4000-6000,薪资面议,请填0-0)</span>
                    </label>
                    <div className="row pl-3 pr-3">
                      <input
                        type="text"
                        name="salary1"
                        value={state.salary1}
                        style={{ fontSize: 10 }}
                        onChange={(e) =>
                          dispatch({
                            type: "set",
                            payload: { key: "salary1", value: e.target.value },
                          })
                        }
                        className="col form-control form-control-sm signin-right-input-phone"
                      />
                      -
                      <input
                        type="text"
                        name="salary2"
                        value={state.salary2}
                        style={{ fontSize: 10 }}
                        onChange={(e) =>
                          dispatch({
                            type: "set",
                            payload: { key: "salary2", value: e.target.value },
                          })
                        }
                        className="col form-control form-control-sm signin-right-input-phone"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row" style={{ fontSize: 10 }}>
                <div className="col">
                  <div className="form-group">
                    <label>职位要求</label>
                    <RichEditor
                      value={state.requirement}
                      name="requirement"
                      handleChange={(e) =>
                        dispatch({
                          type: "set",
                          payload: {
                            key: "requirement",
                            value: e.target.value,
                          },
                        })
                      }
                    />
                    <span
                      id="number"
                      className="pull-right"
                      style={{ color: "#7a858c" }}
                    >
                      {"<p><br></p>" === state.requirement
                        ? 0
                        : state.requirement.length - 7}
                      /200
                    </span>
                    <span
                      id="hide"
                      className="pull-left"
                      style={{ color: "#ff2121", display: "none" }}
                    >
                      请输入1-200个字符
                    </span>
                  </div>
                </div>
              </div>
              <div className="row" style={{ fontSize: 10 }}>
                <div className="col">
                  <div className="form-group">
                    <label>工作内容</label>
                    <RichEditor
                      value={state.description}
                      name="description"
                      handleChange={(e) =>
                        dispatch({
                          type: "set",
                          payload: {
                            key: "description",
                            value: e.target.value,
                          },
                        })
                      }
                    />
                    <span
                      id="number1"
                      className="pull-right"
                      style={{ color: "#7a858c" }}
                    >
                      {"<p><br></p>" === state.description
                        ? 0
                        : state.description.length - 7}
                      /200
                    </span>
                    <span
                      id="hide1"
                      className="pull-left"
                      style={{ color: "#ff2121", display: "none" }}
                    >
                      请输入1-200个字符
                    </span>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col">
                  <a className="btn btn-primary signin-right-input" href="#岗位/列表" style={{ fontSize: 8 }}>
                    返回
                  </a>
                </div>
                <div className="col">
                  <div className="pull-right">
                    <button
                      id="preservation"
                      className="btn btn-success signin-right-input"
                      style={{ fontSize: 8 }}
                      onClick={handleSave}
                      type="button"
                    >
                      保存
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white shadow mt-4">
          <div className="card signin-right-input-phone">
            <div className="card-body signin-right-input-phone">
              <div className="row">
                <div className="col">
                  <h6 className="pull-left">已收到简历</h6>
                </div>
              </div>
              <table className="table table-hover" style={{ fontSize: 10 }}>
                <thead>
                  <tr>
                    <th scope="col">求职者姓名</th>
                    <th scope="col">毕业院校</th>
                    <th scope="col">学历</th>
                    <th scope="col">投递时间</th>
                    <th scope="col">状态</th>
                    <th scope="col">操作</th>
                  </tr>
                </thead>
                <tbody>
                  {state.RESUME_LIST?.map((item) => (
                    <tr key={item.sendinId}>
                      <td>{item.name}</td>
                      <td>{item.school}</td>
                      <td>{item.education}</td>
                      <td>{item.publishTime}</td>
                      <td>{item.status}</td>
                      <td>
                        <div className="btn-group btn-group-sm">
                          <a
                            className="btn btn-primary"
                            style={{ fontSize: 10 }}
                            href={`#简历/列表/详情/${item.sendinId}?u_id=${item.uuid}`}
                          >
                            查看
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </View >
      <ModelPhone uuid={uuid} id={id} modalShow={modalShow} closeShare={() => share(false)}></ModelPhone>
    </>
  );
}
