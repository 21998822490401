import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    id: 0,
    uuid: '',
    name: '',
    email: '',
    phone: '',
    ref_id: 0,
    ref_uuid: '',
  },
  reducers: {
    setAuth: (state, action) => {
      state.id = action.payload.id;
      state.uuid = action.payload.uuid;
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.ref_id = action.payload.ref_id;
      state.ref_uuid = action.payload.ref_uuid;
      state.phone = action.payload.phone;
    },
  },
});

export const { setAuth } = authSlice.actions;

export default authSlice.reducer;
