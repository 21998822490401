import React from 'react';
import { ViewPhone } from './ComponentsPhone';

const ResumePhone = () => {
  return (
    <ViewPhone category="简历管理">
      <div className="card bg-white border-0 shadow signin-right-input-phone" style={{ fontSize: 12 }}>
        <div className="card-body">
          <div className="row">
            <div className="register-font text-primary">
              <h1>01</h1>
            </div>
            <div className="pt-1">
              <strong>点击【简历管理】按钮,可对投递的简历进行查看等操作</strong>
            </div>
          </div>
          <div className="register-img-phone pt-1">
            <img className='signin-right-input-phone' src="./lib/img/r-17.png" alt="" />
          </div>
          <hr style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
          <div className="row">
            <div className="register-font text-primary">
              <h1>02</h1>
            </div>
            <div className="pt-1">
              <strong>进入页面后,可在右侧图片中红框部分选择条件,点击【查询】按钮进行相应条件的查询,点击旁边的【刷新】按钮即可快速清空筛选条件,进行重新的选择</strong>
            </div>
          </div>
          <div className="register-img-phone pt-1">
            <img className='signin-right-input-phone' src="./lib/img/r-18.png" alt="" />
          </div>
          <hr style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
          <div className="row">
            <div className="register-font text-primary">
              <h1>03</h1>
            </div>
            <div className="pt-1">
              <strong>
                点击简历旁的【查看】按钮,即可进入简历详情页面进行阅简
              </strong>
            </div>
          </div>
          <div className="register-img-phone pt-1">
            <img className='signin-right-input-phone' src="./lib/img/r-19.png" alt="" />
          </div>
          <hr style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
          <div className="row">
            <div className="register-font text-primary">
              <h1>04</h1>
            </div>
            <div className="pt-1">
              <strong>
                进入到简历详情页面,可以查看到投简用户的个人信息、毕业院校、求职意向等信息,还可以点击右侧上方的按钮进行收藏、邀请面试、下载简历和下载简历附件,其中,下载简历为下载求职者在程序中所填写的简历,下载简历附件为下载求职者在程序中上传的简历附件
              </strong>
            </div>
          </div>
          <div className="register-img-phone pt-1">
            <img className='signin-right-input-phone' src="./lib/img/r-20.png" alt="" />
          </div>
          <hr style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
          <div className="row">
            <div className="register-font text-primary">
              <h1>05</h1>
            </div>
            <div className="pt-1">
              <strong>
                点击左侧【简历检索】,可以查看到注册平台的求职者信息,用户可以根据相应的条件进行搜索,点击简历旁边的【详情】,可进入到简历详情页面(备注：简历检索中的简历只允许被查看、收藏,并不可以进行面试邀请等操作)
              </strong>
            </div>
          </div>
          <div className="register-img-phone pt-1">
            <img className='signin-right-input-phone' src="./lib/img/r-21.png" alt="" />
          </div>
          <hr style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
          <div className="row">
            <div className="register-font text-primary">
              <h1>06</h1>
            </div>
            <div className="pt-1">
              <strong>
                点击左侧【我的收藏】,可以查看到收藏过的简历信息
              </strong>
            </div>
          </div>
          <div className="register-img-phone pt-1">
            <img className='signin-right-input-phone' src="./lib/img/r-22.png" alt="" />
          </div>
        </div>
      </div>
    </ViewPhone>
  );
};

export default ResumePhone;
