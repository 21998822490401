import md5 from "blueimp-md5";
import React, { useState } from "react";
import { useAuth } from "../utility/useAuth";
import { View } from "./Components";

const UpdatePassword = () => {
  const auth = useAuth(true);
  const [data, setData] = useState({
    old_password: "",
    password1: "",
    password2: "",
  });
  const [err, setErr] = useState({
    old_password: "",
    password1: "",
    password2: "",
  });
  const handleChange = (e) => {
    const { value, name } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdate = async () => {
    const errData = {};
    Object.getOwnPropertyNames(data).forEach((key) => {
      if (data[key].trim() === "") {
        errData[key] = "请填写内容";
      }
    });
    if (Object.getOwnPropertyNames(errData).length !== 0) {
      setErr(errData);
      window.console.info(errData);
      return;
    }
    if (data.password1 !== data.password2) {
      setErr(() => ({
        password1: "请确认密码",
        password2: "请确认密码",
      }));
      return;
    }
    const url = [
      `/gin-api/employer-user/${auth.uuid}/${auth.id}`,
      "?option=password"
    ];
    const response = await fetch(url.join(""), {
      method: "PATCH",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({
        password: md5(data.old_password),
        password1: md5(data.password1),
      }),
    });
    if (response.status < 400) {
      window.alert("操作成功,请重新登录");
      location.href = "#登录";
    } else {
      let alertFlg = false;
      if (typeof res.message === "object") {
        Object.getOwnPropertyNames(res.message).forEach((key) => {
          switch (key) {
            case "old_password":
              errData[key] = "密码错误!";
              break;
            default:
              alertFlg = true;
          }
        });
      } else {
        alertFlg = true;
      }
      if (alertFlg) {
        window.alert("服务器错误，请稍后重试");
      }
      setErr(errData);
    }
  };

  return (
    <View category="修改密码">
      <div className="row bg-white shadow signin-right-input">
        <div className="col card signin-right-input">
          <div className="card-body">
            <div className="row">
              <div className="col">
                <h4 className="pull-left" style={{ color: "#4a4a4a" }}><strong>修改密码</strong></h4>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col offset-3 col-lg-3 offset-lg-4">
                <form>
                  <div className="form-group">
                    <label>旧密码</label>
                    <input
                      className="form-control signin-right-input"
                      type="password"
                      placeholder="旧密码"
                      name="old_password"
                      autoComplete="off"
                      value={data.old_password}
                      onChange={handleChange}
                    />
                    {err.old_password && (
                      <small className="form-text text-danger">
                        {err.old_password}
                      </small>
                    )}
                  </div>
                  <div className="form-group">
                    <label>新密码</label>
                    <input
                      className="form-control signin-right-input"
                      type="password"
                      placeholder="新密码"
                      name="password1"
                      autoComplete="off"
                      value={data.password1}
                      onChange={handleChange}
                    />
                    {err.password1 && (
                      <small className="form-text text-danger">
                        {err.password1}
                      </small>
                    )}
                  </div>
                  <div className="form-group">
                    <label>确认密码</label>
                    <input
                      className="form-control signin-right-input"
                      type="password"
                      placeholder="确认密码"
                      name="password2"
                      autoComplete="off"
                      value={data.password2}
                      onChange={handleChange}
                    />
                    {err.password2 && (
                      <small className="form-text text-danger">
                        {err.password2}
                      </small>
                    )}
                  </div>
                </form>
              </div>
              <div className="mt-2 offset-3 col-lg-3 offset-lg-4">
                <button
                  className="w-100 btn btn-primary signin-right-input"
                  type="button"
                  onClick={handleUpdate}
                >
                  保存
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </View>
  );
};

export default UpdatePassword;
