import React, { useContext, useEffect, useState } from 'react';
import { MainContext } from "../utility/context";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faQrcode } from '@fortawesome/free-solid-svg-icons';

const ContentTitlePhone = () => {
  const auth = JSON.parse(sessionStorage.getItem("auth"));
  const [data, setData] = useState(0);
  const [_, dispatchContext] = useContext(MainContext);
  useEffect(() => {
    if (auth !== null) {
      fetch(`/gin-api/employer/${auth.ref_uuid}/${auth.ref_id}`)
        .then((response) => {
          if (response.status < 400) return response.json();
          else throw new Error("请求数据失败");
        })
        .then((data) => {
          setData(data);
          dispatchContext({ type: "setEmployer", value: data });
        })
        .catch((err) => window.console.error(err));
    }
  }, []);
  // const [banner, setBanner] = useState(0);

  // useEffect(() => {
  //   fetch('./api/banner/企业端-页头/')
  //     .then((res) => res.json())
  //     .then((res) => {
  //       if (res.message) {
  //         window.alert(res.message);
  //       } else {
  //         setBanner(res.content[0]);
  //       }
  //     });
  // }, []);

  return (
    <div className="row bg-white mr-2" style={{ fontSize: 10 }}>
      <div className="col px-3 mb-2" style={{ marginTop: "20px" }}>
        <img
          className="img-fluid pull-left logo2"
          alt=""
          src="./lib/img/logo3.png"
        />
      </div>
      <div className="col header-right pull-right" style={{ textAlign: "right", padding: 0 }}>
        <span className="text-secondary border-0 bg-transparent img-weixin">
          <FontAwesomeIcon icon={faQrcode} fixedWidth />
          求职用户登录
          <p>
            <img className="" alt="" style={{ zIndex: "1" }} src="./lib/img/qrcode.jpg" />
          </p>
        </span>
      </div>
      <div className="col header-right pull-right" style={{ textAlign: "center", padding: 0 }}>
        <a
          href="#操作手册/注册"
          className="text-secondary"
          style={{ textDecoration: 'none' }}
          target="_blank"
        >
          操作手册
        </a>
      </div>
      <div className='header-right pull-right'>
        <a href="#/我的/信息" className='text-secondary' style={{ textDecoration: 'none', textAlign: "center" }}>
          <span>{data.name}</span>
          <FontAwesomeIcon icon={faUser} fixedWidth />
        </a>
      </div>
    </div >
  );
};

export default ContentTitlePhone;
