import dayjs from "dayjs";
import React, { useState, useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField, SelectField, IndustrySearchField } from "../components/InputField";
import { setPage } from "../pageSlice";
import reducer from "../reducer";
import { setRetrieval } from "../retrievalSlice";
import { View } from "./Components";

const Retrieval = () => {
  const rowCount = 10;
  const [state, dispatch] = useReducer(reducer, {
    RETRIEVAL: useSelector((state) => state.retrieval.list),
    PAGE: useSelector((state) => state.page.number),
  });
  const [param, setParam] = useState({
    qiwanghangye: "",
    qiwangzhiwei: "",
    address2: "",
    education: "本科",
  });
  const [flag, setFlag] = useState(true);
  const reduxDispatch = useDispatch();

  const handleChange = (e) => {
    const { value, name } = e.target;
    setParam((prev) => ({ ...prev, [name]: value }));
  };
  const fetchData = () => {
    let url = [
      "/gin-api/resume",
      "?option=filterBy-addressLevel2-education-qiwanghangye-qiwangzhiwei",
      `&addressLevel2=${param.address2}`,
      `&education=${param.education}`,
      `&qiwanghangye=${param.qiwanghangye}`,
      `&qiwangzhiwei=${param.qiwangzhiwei}`,
      `&take=${rowCount}`,
      `&skip=${state["PAGE"] * rowCount}`
    ];
    fetch(url.join(""))
      .then((response) => {
        if (response.status < 400) return response.json();
        else throw new Error("请求数据失败");
      })
      .then((data) => {
        if (data?.length > 0) {
          dispatch({ type: "set", payload: { key: "RETRIEVAL", value: data } });
          reduxDispatch(setRetrieval({ list: data }));
          setFlag(true);
        } else {
          window.alert("暂时还没有数据");
          setFlag(false);
        }
      })
      .catch((err) => window.console.error(err));
  };
  const search = () => {
    dispatch({ type: "set", payload: { key: "PAGE", value: 0 } });
    fetchData();
  };
  const prev = () => {
    dispatch({ type: "set", payload: { key: "PAGE", value: state["PAGE"] - 1 } });
    reduxDispatch(setPage({ number: state["PAGE"] - 1 }));
  }
  const next = () => {
    dispatch({ type: "set", payload: { key: "PAGE", value: state["PAGE"] + 1 } });
    reduxDispatch(setPage({ number: state.PAGE + 1 }))
  }

  useEffect(() => {
    if (!state["RETRIEVAL"]?.length > 0) fetchData();
  }, [])

  useEffect(() => {
    fetchData();
  }, [state["PAGE"]]);

  return (
    <View category="检索">
      <div className="row px-5 pt-2 bg-white shadow signin-right-input" style={{ color: "#4a4a4a" }}>
        <IndustrySearchField
          industry={param.qiwanghangye}
          position={param.qiwangzhiwei}
          handleChange={handleChange}
        />
        <div className="col">
          <TextField
            category="期望地点"
            name="address2"
            value={param.address2}
            handleChange={handleChange}
          />
        </div>
        <div className="col">
          <SelectField
            category="学历"
            name="education"
            value={param.education}
            handleChange={handleChange}
          >
            <option>本科</option>
            <option>高中及以下</option>
            <option>大专</option>
            <option>硕士</option>
            <option>博士</option>
          </SelectField>
        </div>
        <div className="col">
          <br />
          <button onClick={search} className="btn btn-primary signin-right-input" type="button">
            查询
          </button>
        </div>
      </div>

      <div className="row mt-3 bg-white shadow card signin-right-input" style={{ color: "#4a4a4a" }}>
        <div className="col-12 signin-right-input">
          <div className="card-body">
            <h4 className="pull-left"><strong>简历检索</strong></h4>
            <table className="table table-hover" style={{ color: "#4a4a4a" }}>
              <thead>
                <tr>
                  <th scope="col">序号</th>
                  <th scope="col">姓名</th>
                  <th scope="col">期望行业</th>
                  <th scope="col">期望职位</th>
                  <th scope="col">期望地点</th>
                  <th scope="col">毕业院校</th>
                  <th scope="col">学历</th>
                  <th scope="col">操作</th>
                </tr>
              </thead>
              <tbody>
                {state.RETRIEVAL &&
                  state.RETRIEVAL.map((item, index) => (
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>{item.name}</td>
                      <td>{item.qiwanghangye}</td>
                      <td>{item.qiwangzhiwei}</td>
                      <td>{item.yixiangchengshi}</td>
                      <td>{item.school}</td>
                      <td>{item.education}</td>
                      <td>
                        <div className="btn-group btn-group-sm">
                          <a
                            className="btn btn-primary signin-right-input"
                            href={`#简历/检索/详情/${item.id}?u_id=${item.uuid}`}
                          >
                            查看
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="col text-right">
            {state.PAGE === 0 ? (
              <button
                disabled="disabled"
                className="btn btn-outline-primary"
                onClick={prev}
                type="button"
              >
                上一页
              </button>
            ) : (
              <button className="btn btn-outline-primary" onClick={prev} type="button">
                上一页
              </button>
            )}
          </div>
          <div className="col-1 text-center p-1">第{state.PAGE + 1}页</div>
          <div className="col">
            {flag ? (
              <button
                className="btn btn-outline-primary"
                type="button"
                onClick={next}
              >
                下一页
              </button>
            ) : (
              <button
                disabled="disabled"
                className="btn btn-outline-primary"
                type="button"
                onClick={next}
              >
                下一页
              </button>
            )}
          </div>
        </div>
      </div>
    </View>
  );
};

export default Retrieval;
