import { createSlice } from "@reduxjs/toolkit";

export const pageSlice = createSlice({
    name: "page",

    initialState: {
        number: 0,
    },
    reducers: {
        setPage: (state, action) => {
            console.log("setPage", action.payload);
            state.number = action.payload.number;
        },
    },
});

export const { setPage } = pageSlice.actions;

export default pageSlice.reducer;
