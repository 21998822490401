import { createSlice } from '@reduxjs/toolkit';

export const chartSlice = createSlice({
  name: 'chart',

  initialState: {
    list: [],
  },
  reducers: {
    setChart: (state, action) => {
      state.list = action.payload.list;
    },
  },
});

export const { setChart } = chartSlice.actions;

export default chartSlice.reducer;
