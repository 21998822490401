import React from 'react';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';

import Navbar from '../components/Navbar';
import ContentTitle from "../components/ContentTitle";
import Journal from './Journal';
import Feeback from './Feeback';

const Index = () => {
  React.useEffect(() => {
    const auth = sessionStorage.getItem('auth');
    if (auth === null) {
      location.href = '#登录';
    }
  }, []);

  return (
    <>
      <div className="container-fluid pb-4">
        <ContentTitle />
        <Navbar category="记录" totalFlg />
        <Router>
          <Switch>
            <Route exact path="/操作记录">
              <Journal />
            </Route>
            <Route exact path="/操作记录/投诉/">
              <Feeback />
            </Route>
          </Switch>
        </Router>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default Index;
