import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

export const Sidebar = ({ category }) => (
  <div className="list-group bg-white shadow sidebar signin-right-input-phone card" style={{ color: "#4a4a4a", fontSize: 12 }}>
    <a
      href="#操作记录"
      className={`list-group-item list-group-item-action border-0 font-weight-bold ${category === '操作记录' && 'text-primary'
        }`}
    >
      操作记录
      <span className="pull-right">
        <FontAwesomeIcon icon={faAngleRight} fixedWidth />
      </span>
    </a>
    <a
      href="#操作记录/投诉"
      className={`list-group-item list-group-item-action border-0 font-weight-bold ${category === '投诉' && 'text-primary'
        }`}
    >
      (意见反馈/投诉)记录
      <span className="pull-right">
        <FontAwesomeIcon icon={faAngleRight} fixedWidth />
      </span>
    </a>
  </div>
);

Sidebar.propTypes = {
  category: PropTypes.string.isRequired,
};

export const View = ({ category, children }) => (
  <div className="row px-1 mt-4">
    <div className="col-md-2 pb-2">
      <Sidebar category={category} />
    </div>
    <div className="col-md-10">{children}</div>
  </div>
);

View.propTypes = {
  category: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};
