import React from 'react';
import { ViewPhone } from './ComponentsPhone';

const RecordPhone = () => {
  return (
    <ViewPhone category="操作记录">
      <div className="card bg-white border-0 shadow signin-right-input-phone" style={{ fontSize: 12 }}>
        <div className="card-body">
          <div className="row">
            <div className="register-font text-primary">
              <h1>01</h1>
            </div>
            <div className="pt-1">
              <strong>点击页面中【操作记录】按钮，即可查看该账号的一些操作时间。</strong>
            </div>
          </div>
          <div className="register-img-phone pt-1">
            <img className='signin-right-input-phone' src="./lib/img/r-26.png" alt="" />
          </div>
          <hr style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
          <div className="row">
            <div className="register-font text-primary">
              <h1>02</h1>
            </div>
            <div className="pt-1">
              <strong>点击页面中【意见反馈/投诉记录】按钮，即可查看该账号反馈问题或投诉的记录。</strong>
            </div>
            <div className="register-img-phone pt-1">
              <img className='signin-right-input-phone' src="./lib/img/r-27.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </ViewPhone>
  );
};

export default RecordPhone;
