import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { View } from "./ComponentsPhone";

const FeedbackPhone = () => {
  const [auth, setAuth] = useState(0);
  const handleSave = () => {
    const content = document.getElementById("content").value;
    if (content === "") {
      alert("请填写内容");
      return;
    }
    // fetch('/api/feedback/', {
    fetch("/api/miscellaneous/feedback", {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({
        user_id: auth.id,
        user_uuid: auth.uuid,
        user_category: "企业用户",
        content,
        datime: dayjs().format("YYYY-MM-DD HH:mm"),
        category: document.getElementById("category").value,
      }),
    }).then((response) => {
      if (response.status === 200) alert("已提交到后台,我们将尽快处理");
      else alert("操作失败，请您稍后重试");
    });
  };

  useEffect(() => {
    const _auth = JSON.parse(sessionStorage.getItem("auth"));
    if (_auth === null) {
      return;
    }
    setAuth(_auth);
  }, []);

  return (
    <View category="投诉">
      <div className="bg-white shadow signin-right-input-phone">
        <div className="card signin-right-input-phone">
          <div className="card-body">
            <div className="row">
              <div className="col">
                <h5 className="pull-left" style={{ color: "#4a4a4a" }}><strong>意见反馈/投诉</strong></h5>
              </div>
            </div>
            <hr />
            <div className="row" style={{ fontSize: 10 }}>
              <div className="col">
                <div className="form-group">
                  <label>类别</label>
                  <select type="text" id="category" className="form-control signin-right-input-phone" style={{ fontSize: 10 }}>
                    <option>意见反馈</option>
                    <option value="投诉">内容投诉</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group" style={{ fontSize: 10 }}>
                  <label>说明</label>
                  <textarea id="content" cols={4} className="form-control signin-right-input-phone" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <button
                  className="btn btn-primary pull-right signin-right-input-phone"
                  style={{ fontSize: 10 }}
                  type="button"
                  onClick={handleSave}
                >
                  提交
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </View>
  );
};

export default FeedbackPhone;
