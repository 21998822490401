import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";
import dayjs from "dayjs";

export const TextField = ({ required, category, id, name, value, handleChange, req }) => (
  <div className="form-group">
    <label>
      <span className="text-danger">{required ? "*" : ""}</span>
      {category}
    </label>
    <input
      type="text"
      id={id}
      name={name}
      value={value || ""}
      onChange={handleChange}
      className={`form-control form-control-sm signin-right-input ${req ? "is-invalid" : ""}`}
    />
    <div className="invalid-feedback">{req || ""}</div>
  </div>
);

TextField.propTypes = {
  required: PropTypes.number,
  category: PropTypes.string.isRequired,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  req: PropTypes.string,
};

TextField.defaultProps = {
  required: undefined,
  id: undefined,
  req: undefined,
  value: undefined,
};

export const TextFieldPhone = ({ required, category, id, name, value, handleChange, req }) => (
  <div className="form-group">
    <label>
      <span className="text-danger">{required ? "*" : ""}</span>
      {category}
    </label>
    <input
      type="text"
      id={id}
      name={name}
      value={value || ""}
      onChange={handleChange}
      style={{ fontSize: 10 }}
      className={`form-control form-control-sm signin-right-input ${req ? "is-invalid" : ""}`}
    />
    <div className="invalid-feedback">{req || ""}</div>
  </div>
);

TextFieldPhone.propTypes = {
  required: PropTypes.number,
  category: PropTypes.string.isRequired,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  req: PropTypes.string,
};

TextFieldPhone.defaultProps = {
  required: undefined,
  id: undefined,
  req: undefined,
  value: undefined,
};

export const TextField1 = ({ required, category, id, name, value, handleChange, req }) => (
  <div className="form-group">
    <label>
      <span className="text-danger">{required ? "*" : ""}</span>
      {category}
    </label>
    <input
      type="text"
      id={id}
      name={name}
      value={value}
      onChange={handleChange}
      className={`form-control form-control-sm signin-right-input ${req ? "is-invalid" : ""}`}
    />
    <div className="invalid-feedback">{req || ""}</div>
  </div>
);

TextField1.propTypes = {
  required: PropTypes.bool,
  category: PropTypes.string.isRequired,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  req: PropTypes.bool,
};

TextField1.defaultProps = {
  required: undefined,
  id: undefined,
  req: undefined,
  value: undefined,
};

export const TextFieldPhone1 = ({ required, category, id, name, value, handleChange, req }) => (
  <div className="form-group">
    <label>
      <span className="text-danger">{required ? "*" : ""}</span>
      {category}
    </label>
    <input
      type="text"
      id={id}
      name={name}
      value={value}
      onChange={handleChange}
      style={{ fontSize: 10 }}
      className={`form-control form-control-sm signin-right-input ${req ? "is-invalid" : ""}`}
    />
    <div className="invalid-feedback">{req || ""}</div>
  </div>
);

TextFieldPhone1.propTypes = {
  required: PropTypes.bool,
  category: PropTypes.string.isRequired,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  req: PropTypes.bool,
};

TextFieldPhone1.defaultProps = {
  required: undefined,
  id: undefined,
  req: undefined,
  value: undefined,
};

export const SelectField = ({
  required,
  category,
  id,
  name,
  value,
  handleChange,
  req,
  children,
}) => (
  <div className="form-group">
    <label>
      <span className="text-danger">{required ? "*" : ""}</span>
      {category}
    </label>
    <select
      type="text"
      id={id}
      name={name}
      value={value || ""}
      onChange={handleChange}
      className={`form-control form-control-sm signin-right-input ${req ? "is-invalid" : ""}`}
    >
      {children}
    </select>
    <div className="invalid-feedback">{req}</div>
  </div>
);

SelectField.propTypes = {
  required: PropTypes.number,
  category: PropTypes.string.isRequired,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  children: PropTypes.arrayOf(PropTypes.any).isRequired,
  req: PropTypes.string,
};

SelectField.defaultProps = {
  required: undefined,
  id: undefined,
  req: undefined,
  value: undefined,
};

export const SelectFieldPhone = ({
  required,
  category,
  id,
  name,
  value,
  handleChange,
  req,
  children,
}) => (
  <div className="form-group">
    <label>
      <span className="text-danger">{required ? "*" : ""}</span>
      {category}
    </label>
    <select
      type="text"
      id={id}
      name={name}
      value={value || ""}
      onChange={handleChange}
      style={{ fontSize: 10 }}
      className={`form-control form-control-sm signin-right-input ${req ? "is-invalid" : ""}`}
    >
      {children}
    </select>
    <div className="invalid-feedback">{req}</div>
  </div>
);

SelectFieldPhone.propTypes = {
  required: PropTypes.number,
  category: PropTypes.string.isRequired,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  children: PropTypes.arrayOf(PropTypes.any).isRequired,
  req: PropTypes.string,
};

SelectFieldPhone.defaultProps = {
  required: undefined,
  id: undefined,
  req: undefined,
  value: undefined,
};

export const SelectField1 = ({
  required,
  category,
  id,
  name,
  value,
  handleChange,
  req,
  children,
}) => (
  <div className="form-group">
    <label>
      <span className="text-danger">{required ? "*" : ""}</span>
      {category}
    </label>
    <select
      type="text"
      id={id}
      name={name}
      value={value || ""}
      onChange={handleChange}
      className={`form-control form-control-sm signin-right-input ${req ? "is-invalid" : ""}`}
    >
      {children}
    </select>
    <div className="invalid-feedback">{req}</div>
  </div>
);

SelectField1.propTypes = {
  required: PropTypes.bool,
  category: PropTypes.string.isRequired,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  children: PropTypes.arrayOf(PropTypes.any).isRequired,
  req: PropTypes.bool,
};

SelectField1.defaultProps = {
  required: undefined,
  id: undefined,
  req: undefined,
  value: undefined,
};

export const SelectFieldPhone1 = ({
  required,
  category,
  id,
  name,
  value,
  handleChange,
  req,
  children,
}) => (
  <div className="form-group">
    <label>
      <span className="text-danger">{required ? "*" : ""}</span>
      {category}
    </label>
    <select
      type="text"
      id={id}
      name={name}
      value={value || ""}
      onChange={handleChange}
      style={{ fontSize: 10 }}
      className={`form-control form-control-sm signin-right-input ${req ? "is-invalid" : ""}`}
    >
      {children}
    </select>
    <div className="invalid-feedback">{req}</div>
  </div>
);

SelectFieldPhone1.propTypes = {
  required: PropTypes.bool,
  category: PropTypes.string.isRequired,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  children: PropTypes.arrayOf(PropTypes.any).isRequired,
  req: PropTypes.bool,
};

SelectFieldPhone1.defaultProps = {
  required: undefined,
  id: undefined,
  req: undefined,
  value: undefined,
};

export const DateField = ({ category, id, name, value, handleChange }) => (
  <div className="form-group">
    <label>{category}</label>
    <input
      type="date"
      id={id}
      name={name}
      value={value}
      onChange={handleChange}
      className="form-control form-control-sm rounded-0"
    />
  </div>
);

DateField.propTypes = {
  category: PropTypes.string.isRequired,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

DateField.defaultProps = {
  id: undefined,
};

export const DateFieldPhone = ({ category, id, name, value, handleChange }) => (
  <div className="form-group">
    <label>{category}</label>
    <input
      type="date"
      id={id}
      name={name}
      value={value}
      onChange={handleChange}
      style={{ fontSize: 10 }}
      className="form-control form-control-sm rounded-0"
    />
  </div>
);

DateFieldPhone.propTypes = {
  category: PropTypes.string.isRequired,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

DateFieldPhone.defaultProps = {
  id: undefined,
};

export const IndustryField = (props) => {
  const [industry, setIndustry] = React.useState([]);
  const [position, setPosition] = React.useState([]);
  const { industry: industry1, position: position1, handleChange } = props;
  const handleIndustry = (e) => {
    const { value, name } = e.target;
    const _position = industry.filter(
      (item) => item.master_id === industry.find((it) => it.name === value).id
    );
    setPosition(_position);
    props.handleChange({
      target: {
        name,
        value,
      },
    });
  };

  useEffect(() => {
    // fetch('/api/common-data/hangye/')
    fetch("/api/crate/single/setting?option=by-category&category=行业")
      .then((response) => response.json())
      .then((data) => {
        localStorage.setItem(
          "industry",
          JSON.stringify({
            date: parseInt(dayjs().add(7, "day").format("YYYYMMDD"), 10),
            data: data,
          })
        );
        setIndustry(() => data);
      });
  }, []);

  useEffect(() => {
    if (industry.length > 0) {
      if (props.industry && props.industry !== "") {
        let master = industry.find((item) => item.name === props.industry);
        if (!master) {
          [master] = industry;
          props.handleChange({
            target: {
              name: "industry",
              value: industry[0].name,
            },
          });
        }
        const _position = industry.filter((item) => item.master_id === master.id);
        setPosition(_position);
      } else {
        const _position = industry.filter((item) => item.master_id === industry[0].id);
        setPosition(_position);
        props.handleChange({
          target: {
            name: "industry",
            value: industry[0].name,
          },
        });
      }
    }
  }, [industry, props]);

  React.useEffect(() => {
    if (position.length > 0) {
      if (props.position && props.position !== "") {
        if (!position.find((item) => item.name === props.position)) {
          props.handleChange({
            target: {
              name: "position",
              value: position[0].name,
            },
          });
        }
      } else {
        props.handleChange({
          target: {
            name: "position",
            value: position[0].name,
          },
        });
      }
    }
  }, [position, props]);

  return (
    <>
      <div className="col">
        <SelectField
          category="所属行业"
          name="industry"
          value={industry1}
          handleChange={handleIndustry}
        >
          {industry &&
            industry
              .filter((item) => item.master_id === 0)
              .map((item) => <option key={item.id}>{item.name}</option>)}
        </SelectField>
      </div>
      <div className="col">
        <SelectField
          category="所属职位"
          name="position"
          value={position1}
          handleChange={handleChange}
        >
          {position.map((item) => (
            <option key={item.id}>{item.name}</option>
          ))}
        </SelectField>
      </div>
    </>
  );
};

IndustryField.propTypes = {
  industry: PropTypes.string.isRequired,
  position: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
};

IndustryField.defaultProps = {
  position: undefined,
};

export const IndustryFieldPhone = (props) => {
  const [industry, setIndustry] = React.useState([]);
  const [position, setPosition] = React.useState([]);
  const { industry: industry1, position: position1, handleChange } = props;
  const handleIndustry = (e) => {
    const { value, name } = e.target;
    const _position = industry.filter(
      (item) => item.master_id === industry.find((it) => it.name === value).id
    );
    setPosition(_position);
    props.handleChange({
      target: {
        name,
        value,
      },
    });
  };

  useEffect(() => {
    // fetch('/api/common-data/hangye/')
    fetch("/api/crate/single/setting?option=by-category&category=行业")
      .then((response) => response.json())
      .then((data) => {
        localStorage.setItem(
          "industry",
          JSON.stringify({
            date: parseInt(dayjs().add(7, "day").format("YYYYMMDD"), 10),
            data: data,
          })
        );
        setIndustry(() => data);
      });
  }, []);

  useEffect(() => {
    if (industry.length > 0) {
      if (props.industry && props.industry !== "") {
        let master = industry.find((item) => item.name === props.industry);
        if (!master) {
          [master] = industry;
          props.handleChange({
            target: {
              name: "industry",
              value: industry[0].name,
            },
          });
        }
        const _position = industry.filter((item) => item.master_id === master.id);
        setPosition(_position);
      } else {
        const _position = industry.filter((item) => item.master_id === industry[0].id);
        setPosition(_position);
        props.handleChange({
          target: {
            name: "industry",
            value: industry[0].name,
          },
        });
      }
    }
  }, [industry, props]);

  React.useEffect(() => {
    if (position.length > 0) {
      if (props.position && props.position !== "") {
        if (!position.find((item) => item.name === props.position)) {
          props.handleChange({
            target: {
              name: "position",
              value: position[0].name,
            },
          });
        }
      } else {
        props.handleChange({
          target: {
            name: "position",
            value: position[0].name,
          },
        });
      }
    }
  }, [position, props]);

  return (
    <>
      <div className="col">
        <SelectFieldPhone
          category="所属行业"
          name="industry"
          value={industry1}
          handleChange={handleIndustry}
        >
          {industry &&
            industry
              .filter((item) => item.master_id === 0)
              .map((item) => <option key={item.id}>{item.name}</option>)}
        </SelectFieldPhone>
      </div>
      <div className="col">
        <SelectFieldPhone
          category="所属职位"
          name="position"
          value={position1}
          handleChange={handleChange}
        >
          {position.map((item) => (
            <option key={item.id}>{item.name}</option>
          ))}
        </SelectFieldPhone>
      </div>
    </>
  );
};

IndustryFieldPhone.propTypes = {
  industry: PropTypes.string.isRequired,
  position: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
};

IndustryFieldPhone.defaultProps = {
  position: undefined,
};

export const IndustrySearchField = (props) => {
  const [industry, setIndustry] = useState([]);
  const [position, setPosition] = useState([]);
  const { industry: industry1, position: position1, handleChange } = props;

  useEffect(() => {
    const getIndustry = () => {
      const url = [
        "/crate-api/setting",
        "?option=filterBy-category",
        "&category=行业"
      ];
      fetch(url.join(""))
        .then((response) => {
          if (response.status < 400) return response.json();
          else throw new Error("请求数据失败");
        })
        .then((data) => {
          sessionStorage.setItem(
            "industry",
            JSON.stringify(data)
          );
          setIndustry(data);
        })
        .catch((err) => window.console.error(err));
    };
    const _industry = JSON.parse(sessionStorage.getItem("industry"));
    if (_industry !== null) {
      setIndustry(_industry);
    } else {
      getIndustry();
    }
  }, []);

  const handleIndustry = (e) => {
    const { value, name } = e.target;
    if (value === "") {
      setPosition([]);
    } else {
      const getRefId = (l, v) => l.find((c) => c["detail"]?.name === v)?.id;
      const getPositionList = (l, id) => l.filter((c) => c["ref_id"].toString() === id);
      setPosition(getPositionList(industry, getRefId(industry, value)));
    }
    props.handleChange({ target: { name, value } });
    props.handleChange({ target: { name: "qiwangzhiwei", value: "" } });
  };

  return (
    <>
      <div className="col">
        <SelectField
          category="期望行业"
          name="qiwanghangye"
          value={industry1}
          handleChange={handleIndustry}
        >
          <option></option>
          {industry?.filter((item) => item["ref_id"] === 0)
            .map((item) => <option key={item["id"]}>{item["detail"]?.name}</option>)}
        </SelectField>
      </div>
      <div className="col">
        <SelectField
          category="期望职位"
          name="qiwangzhiwei"
          value={position1}
          handleChange={handleChange}
        >
          <option></option>
          {position.map((item) => (
            <option key={item["id"]}>{item["detail"]?.name}</option>
          ))}
        </SelectField>
      </div>
    </>
  );
};

IndustrySearchField.propTypes = {
  industry: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export const IndustrySearchFieldPhone = (props) => {
  const [industry, setIndustry] = useState([]);
  const [position, setPosition] = useState([]);
  const { industry: industry1, position: position1, handleChange } = props;

  useEffect(() => {
    const getIndustry = () => {
      const url = [
        "/crate-api/setting",
        "?option=filterBy-category",
        "&category=行业"
      ];
      fetch(url.join(""))
        .then((response) => {
          if (response.status < 400) return response.json();
          else throw new Error("请求数据失败");
        })
        .then((data) => {
          sessionStorage.setItem(
            "industry",
            JSON.stringify(data)
          );
          setIndustry(data);
        })
        .catch((err) => window.console.error(err));
    };
    const _industry = JSON.parse(sessionStorage.getItem("industry"));
    if (_industry !== null) {
      setIndustry(_industry);
    } else {
      getIndustry();
    }
  }, []);

  const handleIndustry = (e) => {
    const { value, name } = e.target;
    if (value === "") {
      setPosition([]);
    } else {
      const getRefId = (l, v) => l.find((c) => c["detail"]?.name === v)?.id;
      const getPositionList = (l, id) => l.filter((c) => c["ref_id"].toString() === id);
      setPosition(getPositionList(industry, getRefId(industry, value)));
    }
    props.handleChange({ target: { name, value } });
    props.handleChange({ target: { name: "qiwangzhiwei", value: "" } });
  };

  return (
    <>
      <div className="col">
        <SelectFieldPhone
          category="期望行业"
          name="qiwanghangye"
          value={industry1}
          handleChange={handleIndustry}
        >
          <option></option>
          {industry?.filter((item) => item["ref_id"] === 0)
            .map((item) => <option key={item["id"]}>{item["detail"]?.name}</option>)}
        </SelectFieldPhone>
      </div>
      <div className="col">
        <SelectFieldPhone
          category="期望职位"
          name="qiwangzhiwei"
          value={position1}
          handleChange={handleChange}
        >
          <option></option>
          {position.map((item) => (
            <option key={item["id"]}>{item["detail"]?.name}</option>
          ))}
        </SelectFieldPhone>
      </div>
    </>
  );
};

IndustrySearchFieldPhone.propTypes = {
  industry: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};