import React, { useContext, useEffect, useReducer } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faSync } from "@fortawesome/free-solid-svg-icons";
import reducer from "../reducer";
import { View } from "./ComponentsPhone";
import dayjs from "dayjs";
import { MainContext } from "../utility/context";

export default function ListPhone() {
  const auth = JSON.parse(sessionStorage.getItem("auth"));
  const [state, dispatch] = useReducer(reducer, {
    JOB_LIST: [],
    PROGRESS: 0,
  });

  const refresh = (id, uuid) => {
    // fetch(`/api/biz/job/${id}?option=refresh`, {
    fetch(`/gin-api/job/${uuid}/${id}?option=refreshTime`, {
      method: "PUT",
    }).then((response) => {
      if (response.status < 400) alert("刷新成功，您的岗位会更容易被求职者看到！");
      else alert("操作失败，请您稍后重试");
    });
  };

  useEffect(() => {
    if (auth === null) location.href = "#/登录";
  }, []);

  useEffect(() => {
    const url = [
      "/gin-api/job",
      "?option=filterBy-refId-status",
      `&refId=${auth.ref_id}`,
      "&status="
    ];
    fetch(url.join(""))
      .then((response) => response.json())
      .then((data) => {
        if (data !== null) {
          dispatch({ type: "set", payload: { key: "JOB_LIST", value: data } });
          dispatch({
            type: "set",
            payload: { key: "PROGRESS", value: state.PROGRESS + 1 },
          });
        }
      });
  }, []);

  useEffect(() => {
    if (1 === state.PROGRESS) {
      let url = [
        "/gin-api/sendin",
        "?option=filterBy-ref1Ids-timeBegin-groupBy-ref1Id",
        `&ref1Ids=${state.JOB_LIST.map((c) => c.id).join(",")}`,
        `&timeBegin=2020-01-01`
      ];
      fetch(url.join(""))
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (data !== null) {
            let a = state.JOB_LIST.map((c) => {
              let sendin = data.find((element) => element["category"] === `${c.id}`);
              if ("undefined" === typeof sendin) {
                return Object.assign(c, { send_in_qty: 0 });
              } else {
                return Object.assign(c, {
                  send_in_qty: data.find((e) => e["category"] === `${c.id}`)["qty"],
                });
              }
            });
            dispatch({ type: "set", payload: { key: "JOB_LIST", value: a } });
            dispatch({
              type: "set",
              payload: { key: "PROGRESS", value: state.PROGRESS + 1 },
            });
          }
        });
    }
  }, [state.PROGRESS]);

  return (
    <View category="我的职位">
      <div className="mt-1 bg-white shadow signin-right-input-phone" style={{ textAlign: "center" }}>
        <div className="card signin-right-input-phone">
          <div className="card-body" style={{ padding: 0 }}>
            <h6 className="pull-left pt-2 pl-2" style={{ color: "#4a4a4a" }}><strong>岗位列表</strong></h6>
            <a className="pull-right btn btn-link btn-lg" href="#岗位/新增" style={{ fontSize: 10 }}>
              <FontAwesomeIcon icon={faPlusCircle} fixedWidth />
              新增
            </a>
            <table className="table table-hover" style={{ fontSize: 5, color: "#4a4a4a" }}>
              <thead>
                <tr>
                  <th scope="col" style={{ padding: 10, textAlign: "center" }}> </th>
                  <th scope="col" style={{ padding: 10, textAlign: "center" }}>序号</th>
                  <th scope="col" style={{ padding: 10, textAlign: "center" }}>岗位名称</th>
                  {/* <th scope="col">岗位类型</th> */}
                  {/* <th scope="col">所属行业</th>
                  <th scope="col">所属职位</th> */}
                  <th scope="col" style={{ padding: 10, textAlign: "center" }}>学历要求</th>
                  {/* <th scope="col">工作地点</th> */}
                  <th scope="col" style={{ padding: 10, textAlign: "center" }}>状态</th>
                  <th scope="col" style={{ padding: 10, textAlign: "center" }}>日期</th>
                  <th scope="col" style={{ padding: 10, textAlign: "center" }}>投递总数</th>
                  <th scope="col" style={{ padding: 10, textAlign: "center" }}>操作</th>
                </tr>
              </thead>
              <tbody style={{ fontSize: 5 }}>
                {0 < state.JOB_LIST.length &&
                  state.JOB_LIST.map((item, index) => (
                    <tr key={item.id}>
                      <td style={{ padding: 10 }}>
                        <button
                          type="button"
                          className="pull-right btn btn-link btn-lg"
                          style={{ fontSize: 5 }}
                          onClick={() => refresh(item["id"], item["uuid"])}
                        >
                          <FontAwesomeIcon icon={faSync} fixedWidth />
                          刷新
                        </button>
                      </td>
                      <td style={{ padding: 10, textAlign: "center" }}>{index + 1}</td>
                      <td style={{ padding: 10, textAlign: "center" }}>{item.name}</td>
                      {/* <td>{item.category}</td> */}
                      {/* <td>{item.industry}</td>
                      <td>{item.position}</td> */}
                      <td style={{ padding: 10, textAlign: "center" }}>{item.education}</td>
                      {/* <td>
                        {item["addressLevel1"]}-{item["addressLevel2"]}
                      </td> */}
                      <td style={{ padding: 10, textAlign: "center" }} className={item["status"] === "在招" ? "text-success" : "text-danger"}>{item.status}</td>
                      <td style={{ padding: 10, textAlign: "center" }}>{dayjs(item["refreshTime"]).format("YYYY-MM-DD")}</td>
                      <td style={{ padding: 10, textAlign: "center" }}>{item.send_in_qty}</td>
                      <td style={{ padding: 10, textAlign: "center" }}>
                        <div className="btn-group btn-group-sm">
                          <a
                            className="btn btn-primary signin-right-input-phone"
                            style={{ fontSize: 8 }}
                            href={`#岗位/编辑/${item.id}?u_id=${item.uuid}`}
                          >
                            详情
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </View>
  );
}
