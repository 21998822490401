import dayjs from "dayjs";
import { faStar, faComment, faPhoneAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Sidebar } from "./Components";
import { saveJournal } from "../commonFetch";
import { ResumeTemplate } from "./ResumeTemplate";

export default function ListDetails() {
  const { id } = useParams();
  const [modalShow, setModalShow] = useState(false);
  const [resume, setResume] = useState({ id: 0 });
  const [sendin, setSendin] = useState({ id: 0 });
  const { search } = useLocation();
  const [openid, setOpenid] = useState(0);
  const [careerList, setCareerList] = useState([]);
  const [recordList, setRecordList] = useState([]);
  const [certificateList, setCertificateList] = useState([]);
  const [skillList, setSkillList] = useState([]);
  // const [modalShow, setModalShow] = useState(false);
  const auth = JSON.parse(sessionStorage.getItem("auth"));
  const [favorite_id, setFavoriteId] = useState(0);

  useEffect(() => {
    if (auth === null) {
      window.location = "#登录";
    } else {
      // 投递记录，职位，简历
      fetch(`/api/simple/biz/sendin/${id}`)
        .then((response) => {
          if (response.status < 400) return response.json();
          else throw new Error("请求数据失败");
        })
        .then((data) => {
          setSendin(data);
        })
        .catch((err) => window.console.error(err));
    }
  }, [id, search]);

  useEffect(() => {
    // 获取投递记录后，获取简历
    if (sendin.id > 0) {
      fetch(`/api/simple/biz/resume/${sendin.resumeId}/${sendin.resumeUuid}`)
        .then((response) => {
          if (response.status < 400) return response.json();
          else throw new Error("请求数据失败");
        })
        .then((data) => {
          setResume(data);
          // setFlag(true);
          if (data.career !== undefined) {
            const arr = [];
            const career = JSON.parse(data.career);
            career.forEach((item) => {
              arr.push(JSON.parse(item));
            });
            setCareerList(arr);
          }
          if (data.record !== undefined) {
            const arr1 = [];
            const record = JSON.parse(data.record);
            record.forEach((item) => {
              arr1.push(JSON.parse(item));
            });
            setRecordList(arr1);
          }
          if (data.certificate !== undefined) {
            const certificate_arr = [];
            const certificate = JSON.parse(data.certificate);
            certificate.forEach((item) => {
              certificate_arr.push(JSON.parse(item));
            });
            setCertificateList(certificate_arr);
          }
          if (data.skill !== undefined && data.skill) {
            const skill_arr = [];
            const skill = JSON.parse(data.skill);
            skill.forEach((item) => {
              skill_arr.push(JSON.parse(item));
            });
            setSkillList(skill_arr);
          }
        })
        .catch((err) => window.console.error(err));
    }
  }, [sendin]);

  useEffect(() => {
    // 修改投递状态
    const timeout = window.setTimeout(() => {
      if (sendin.status === "已投递") {
        fetch(`/gin-api/sendin/${id}`, {
          method: "PUT",
          headers: { "content-type": "application/json" },
          body: JSON.stringify({ ...sendin, status: "已查看" }),
        })
          .then((response) => {
            if (response.status >= 400) throw new Error("请求数据失败");
          })
          .catch((err) => window.console.error(err));
      }
    }, 3000);
    return () => clearTimeout(timeout);
  }, [sendin]);

  useEffect(() => {
    if (openid && resume.name) {
      const jsonData = {
        touser: openid,
        mp_template_msg: {
          appid: "wx79586a354703320a",
          template_id: "_Y-691A3R76ND9o93Sm34zWLjI_7sslH5zjs-bO2ah8",
          url: "http://weixin.qq.com/download",
          miniprogram: {
            appid: "wxbf9bb377ed519ed8",
            pagepath: "",
          },
          data: {
            first: {
              value: "您好，您的简历已经被企业人力资源部查看",
              color: "#173177",
            },
            keyword1: {
              value: auth.name,
              color: "#173177",
            },
            keyword2: {
              value: auth.phone,
              color: "#173177",
            },
            keyword3: {
              value: resume.name,
              color: "#173177",
            },
            remark: {
              value: "请您随时关注！",
              color: "#173177",
            },
          },
        },
      };
      fetch("/api/wx-minip/token/", {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify(jsonData),
      });
    }
  }, [openid, resume]);

  useEffect(() => {
    // 获取简历对应用户的wx_openid
    if (resume.id > 0) {
      fetch(`/api/simple/biz/candidate/${resume.commonUserId}`)
        .then((response) => {
          if (response.status < 400) return response.json();
          else throw new Error("请求数据失败");
        })
        .then((data) => {
          console.log(data);
          setOpenid(data.wxOpenId);
        })
        .catch((err) => window.console.error(err));
    }
  }, [resume]);

  useEffect(() => {
    if (resume.id > 0) {
      document.getElementById("ziwopingjia").innerHTML = resume.ziwopingjia;
    }
  }, [resume]);

  useEffect(() => {
    // 检查当前简历的收藏状态
    if (resume.id > 0) {
      const url = [
        "/api/miscellaneous/favorite",
        "?option=by-ref_id-ref_id2-category-tag",
        `&ref_id=${auth.id}`,
        `&ref_id2=${resume.id}`,
        `&category=简历`,
        "&tag=企业用户",
      ];
      fetch(url.join(""))
        .then((response) => {
          if (response.status < 400) return response.json();
          else throw new Error("请求数据失败");
        })
        .then((data) => {
          if (data.length > 0) setFavoriteId(data[0].id);
        })
        .catch((err) => window.console.error(err));
    }
  }, [resume]);

  useEffect(() => {
    if (sendin.id > 0 && resume.id > 0) {
      if (sendin.status === "已投递") {
        saveJournal({
          ref_id: auth.id,
          ref_id2: resume.id,
          category: "查看简历",
          tag: "企业用户",
          ref_uuid: auth.uuid,
          ref_uuid2: resume.uuid,
        });
      }
    }
  }, [sendin, resume]);

  const handleFavorite = () => {
    if (favorite_id) {
      fetch(`/api/miscellaneous/favorite/${favorite_id}`, {
        method: "DELETE",
      }).then((response) => {
        if (response.status === 200) setFavoriteId(0);
      });
    } else {
      // 收藏简历
      fetch("/api/miscellaneous/favorite", {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({
          ref_id: auth.id,
          ref_id2: resume.id,
          category: "简历",
          tag: "企业用户",
          ref_uuid: auth.uuid,
          ref_uuid2: resume.uuid,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.insertId > 0) setFavoriteId(data.insertId);
        });
    }
  };

  const age = (birthday1) => {
    if (birthday1 && birthday1 !== "") {
      return `${parseInt(dayjs().format("YYYY"), 10) - parseInt(birthday1.slice(0, 4), 10)
        }岁`;
    } else {
      return "0岁";
    }
  };

  const phoneHide = (phone1) => {
    if (phone1 && phone1.length === 11) {
      return phone1;
    } else {
      return "错误的号码格式";
    }
  };

  const emailHide = (email1) => {
    if (email1 && email1.split("@").length > 1) {
      return email1;
    } else {
      return "错误的邮箱格式";
    }
  };

  // const handleExport = (e) => {
  //   // setModalShow(e);
  //   if (window.confirm("简历仅供公司内部筛选使用，切勿泄露外传！")) {
  //     window.open(
  //       `http://www.longzhaopin.com/api/resume/${resume.id}?option=export&u_id=${resume.uuid}&u_i=${resume.commonUserId}`,
  //       "_blank"
  //     );
  //   }
  // };

  const share = (e) => {
    setModalShow(e);
  };

  const handleAttachment = () => {
    fetch(`/api/miscellaneous/simple/file/${resume.id}?option=by-ref`).then((res) => {
      if (res.status === 200) {
        if (window.confirm("简历附件仅供公司内部筛选使用，切勿泄露外传！")) {
          window.open(
            `/api/miscellaneous/simple/file/${resume.id}?option=by-ref`,
            "_blank"
          );
        }
      } else {
        if (window.confirm("由于该用户没有上传简历附件，系统会自动下载该用户在本平台中填写的简历信息，请下载后仅供公司内部筛选使用，切勿泄露外传！")) {
          window.open(
            `https://www.longzhaopin.com/api/resume/${resume.id}?option=export&u_id=${resume.uuid}&u_i=${resume.commonUserId}`,
            "_blank"
          );
        }
      }
    });
  };

  const Bar = () => {
    return (
      <div className="pull-right">
        <button
          className="btn btn-light rounded-0 text-muted"
          onClick={handleFavorite}
          type="button"
        >
          {favorite_id > 0 ? (
            <FontAwesomeIcon icon={faStar} style={{ color: "#FFFF00" }} fixedWidth />
          ) : (
            <FontAwesomeIcon icon={faStar} fixedWidth />
          )}
          收藏
        </button>
        <a
          className="btn btn-light rounded-0 text-muted"
          href={`#简历/面试邀请/${id}`}
          type="button"
        >
          <FontAwesomeIcon icon={faComment} fixedWidth />
          邀请面试
        </a>
        {/* <button
          className="btn btn-light rounded-0 text-muted"
          onClick={handleExport}
          type="button"
        >
          下载简历
        </button> */}
        {/* <button
          className="btn btn-light rounded-0 text-muted"
          onClick={() => share(true)}
          type="button"
        >
          在线生成简历
        </button> */}
        <button
          className="btn btn-light rounded-0 text-muted"
          onClick={handleAttachment}
          type="button"
        >
          下载简历
        </button>
      </div>
    );
  };

  return (
    <div>
      <ResumeTemplate modalShow={modalShow} resume_name={resume.name} closeShare={() => share(false)}></ResumeTemplate>
      <div className="row px-5 mt-4">
        <div className="col-md-2 ">
          <Sidebar category="列表" />
        </div>
        <div className="col-md-10">
          <div className="row bg-white shadow signin-right-input">
            <div className="col card signin-right-input">
              <div className="card-body">
                <div className="row">
                  <div className="col item-middle">
                    <div>
                      <h3><strong>{resume.name}</strong></h3>
                      <span className="text-muted">
                        {resume.gender}
                        &nbsp;|&nbsp;
                        {age(resume.birthday)}
                        &nbsp;|&nbsp;
                        {resume.addressLevel2}
                        <br />
                        <FontAwesomeIcon icon={faPhoneAlt} fixedWidth />
                        {phoneHide(resume.phone)} &nbsp;
                        <FontAwesomeIcon icon={faEnvelope} fixedWidth />
                        {emailHide(resume.email)}
                      </span>
                    </div>
                  </div>
                  <div className="col pull-right">
                    <Bar />
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col">
                    <h4>求职意向</h4>
                    <span className="text-muted">
                      期望行业:&nbsp;
                      {resume.qiwanghangye}/{resume.qiwangzhiwei}
                      <br />
                      期望地点:&nbsp;
                      {resume.yixiangchengshi}
                    </span>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col">
                    <h4>毕业院校</h4>
                    <span style={{ fontSize: "larger" }}>{resume.school}</span>
                    <span className="text-muted">
                      /{resume.education}
                      <br />
                      在校时间: {resume.dateBegin}
                      &nbsp; - &nbsp;
                      {resume.dateEnd}
                      <br />
                      专业:{resume.major}
                    </span>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col">
                    <h4>求职状态</h4>
                    <div className="mt-1 text-muted">
                      求职状态：
                      {resume.status}
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col">
                    <h4>证书</h4>
                    {certificateList.length > 0 &&
                      certificateList.map((item, index) => (
                        <div className="row" key={index.toString()}>
                          <div className="col text-muted">
                            <strong style={{ fontSize: 15 }}>
                              证书名称：
                              {item.certificate}
                            </strong>
                            <div className="mt-1">
                              获得时间：
                              {item.time}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col">
                    <h4>专业技能</h4>
                    {skillList.length > 0 &&
                      skillList.map((item, index) => (
                        <div className="row" key={index.toString()}>
                          <div className="col text-muted">
                            <strong style={{ fontSize: 15 }}>
                              技能名称：
                              {item.name}
                            </strong>
                            <div className="mt-1">
                              使用时长：
                              {item.length}个月
                            </div>
                            <div className="mt-1">
                              掌握程度：
                              {item.level}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col">
                    <h4>工作经历</h4>
                    {careerList.length > 0 &&
                      careerList.map((item, index) => (
                        <div className="row" key={index.toString()}>
                          <div className="col text-muted">
                            <strong style={{ fontSize: 15 }}>
                              公司名称：
                              {item.employer}
                            </strong>
                            <div className="mt-1">
                              职位名称：
                              {item.title}
                            </div>
                            <div className="mt-1">
                              在职时间：
                              {item.date_begin}&nbsp;-&nbsp;
                              {item.date_end}
                            </div>
                            <div className="mt-1">
                              工作描述：
                              {item.description}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col">
                    <h4>项目经历</h4>
                    {recordList.length > 0 &&
                      recordList.map((item, index) => (
                        <div className="row" key={index.toString()}>
                          <div className="col text-muted">
                            <strong style={{ fontSize: 15 }}>
                              项目名称：
                              {item.name}
                            </strong>
                            <div className="mt-1">
                              职位名称：
                              {item.title}
                            </div>
                            <div className="mt-1">
                              项目时间：
                              {item.date_begin}&nbsp;-&nbsp;
                              {item.date_end}
                            </div>
                            <div className="mt-1">
                              项目职责：
                              {item.role}
                            </div>
                            <div className="mt-1">
                              项目描述：
                              {item.description}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col">
                    <h4>自我评价</h4>
                    <div className="text-muted" id="ziwopingjia" />
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col">
                    <button
                      onClick={() => window.history.back()}
                      className="btn btn-primary signin-right-input"
                      type="button"
                    >
                      返回
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
