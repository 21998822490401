import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { View } from './ComponentsPhone';

const OfferPhone = () => {
  const [list, setList] = useState([]);

  useEffect(() => {
    const _auth = JSON.parse(sessionStorage.getItem('auth'));

    if (_auth === null) {
      location.href = '#登录';
      return;
    }

    fetch(`./api/offer/ent/${_auth.ref_id}`)
      .then((res) => res.json())
      .then((res) => {
        setList(res.content);
      });
  }, []);

  return (
    <View category="邀请" totalFlg>
      <div className="bg-white shadow signin-right-input-phone">
        <div className="card signin-right-input-phone">
          <div className="card-body" style={{ padding: 0, }}>
            <h5 className='pt-2 pl-2' style={{ color: "#4a4a4a" }}><strong>面试邀请</strong></h5>
            <table className="table table-hover" style={{ color: "#4a4a4a", fontSize: 5 }}>
              <thead>
                <tr>
                  {/* <th scope="col">编号</th> */}
                  <th scope="col" style={{ padding: 5, textAlign: "center" }}>面试职位</th>
                  <th scope="col" style={{ padding: 5, textAlign: "center" }}>面试人</th>
                  <th scope="col" style={{ padding: 5, textAlign: "center" }}>面试地点</th>
                  <th scope="col" style={{ padding: 5, textAlign: "center" }}>联系电话1</th>
                  <th scope="col" style={{ padding: 5, textAlign: "center" }}>联系电话2</th>
                  <th scope="col" style={{ padding: 5, textAlign: "center" }}>面试时间</th>
                  <th scope="col" style={{ padding: 5, textAlign: "center" }}>发送时间</th>
                  <th scope="col" style={{ padding: 5, textAlign: "center" }}>状态</th>
                </tr>
              </thead>
              <tbody>
                {list &&
                  list.map((item) => (
                    <tr key={item.id}>
                      <td style={{ padding: 5, textAlign: "center" }}>{item.recruitment_name}</td>
                      <td style={{ padding: 5, textAlign: "center" }}>{item.user_name}</td>
                      <td style={{ padding: 5, textAlign: "center" }}>{item.address}</td>
                      <td style={{ padding: 5, textAlign: "center" }}>{item.phone1}</td>
                      <td style={{ padding: 5, textAlign: "center" }}>{item.phone2}</td>
                      <td style={{ padding: 5, textAlign: "center" }}>{moment(item.mianshishijian).format('YYYY-MM-DD H:mm',)}</td>
                      <td style={{ padding: 5, textAlign: "center" }}>{item.datime}</td>
                      <td style={{ padding: 5, textAlign: "center" }}>{item.status}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </View>
  );
};

export default OfferPhone;
