import { createSlice } from "@reduxjs/toolkit";

export const resumeSlice = createSlice({
  name: "resume",

  initialState: {
    job_list: [],
    list: [],
    number: 1,
  },
  reducers: {
    setResume: (state, action) => {
      console.log("setResume", action.payload);
      state.list = action.payload.list;
    },
    setJobList: (state, action) => {
      console.log("setJobList", action.payload);
      state.job_list = action.payload.job_list;
    },
    setNumber: (state, action) => {
      console.log("setNumber", action.payload);
      state.number = action.payload.number;
    },
  },
});

export const { setResume, setJobList, setNumber } = resumeSlice.actions;

export default resumeSlice.reducer;
