import React from 'react';
import { ViewPhone } from './ComponentsPhone';

const PostPhone = () => {
  return (
    <ViewPhone category="岗位管理">
      <div className="card bg-white border-0 shadow signin-right-input-phone" style={{ fontSize: 12 }}>
        <div className="card-body">
          <div className="row">
            <div className="register-font text-primary">
              <h1>01</h1>
            </div>
            <div className="pt-1">
              <strong>点击【岗位管理】按钮,可对企业的岗位进行增加或者修改操作</strong>
            </div>
          </div>
          <div className="register-img-phone pt-1">
            <img className='signin-right-input-phone' src="./lib/img/r-12.png" alt="" />
          </div>
          <hr style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
          <div className="row">
            <div className="register-font text-primary">
              <h1>02</h1>
            </div>
            <div className="pt-1">
              <strong>点击岗位管理页面中的【新增】按钮,则跳转到新增岗位页面</strong>
            </div>
          </div>
          <div className="register-img-phone pt-1">
            <img className='signin-right-input-phone' src="./lib/img/r-15.png" alt="" />
          </div>
          <hr style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
          <div className="row">
            <div className="register-font text-primary">
              <h1>03</h1>
            </div>
            <div className="pt-1">
              <strong>
                在此页面中,按照选项填写相应的信息,点击【保存】后,即可在小程序中发布岗位(备注:职位要求与工作内容字数限制为200字)
              </strong>
            </div>
          </div>
          <div className="register-img-phone pt-1">
            <img className='signin-right-input-phone' src="./lib/img/r-13.png" alt="" />
          </div>
          <hr style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
          <div className="row">
            <div className="register-font text-primary">
              <h1>04</h1>
            </div>
            <div className="pt-1">
              <strong>
                点击岗位管理页面中的【详情】按钮，即可进入编辑岗位页面
              </strong>
            </div>
          </div>
          <div className="register-img-phone pt-1">
            <img className='signin-right-input-phone' src="./lib/img/r-16.png" alt="" />
          </div>
          <hr style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
          <div className="row">
            <div className="register-font text-primary">
              <h1>05</h1>
            </div>
            <div className="pt-1">
              <strong>
                再此页面中，点击对应的选项即可修改岗位的内容，点击【停招】或【复招】也可对岗位状态进行更改，点击【分享】,系统会自动生成推广海报，用户只需保存至电脑中就可以自由的进行宣传
              </strong>
            </div>
          </div>
          <div className="register-img-phone pt-1">
            <img className='signin-right-input-phone' src="./lib/img/r-14.png" alt="" />
          </div>
        </div>
      </div>
    </ViewPhone>
  );
};

export default PostPhone;
