import React from 'react';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';

import NavbarPhone from '../components/NavbarPhone';
import ContentTitlePhone from "../components/ContentTitlePhone";
import JournalPhone from './JournalPhone';
import FeebackPhone from './FeebackPhone';

const Index = () => {
  React.useEffect(() => {
    const auth = sessionStorage.getItem('auth');
    if (auth === null) {
      location.href = '#登录';
    }
  }, []);

  return (
    <>
      <div className="container-fluid pb-4">
        <ContentTitlePhone />
        <NavbarPhone category="记录" totalFlg />
        <Router>
          <Switch>
            <Route exact path="/操作记录">
              <JournalPhone />
            </Route>
            <Route exact path="/操作记录/投诉/">
              <FeebackPhone />
            </Route>
          </Switch>
        </Router>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default Index;
