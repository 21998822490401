import React, { useEffect } from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAuth } from "../authSlice";
import Navbar from "../components/Navbar";
import ContentTitle from "../components/ContentTitle";
import Footer from "../components/Footer";
import List from "./List";
import Update from "./Update";

export default function Index() {
  const reduxDispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (auth === null) location.href = "#登录";
  }, []);

  useEffect(() => {
    if (0 === auth.id) {
      let a = sessionStorage.getItem("auth");
      if (null === a) location.href = "#登录";
      else reduxDispatch(setAuth(JSON.parse(a)));
    } else {
      fetch(`/api/biz/employer/${auth.ref_id}?uuid=${auth.ref_uuid}`)
        .then((response) => response.json())
        .then((data) => {
          if ("认证" !== data.status) {
            window.alert("您的企业尚未完成认证,请等待认证成功后在报名招聘会!");
            window.location.href = "#我的/信息/";
          } else {
            let a1 = JSON.parse(sessionStorage.getItem("auth"));
            if (!a1.phone) {
              alert("您还未填写手机号，请您将手机号填写完整");
              location.href = "#我的/用户/";
            }
          }
        });
    }
  }, []);

  return (
    <>
      <div className="flex-shrink-0">
        <div className="container-fluid pb-4">
          <ContentTitle />
          <Navbar category="招聘会" totalFlg />
          <Router>
            <Switch>
              <Route exact path="/招聘会/列表/">
                <List />
              </Route>
              <Route exact path="/招聘会/报名/:id/">
                <Update />
              </Route>
            </Switch>
          </Router>
        </div>
      </div>
      <Footer />
    </>
  );
}
