import { createSlice } from '@reduxjs/toolkit';

export const bannerSlice = createSlice({
  name: 'banner',

  initialState: {
    list: [],
  },
  reducers: {
    setBanner: (state, action) => {
      state.list = action.payload.list;
    },
  },
});

export const { setBanner } = bannerSlice.actions;

export default bannerSlice.reducer;
