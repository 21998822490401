import React from 'react';
import dayjs from 'dayjs';
import { View } from './Components';

const Journal = () => {
  const [list, setList] = React.useState([]);
  const auth = JSON.parse(sessionStorage.getItem('auth'));

  React.useEffect(() => {
    fetch(
      `/api/miscellaneous/journal?option=by-ref_id-tag&ref_id=${auth.id}&tag=企业用户`,
    )
      .then((response) => response.json())
      .then((data) => {
        setList(data);
      });
  }, []);

  // React.useEffect(() => {
  //   fetch(`./api/journal/login/企业用户/${auth.id}`)
  //     .then((res) => res.json())
  //     .then((res) => {
  //       if (res.message) {
  //         alert(res.message);
  //       } else {
  //         setList(res.content);
  //       }
  //     });
  // }, []);

  return (
    <View category="操作记录">
      <div className="row bg-white shadow signin-right-input">
        <div className="col card signin-right-input">
          <div className="card-body">
            <h4 className="pull-left" style={{ color: "#4a4a4a" }}><strong>操作记录</strong></h4>
            <table className="table table-hover" style={{ color: "#4a4a4a" }}>
              <thead>
                <tr>
                  <th scope="col">操作</th>
                  <th scope="col">时间</th>
                  <th scope="col">内容</th>
                </tr>
              </thead>
              <tbody>
                {list &&
                  list.map((current) => (
                    <tr key={current.id}>
                      <td>{current.category}</td>
                      <td>{dayjs(current.dtime).format('YYYY-MM-DD HH:mm')}</td>
                      <td>{current.category === '登录' ? current.ip : ''}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </View>
  );
};

export default Journal;
