import React, { useEffect } from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import NavbarPhone from "../components/NavbarPhone";
import ContentTitlePhone from "../components/ContentTitlePhone";
import EnterprisePhone from "./EnterprisePhone";
import UpdatePhone from "./UpdatePhone";
import FeedbackPhone from "./FeedbackPhone";
import UserPhone from "./UserPhone";
import UpdatePasswordPhone from "./UpdatePasswordPhone";
import RevisePhone from "./RevisePhone";

const Index = () => {
  useEffect(() => {
    const auth = sessionStorage.getItem("auth");
    if (auth === null) {
      location.href = "#登录";
    }
  }, []);

  return (
    <>
      <div className="container-fluid pb-4">
        <ContentTitlePhone />
        <NavbarPhone category="我的" totalFlg />
        <Router>
          <Switch>
            <Route exact path="/我的/信息/">
              <EnterprisePhone />
            </Route>
            <Route exact path="/我的/信息/编辑/">
              <UpdatePhone />
            </Route>
            <Route exact path="/我的/信息/认证后编辑/">
              <RevisePhone />
            </Route>
            <Route exact path="/我的/投诉/">
              <FeedbackPhone />
            </Route>
            <Route exact path="/我的/用户/">
              <UserPhone />
            </Route>
            <Route exact path="/我的/修改密码">
              <UpdatePasswordPhone />
            </Route>
          </Switch>
        </Router>
      </div>
    </>
  );
};

export default Index;
