import React, { useEffect, useReducer } from 'react';
import dayjs from "dayjs";
import reducer from "../reducer";

export const ModelPhone = (props) => {
  const { uuid, id, modalShow } = props;
  const closeShare = () => {
    props.closeShare();
  };
  useEffect(() => {
    const canvas = document.getElementById('myCanvas');
    const ctx = canvas.getContext('2d');
    const image = document.getElementById('source');
    image.addEventListener('load', (e) => {
      ctx.drawImage(image, 0, 0, 330, 538);
      createWhiteArea(ctx);
      createText(ctx);
      createCode(ctx);
    });
  }, []);

  const createWhiteArea = (ctx) => {
    ctx.fillStyle = "#fff";
    let x1 = 10;
    let y1 = 538 / 1.7;
    let contentWidth = 330 - x1 * 2
    let contentHeight = 538 / 2.5;
    ctx.moveTo(x1, y1)
    ctx.arcTo(x1 + contentWidth, y1, x1 + contentWidth, y1 + contentHeight, 20);
    ctx.arcTo(x1 + contentWidth, y1 + contentHeight, x1, y1 + contentHeight, 20);
    ctx.arcTo(x1, y1 + contentHeight, x1, y1, 20);
    ctx.arcTo(x1, y1, x1 + contentWidth, y1, 20);
    ctx.fill()
  };

  const createText = (ctx) => {
    if (uuid && id) {
      fetch(`/gin-api/job/${uuid}/${id}`)
        .then((response) => {
          if (response.status < 400) return response.json();
          else throw new Error("请求数据失败");
        })
        .then((data) => {
          let y1 = 538 / 1.7;
          let contentHeight = 538 / 2.5;
          let textHeight = contentHeight / 5;
          let arr = data.name.split("");
          let enterpriseArr = data.employer.name.split("");
          let postName = "";
          let enterpriseName = "";
          if (arr.length <= 15) {
            ctx.font = "normal bold 26px SimSun";
            ctx.fillStyle = "#000";
            ctx.fillText(dayjs().format("MM"), 10, 25);
            ctx.font = "normal bold 18px SimSun";
            ctx.fillStyle = "#000";
            ctx.fillText(`/${dayjs().format("DD")}`, 8, 45);
            ctx.font = "normal bold 20px Arial";
            ctx.fillStyle = "#000";
            ctx.fillText(data.name, 20, y1 + textHeight);
            ctx.font = "12px Arial";
            ctx.fillStyle = "#545454";
            ctx.fillText(`${data.addressLevel2} | ${data.education} | ${data.category}`, 20, y1 + textHeight * 2);
            // ctx.fillText("|", 32 + addressWidth, y1 + textHeight * 2)
            // ctx.fillText(data.education, 38 + addressWidth, y1 + textHeight * 2);
            // ctx.fillText("|", educationWidth + 52 + addressWidth, y1 + textHeight * 2)
            // ctx.fillText(data.category, educationWidth + 60 + addressWidth, y1 + textHeight * 2);
            ctx.font = "14px Arial";
            ctx.fillStyle = "#f3585e";
            ctx.fillText(`${data.salary1} — ${data.salary2}/月`, 20, y1 + textHeight * 3);
            // ctx.fillText("—", salary1Width + 30, y1 + textHeight * 3);
            // ctx.fillText(data.salary2, salary1Width + 46, y1 + textHeight * 3);
            // ctx.fillText("/月", salary1Width + 54 + salary2Width, y1 + textHeight * 3);
            if (enterpriseArr.length <= 20) {
              ctx.font = "normal bold 16px Arial";
              ctx.fillStyle = "#000";
              ctx.fillText(data.employer.name, 20, y1 + textHeight * 4);
            } else {
              enterpriseArr.forEach((item, index) => {
                enterpriseName += item;
                if (index === 19) {
                  ctx.font = "normal bold 16px Arial";
                  ctx.fillStyle = "#000";
                  ctx.fillText(enterpriseName, 20, y1 + textHeight * 4);
                  let _enterpriseArr = enterpriseArr.slice(20);
                  let _enterpriseName = _enterpriseArr.join("");
                  ctx.fillText(_enterpriseName, 20, y1 + textHeight * 4 + 20);
                }
              })
            }
          } else {
            arr.forEach((item, index) => {
              postName += item;
              if (index === 14) {
                ctx.font = "normal bold 26px SimSun";
                ctx.fillStyle = "#000";
                ctx.fillText(dayjs().format("MM"), 10, 25);
                ctx.font = "normal bold 18px SimSun";
                ctx.fillStyle = "#000";
                ctx.fillText(`/${dayjs().format("DD")}`, 8, 45);
                ctx.font = "normal bold 20px Arial";
                ctx.fillStyle = "#000";
                ctx.fillText(postName, 20, y1 + textHeight - 20);
                let _arr = arr.slice(15);
                let _postName = _arr.join("");
                ctx.fillText(_postName, 20, y1 + textHeight);
                ctx.font = "12px Arial";
                ctx.fillStyle = "#545454";
                ctx.fillText(`${data.addressLevel2} | ${data.education} | ${data.category}`, 20, y1 + textHeight * 2);
                // ctx.fillText("|", 32 + addressWidth, y1 + textHeight * 2)
                // ctx.fillText(data.education, 38 + addressWidth, y1 + textHeight * 2);
                // ctx.fillText("|", educationWidth + 52 + addressWidth, y1 + textHeight * 2)
                // ctx.fillText(data.category, educationWidth + 60 + addressWidth, y1 + textHeight * 2);
                ctx.font = "14px Arial";
                ctx.fillStyle = "#f3585e";
                ctx.fillText(`${data.salary1} — ${data.salary2}/月`, 20, y1 + textHeight * 3);
                // ctx.fillText("—", salary1Width + 30, y1 + textHeight * 3);
                // ctx.fillText(data.salary2, salary1Width + 46, y1 + textHeight * 3);
                // ctx.fillText("/月", salary1Width + 54 + salary2Width, y1 + textHeight * 3);
                if (enterpriseArr.length <= 20) {
                  ctx.font = "normal bold 16px Arial";
                  ctx.fillStyle = "#000";
                  ctx.fillText(data.employer.name, 20, y1 + textHeight * 4);
                } else {
                  enterpriseArr.forEach((item, index) => {
                    enterpriseName += item;
                    if (index === 19) {
                      ctx.font = "normal bold 16px Arial";
                      ctx.fillStyle = "#000";
                      ctx.fillText(enterpriseName, 20, y1 + textHeight * 4);
                      let _enterpriseArr = enterpriseArr.slice(20);
                      let _enterpriseName = _enterpriseArr.join("");
                      ctx.fillText(_enterpriseName, 20, y1 + textHeight * 4 + 20);
                    }
                  })
                }
              }
            });
          }
        })
        .catch((err) => window.console.error(err));
    }
  };

  const createCode = (ctx) => {
    let webUrl = `https://www.longzhaopin.com/wx/#/岗位/${id}/0`;
    let pathUrl = `/pages/index/index?url=${encodeURIComponent(webUrl)}`;
    fetch("api/wx-minip/qrcode", {
      method: "POST",
      headers: { "content-type": "application/json" },
      responseType: "arraybuffer",
      body: JSON.stringify({
        path: pathUrl,
        width: "280",
      }),
    })
      .then((response) => response.blob())
      .then((data) => {
        let proCodeX = 220;
        let y1 = 538 / 1.7;
        let contentHeight = 538 / 2.5;
        let proCodeY = y1 + contentHeight / 2 - 50;
        const downloadURL = window.URL.createObjectURL(data);
        const img = document.createElement("img");
        img.style.display = "none";
        img.src = downloadURL;
        img.addEventListener('load', (e) => {
          ctx.drawImage(img, proCodeX, proCodeY, 100, 100);
        });
      })
  };

  const Save = () => {
    const canvas = document.getElementById('myCanvas');
    let name = "publicity-poster.png";
    var a = document.createElement("a");
    a.href = canvas.toDataURL();
    a.download = name;
    a.click();
    window.alert("图片已经下载到您的电脑中,请注意查收！");
  };

  return (
    <div>
      <div className={`bg-white shadow rounded-lg poster ${modalShow === true ? "canvas-block" : "canvas-none"}`}>
        <div className='container1'>
          <canvas id="myCanvas" width="330" height="538"></canvas>
          <div className='saveImg'>
            <button type="button" className="btn btn-success" onClick={Save}>保存图片</button>
            &nbsp;&nbsp;
            <button type="button" className="btn btn-danger" onClick={closeShare}>关闭</button>
          </div>
        </div>
      </div>
      <div style={{ display: "none" }}>
        <img id="source" src="./lib/img/backgroundImage.jpg" alt="" />
      </div>
    </div>
  )
};
