import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import reducer from '../reducer';

export default function Banner() {
  const [state, dispatch] = React.useReducer(reducer, {
    title: '',
    datime: '',
    data_url: '',
    comment: '',
  });
  const { id } = useParams();
  const uuid = new URLSearchParams(useLocation().search).get('uuid');

  React.useEffect(() => {
    fetch(`/api/bulletin/${id}?option=banner&uuid=${uuid}`)
      .then((response) => response.json())
      .then((data) => {
        dispatch({ type: 'set', payload: { key: 'title', value: data.title } });
        dispatch({
          type: 'set',
          payload: { key: 'datime', value: data.datime },
        });
        dispatch({
          type: 'set',
          payload: { key: 'data_url', value: data.data_url },
        });
        dispatch({
          type: 'set',
          payload: { key: 'comment', value: data.comment },
        });
      });
  }, []);

  return (
    <div className="mt-4">
      <div className="row px-5">
        <div className="card col rounded-0 shadow px-4">
          <a href="#/">
            <h2 className="mt-4">
              <FontAwesomeIcon icon={faChevronLeft} size="lg" fixedWidth />
              {state.title}
            </h2>
          </a>
          <hr />
          <span className="text-mute">
            {dayjs(state.datime).format('YYYY-MM-DD')}
          </span>
          <img src={state.data_url} />
          <div
            className="px-5"
            dangerouslySetInnerHTML={{ __html: state.comment }}
          ></div>
        </div>
      </div>
    </div>
  );
}
