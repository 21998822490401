import React, { useContext, useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";
import { setBanner } from "../bannerSlice";
import { setChart } from "../chartSlice";
import reducer from "../reducer";
import { setTopic } from "../topicSlice";
import AutoCardsPhone from "../components/AutoCardsPhone";
import { useAuth } from "../utility/useAuth";
import { MainContext } from "../utility/context";

const TopicRow = (item) => {
  const reg = (str) => str && str.replace(/<[^<>]+>/g, "");
  const getTag = (tag) => {
    if (tag) {
      switch (tag) {
        case "职业发展":
          return <span className="text-primary">[{item.tag}]</span>;
        case "喜讯":
          return <span className="text-danger">[{item.tag}]</span>;
        case "职业规划":
          return <span className="text-success">[{item.tag}]</span>;
        default:
          return <span className="text-dark">[{item.tag}]</span>;
      }
    } else {
      return <span className="text-white">[]</span>;
    }
  };

  return (
    <>
      <div className="row">
        <div className="col">
          <a
            className="text-dark"
            style={{ textDecoration: "none" }}
            href={`#首页/${item.id}?u_id=${item.uuid}`}
          >
            <strong>
              {getTag(item.tag)}
              {item.title}
            </strong>
          </a>
        </div>
      </div>
      <div className="row">
        <div className="col-9">
          <span className="text-muted text-hidden">{reg(item.content)}</span>
        </div>
        <div className="col">
          {item.id ? (
            <a
              className="pull-right text-muted text-decoration-none"
              href={`#首页/${item.id}?u_id=${item.uuid}`}
            >
              阅读全文
              {">>>"}
            </a>
          ) : (
            <span className="pull-right text-white">x</span>
          )}
        </div>
      </div>
      {item.id && <hr />}
    </>
  );
};

// TopicRow.propTypes = {
//   item: PropTypes.string.isRequired,
// };

export default function HomePhone() {
  const [_, dispatchContext] = useContext(MainContext);
  const auth = useAuth(true);
  const disp = useDispatch();
  const [state, dispatch] = useReducer(reducer, {
    banner: useSelector((state) => state.banner.list) || [],
    topic: useSelector((state) => state.topic.list) || [],
    chart: useSelector((state) => state.chart.list) || [],
  });

  // banner
  useEffect(() => {
    if (!state.banner.length) {
      fetch("/api/bulletin?option=banner-by-category&category=企业端-首页")
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          disp(setBanner({ list: data }));
          dispatch({ type: "set", payload: { key: "banner", value: data } });
        });
    }
  }, []);

  // topic
  useEffect(() => {
    if (!state.topic.length) {
      fetch("/api/bulletin?option=topic-by-limit")
        .then((response) => response.json())
        .then((data) => {
          disp(setTopic({ list: data }));
          dispatch({ type: "set", payload: { key: "topic", value: data } });
        });
    }
  }, []);

  useEffect(() => {
    fetch(`/gin-api/employer/${auth.ref_uuid}/${auth.ref_id}`)
      .then((response) => response.status < 400 && response.json())
      .then((data) => {
        dispatchContext({ type: "setEmployer", value: data });
      });
  }, [])

  // 按期望职位统计
  useEffect(() => {
    if (!state.chart.length) {
      // fetch('/api/biz/resume/statistic?option=by-qiwangzhiwei')
      fetch("/gin-api/resume?option=statsBy-qiwangzhiwei")
        .then((response) => response.json())
        .then((data) => {
          disp(setChart({ list: data }));
          dispatch({ type: "set", payload: { key: "chart", value: data } });
        });
    }
  }, []);

  return (
    <div className="mt-4" style={{ margin: "auto", marginBottom: 10 }}>
      <div className="row">
        <div className="card shadow">
          <div className="card-body">
            {state.banner.length > 0 && <AutoCardsPhone list={state.banner} />}
          </div>
        </div>
      </div>
      &nbsp;&nbsp;&nbsp;
      <div className="row">
        <div className="card shadow">
          <div className="card-body">
            <div className="mt-4" style={{ fontSize: 12 }}>
              {/* {message && message.map((item, index) => <TopicRow {...item} key={index.toString()} />)} */}
              {state.topic.length > 0 &&
                state.topic.map((item, index) => (
                  <TopicRow {...item} key={index.toString()} />
                ))}
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="card shadow">
          <div className="mt-3" style={{ marginLeft: -15 }}>
            <BarChart
              width={420}
              height={200}
              data={state.chart}
              margin={{
                top: 5,
                right: 30,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="qiwangzhiwei" type="category" interval={0} style={{ fontSize: 5 }} />
              <YAxis type="number" style={{ fontSize: 8 }} />
              <Bar dataKey="qty" fill="#FCC802" />
            </BarChart>
          </div>
        </div>
      </div>
    </div>
  );
}
