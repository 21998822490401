import React from 'react';
import { ViewPhone } from './ComponentsPhone';

const JobFairPhone = () => {
  return (
    <ViewPhone category="报名招聘会">
      <div className="card bg-white border-0 shadow signin-right-input-phone" style={{ fontSize: 12 }}>
        <div className="card-body">
          <div className="row">
            <div className="register-font text-primary">
              <h1>01</h1>
            </div>
            <div className="pt-1">
              <strong>认证通过后,点击【招聘会】按钮,进入招聘会页面</strong>
            </div>
          </div>
          <div className="register-img-phone pt-1">
            <img className='signin-right-input-phone' src="./lib/img/r-8.png" alt="" />
          </div>
          <hr style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
          <div className="row">
            <div className="register-font text-primary">
              <h1>02</h1>
            </div>
            <div className="pt-1">
              <strong>进入招聘页面后,点击招聘会后面的【报名】按钮,进入招聘会详情页面</strong>
            </div>
          </div>
          <div className="register-img-phone pt-1">
            <img className='signin-right-input-phone' src="./lib/img/r-9.png" alt="" />
          </div>
          <hr style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
          <div className="row">
            <div className="register-font text-primary">
              <h1>03</h1>
            </div>
            <div className="pt-1">
              <strong>
                进入招聘会详情页面后,选择想要参加招聘会的岗位,点击【保存】后,即可报名当前招聘会
              </strong>
            </div>
          </div>
          <div className="register-img-phone pt-1">
            <img className='signin-right-input-phone' src="./lib/img/r-10.png" alt="" />
          </div>
          <hr style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
          <div className="row">
            <div className="register-font text-primary">
              <h1>04</h1>
            </div>
            <div className="pt-1">
              <strong>
                当招聘会后面的报名按钮变成编辑按钮时,则为成功报名招聘会
              </strong>
            </div>
          </div>
          <div className="register-img-phone pt-1">
            <img className='signin-right-input-phone' src="./lib/img/r-11.png" alt="" />
          </div>
        </div>
      </div>
    </ViewPhone>
  );
};

export default JobFairPhone;
