import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { setAuth } from '../authSlice';
import reducer from '../reducer';
// import { useSelector } from 'react-redux';

const Navbar = (props) => {
  // const auth = useSelector((state) => state.auth);
  // const auth = JSON.parse(sessionStorage.getItem('auth'));
  const [state, dispatch] = React.useReducer(reducer, { m_length: 0, b_length: 0 });
  const { category } = props;
  // const [message, setMessage] = React.useState(0);
  // const [totalFlg, setTotalFlg] = React.useState(false);
  const reduxDispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  React.useEffect(() => {
    if (auth === null) location.href = '#/登录';
  }, []);

  React.useEffect(() => {
    if (0 === auth.id) {
      let a = sessionStorage.getItem('auth');
      if (null === a) location.href = '#登录';
      else reduxDispatch(setAuth(JSON.parse(a)));
    } else {
      fetch(
        `/api/miscellaneous/message?option=group-ref_id-by-ref_id2-tag-category-status&ref_id2=${auth.id}&tag=个人用户&category=咨询企业&status=未读`,
      )
        .then((response) => response.json())
        .then((data) => {
          dispatch({
            type: 'set',
            payload: { key: 'm_length', value: data.length || 0 },
          });
        });
    }
  }, []);

  React.useEffect(() => {
    // 通知/公告
    fetch('/api/bulletin?option=bulletin-by-tag&tag=企业用户')
      .then((response) => response.json())
      .then((data) => {
        dispatch({
          type: 'set',
          payload: { key: 'b_length', value: data.length || 0 },
        });
      });
  }, []);

  React.useEffect(() => {
    // const jobId = setInterval(() => {
    //   if (auth !== null) {
    //     fetch(`./api/message/ent/total/${auth.id}`)
    //       .then((res) => res.json())
    //       .then((res) => {
    //         let total = 0;
    //         if (res.content) {
    //           total = res.content;
    //           setMessage(total);
    //         }
    //         fetch(`./api/message/sys/total/企业用户/${auth.id}`)
    //           .then((res1) => res1.json())
    //           .then((res1) => {
    //             if (res.content) {
    //               total += res1.content;
    //               setMessage(total);
    //             }
    //           });
    //       });
    //   }
    // }, 900000);
    // return () => window.clearInterval(jobId);
  }, []);

  // React.useEffect(() => {
  // if (totalFlg === props.totalFlg) {
  //   return;
  // }
  // setTotalFlg(props.totalFlg);
  // if (auth !== null && props.totalFlg) {
  //   fetch(`./api/message/ent/total/${auth.id}`)
  //     .then((res) => res.json())
  //     .then((res) => {
  //       console.info(1, res);
  //       let total = 0;
  //       if (res.content) {
  //         total = res.content;
  //         setMessage(total);
  //       }
  //       fetch(`./api/message/sys/total/企业用户/${auth.id}`)
  //         .then((res1) => res1.json())
  //         .then((res1) => {
  //           console.info(2, res);
  //           if (res.content) {
  //             total += res1.content;
  //             setMessage(total);
  //           }
  //         });
  //     });
  // }
  // }, [props, totalFlg]);

  return (
    <div className="row shadow-sm bg-white px-5 text-center font-weight-bold" style={{ fontFamily: "Microsoft YaHei", height: "60px" }}>
      <div className={`col ${category === '首页' && 'nav-bar-active'}`} style={{ margin: "auto" }}>
        <a className="nav-link text-dark" href="#/">
          首页
        </a>
      </div>
      <div className={`col ${category === '招聘会' && 'nav-bar-active'}`} style={{ margin: "auto" }}>
        <a className="nav-link text-dark" href="#/招聘会/列表/">
          招聘会
        </a>
      </div>
      <div className={`col ${category === '岗位' && 'nav-bar-active'}`} style={{ margin: "auto" }}>
        <a className="nav-link text-dark" href="#/岗位/列表">
          岗位管理
        </a>
      </div>
      <div className={`col ${category === '简历' && 'nav-bar-active'}`} style={{ margin: "auto" }}>
        <a className="nav-link text-dark" href="#/简历/列表">
          简历管理
        </a>
      </div>
      <div className={`col ${category === '消息' && 'nav-bar-active'}`} style={{ margin: "auto" }}>
        <a className="nav-link text-dark" href="#/消息/会话">
          消息&nbsp;
          {/* {message !== 0 ? <span className="badge badge-pill badge-danger">{message}</span> : <></>} */}
          {state.m_length + state.b_length > 0 ? (
            <span className="badge badge-pill badge-danger">
              {state.m_length + state.b_length}
            </span>
          ) : (
            <></>
          )}
        </a>
      </div>
      <div className={`col ${category === '记录' && 'nav-bar-active'}`} style={{ margin: "auto" }}>
        <a className="nav-link text-dark" href="#/操作记录/">
          操作记录
        </a>
      </div>
      <div className={`col ${category === '我的' && 'nav-bar-active'}`} style={{ margin: "auto" }}>
        <a className="nav-link text-dark" href="#/我的/信息/">
          我的
        </a>
      </div>
    </div>
  );
};

Navbar.propTypes = {
  totalFlg: PropTypes.bool,
  category: PropTypes.string.isRequired,
};

Navbar.defaultProps = {
  totalFlg: undefined,
};

export default Navbar;
