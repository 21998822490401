import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useParams, useLocation } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleRight,
  faPhoneAlt,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

export const Sidebar = ({ category }) => (
  <div className="list-group bg-white shadow sidebar signin-right-input-phone card" style={{ color: "#4a4a4a", fontSize: 10 }}>
    <a
      href="#简历/列表"
      className={`list-group-item list-group-item-action border-0 font-weight-bold ${category === '列表' && 'text-primary'
        }`}
    >
      已收到的简历
      <span className="pull-right">
        <FontAwesomeIcon icon={faAngleRight} fixedWidth />
      </span>
    </a>
    <a
      href="#/简历/检索"
      className={`list-group-item list-group-item-action border-0 font-weight-bold ${category === '检索' && 'text-primary'
        }`}
    >
      简历检索
      <span className="pull-right">
        <FontAwesomeIcon icon={faAngleRight} fixedWidth />
      </span>
    </a>

    <a
      href="#简历/收藏"
      className={`list-group-item list-group-item-action border-0 font-weight-bold ${category === '收藏' && 'text-primary'
        }`}
    >
      我的收藏
      <span className="pull-right">
        <FontAwesomeIcon icon={faAngleRight} fixedWidth />
      </span>
    </a>
    {/* <a
      href="#简历/推荐"
      className={`list-group-item list-group-item-action border-0 font-weight-bold ${
        category === '推荐' && 'text-primary'
      }`}
    >
      系统推荐
      <span className="pull-right">
        <FontAwesomeIcon icon={faAngleRight} fixedWidth />
      </span>
    </a> */}
  </div>
);

Sidebar.propTypes = {
  category: PropTypes.string.isRequired,
};

export const View = ({ category, children }) => (
  <div className="row px-1 mt-4">
    <div className="col-md-2 pb-2">
      <Sidebar category={category} />
    </div>
    <div className="col-md-10">{children}</div>
  </div>
);

View.propTypes = {
  category: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export const ResumeView = (props) => {
  useEffect(() => {
    if (props.common_user_id) {
      document.getElementById('ziwopingjia').innerHTML = props["ziwopingjia"];
    }
  }, [props]);

  const age = (birthday1) => {
    if (birthday1 && birthday1 !== '') {
      return `${parseInt(moment().format('YYYY'), 10) -
        parseInt(birthday1.slice(0, 4), 10)
        }岁`;
    } else {
      return '0岁';
    }
  };

  const phoneHide = (phone1) => {
    if (phone1 && phone1.length === 11) {
      return phone1;
    } else {
      return '错误的号码格式';
    }
  };

  const emailHide = (email1) => {
    if (email1 && email1.split('@').length > 1) {
      return email1;
    } else {
      return '错误的邮箱格式';
    }
  };

  return (
    <>
      <div className="row">
        <div className="col item-middle" style={{ fontSize: 13 }}>
          <div>
            <h5><strong>{props["name"]}</strong></h5>
            <span className="text-muted">
              {props["gender"]}
              &nbsp;|&nbsp;
              {age(props["birthday"])}
              &nbsp;|&nbsp;
              {props["address2"]}
              <br />
              <FontAwesomeIcon icon={faPhoneAlt} fixedWidth />
              {phoneHide(props["phone"])} &nbsp;
              <br />
              <FontAwesomeIcon icon={faEnvelope} fixedWidth />
              {emailHide(props["email"])}
            </span>
          </div>
        </div>
        <div className="col">{props["bar"]}</div>
      </div>
      <hr />
      <div className="row" style={{ fontSize: 12 }}>
        <div className="col">
          <h5>求职意向</h5>
          <span className="text-muted">
            期望行业:&nbsp;
            {props["qiwanghangye"]}/{props["qiwangzhiwei"]}
            <br />
            期望地点:&nbsp;
            {props["yixiangchengshi"]}
          </span>
        </div>
      </div>
      <hr />
      <div className="row" style={{ fontSize: 12 }}>
        <div className="col">
          <h5>毕业院校</h5>
          <span style={{ fontSize: 'larger' }}>{props["school"]}</span>
          <span className="text-muted">
            /{props["education"]}
            <br />
            在校时间: {props["date_begin"]}
            &nbsp; - &nbsp;
            {props["date_end"]}
            <br />
            专业:{props["major"]}
          </span>
        </div>
      </div>
      <hr />
      <div className="row" style={{ fontSize: 12 }}>
        <div className="col">
          <h5>求职状态</h5>
          <div className="mt-1 text-muted">
            求职状态：
            {props["status"]}
          </div>
        </div>
      </div>
      <hr />
      <div className="row" style={{ fontSize: 12 }}>
        <div className="col">
          <h5>证书</h5>
          {JSON.parse(props["certificate"] || "[]").map((item, index) => (
            <div className="row" key={index}>
              <div className="col text-muted">
                <strong style={{ fontSize: 15 }}>
                  证书名称：
                  {JSON.parse(item)?.certificate}
                </strong>
                <div className="mt-1">
                  获得时间：
                  {JSON.parse(item)?.time}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <hr />
      <div className="row" style={{ fontSize: 12 }}>
        <div className="col">
          <h5>专业技能</h5>
          {JSON.parse(props["skill"] || "[]").map((item, index) => (
            <div className="row" key={index.toString()}>
              <div className="col text-muted">
                <strong style={{ fontSize: 15 }}>
                  技能名称：
                  {JSON.parse(item)?.name}
                </strong>
                <div className="mt-1">
                  使用时长：
                  {JSON.parse(item)?.length}/月
                </div>
                <div className="mt-1">
                  掌握程度：
                  {JSON.parse(item)?.level}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <hr />
      <div className="row" style={{ fontSize: 12 }}>
        <div className="col">
          <h5>工作经历</h5>
          {JSON.parse(props["career"] || "[]").map((item, index) => (
            <div className="row" key={index.toString()} style={{ fontSize: 12 }}> 
              <div className="col text-muted">
                <strong style={{ fontSize: 15 }}>
                  公司名称：
                  {JSON.parse(item)?.employer}
                </strong>
                <div className="mt-1">
                  职位名称：
                  {JSON.parse(item)?.title}
                </div>
                <div className="mt-1">
                  在职时间：
                  {JSON.parse(item)?.date_begin}&nbsp;-&nbsp;{JSON.parse(item)?.date_end}
                </div>
                <div className="mt-1">
                  工作描述：
                  {JSON.parse(item)?.description}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <hr />

      <div className="row" style={{ fontSize: 12 }}>
        <div className="col">
          <h5>项目经历</h5>
          {JSON.parse(props["record"] || "[]").map((item, index) => (
            <div className="row" key={index.toString()} style={{ fontSize: 12 }}>
              <div className="col text-muted">
                <strong style={{ fontSize: 15 }}>
                  项目名称：
                  {JSON.parse(item)?.name}
                </strong>
                <div className="mt-1">
                  职位名称：
                  {JSON.parse(item)?.title}
                </div>
                <div className="mt-1">
                  项目时间：
                  {JSON.parse(item)?.date_begin}&nbsp;-&nbsp;{JSON.parse(item)?.date_end}
                </div>
                <div className="mt-1">
                  项目职责：
                  {JSON.parse(item)?.role}
                </div>
                <div className="mt-1">
                  项目描述：
                  {JSON.parse(item)?.description}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <hr />

      <div className="row" style={{ fontSize: 12 }}>
        <div className="col">
          <h5>自我评价</h5>
          <div className="text-muted" id="ziwopingjia" />
        </div>
      </div>
      <hr />
    </>
  );
};

ResumeView.propTypes = {
  common_user_id: PropTypes.number,
  name: PropTypes.string,
  gender: PropTypes.string,
  birthday: PropTypes.string,
  address2: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  bar: PropTypes.element,
  qiwanghangye: PropTypes.string,
  qiwangzhiwei: PropTypes.string,
  yixiangchengshi: PropTypes.string,
  school: PropTypes.string,
  education: PropTypes.string,
  date_begin: PropTypes.string,
  date_end: PropTypes.string,
  ziwopingjia: PropTypes.string,
  major: PropTypes.string,
};

ResumeView.defaultProps = {
  common_user_id: undefined,
  name: undefined,
  gender: undefined,
  birthday: undefined,
  address2: undefined,
  phone: undefined,
  email: undefined,
  bar: undefined,
  qiwanghangye: undefined,
  qiwangzhiwei: undefined,
  yixiangchengshi: undefined,
  school: undefined,
  education: undefined,
  date_begin: undefined,
  date_end: undefined,
  ziwopingjia: undefined,
  major: undefined,
};
