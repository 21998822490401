import dayjs from 'dayjs';

export function saveJournal(data) {
  fetch('/api/miscellaneous/journal', {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({
      ref_id: data.ref_id || 0,
      ref_id2: data.ref_id2 || 0,
      category: data.category || '',
      tag: data.tag || '',
      ref_uuid: data.ref_uuid || '',
      ref_uuid2: data.ref_uuid2 || '',
    }),
  });
}

export const _EditJournal = (body, callback) => {
  const auth = JSON.parse(sessionStorage.getItem('auth'));
  if (auth !== null) {
    fetch('./api/journal/edit/', {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({
        user_id: auth.id,
        user_uuid: auth.uuid,
        datime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        category1: '企业用户',
        ...body,
      }),
    })
      .then((res) => res.json())
      .then(callback);
  }
};

export const FavoriteJournal = (body, callback) => {
  const auth = JSON.parse(sessionStorage.getItem('auth'));
  if (auth !== null) {
    fetch('./api/favorite/', {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({
        user_id: auth.id,
        user_uuid: auth.uuid,
        category1: '企业用户',
        ...body,
      }),
    })
      .then((res) => res.json())
      .then(callback);
  }
};
