import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import { setAuth } from '../authSlice';
import ContentTitlePhone from "../components/ContentTitlePhone";
import AuditionPhone from './AuditionPhone';
import OfferPhone from './OfferPhone';
import SysPhone from './SysPhone';

const Index = () => {
  const reduxDispatch = useDispatch();
  let auth = JSON.parse(window.sessionStorage.getItem('auth'));
  if (auth !== null && auth.id) {
    reduxDispatch(
      setAuth({
        id: auth.id,
        uuid: auth.uuid,
        name: auth.name,
        email: auth.email,
        phone: auth.phone,
        ref_id: auth.ref_id,
        ref_uuid: auth.ref_uuid,
      }),
    );
  } else window.location.href = '#登录';

  useEffect(() => {
    if (auth === null) {
      location.href = '#登录';
    } else if (!auth.phone) {
      alert("您还未填写手机号，请您将手机号填写完整");
      location.href = '#/我的/用户';
    }
  }, []);

  useEffect(() => {
    fetch(`/gin-api/employer/${auth.ref_uuid}/${auth.ref_id}`)
      .then((response) => {
        if (response.status < 400) return response.json();
        else throw new Error("请求数据失败");
      })
      .then((data) => {
        if ('认证' !== data.status) {
          alert('您的企业尚未完成认证,请等待认证成功后在添加岗位!');
          location.href = '#我的/信息/';
        }
      })
      .catch((err) => window.console.error(err));
  }, []);

  return (
    <>
      <div className="container-fluid pb-4">
        <ContentTitlePhone />
        <Router>
          <Switch>
            <Route exact path="/消息/会话/">
              <AuditionPhone />
            </Route>
            <Route exact path="/消息/邀请/">
              <OfferPhone />
            </Route>
            <Route exact path="/消息/系统/">
              <SysPhone />
            </Route>
          </Switch>
        </Router>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default Index;
