import React, { useEffect, useState } from "react";
import { View } from "./Components";

const Favorite = () => {
  const [list, setList] = useState([]);
  const [progress, setProgress] = useState(0);
  const auth = JSON.parse(sessionStorage.getItem("auth"));

  useEffect(() => {
    if (auth === null) return;
    let url = [
      "/api/miscellaneous/favorite",
      "?option=by-ref_id-category-tag",
      `&ref_id=${auth.id}`,
      `&tag=企业用户`,
      `&category=简历`,
    ];
    fetch(url.join(""))
      .then((response) => response.json())
      .then((data) => {
        setList(data);
        setProgress(1);
      });
  }, []);

  useEffect(() => {
    if (progress === 1) {
      let lt = list.map((current) => current.ref_id2);
      setProgress(0);
      fetch(`/gin-api/resume?option=filterBy-ids&ids=${lt.join(",")}`)
        .then((response) => {
          if (response.status < 400) return response.json();
          else throw new Error("请求数据失败");
        })
        .then((data) => {
          let lf = list.map((current) => {
            const r = data.find((element) => element.id === current.ref_id2);
            return {
              ...current,
              resume: r,
            };
          });
          setList(lf);
        })
        .catch((err) => window.console.error(err));
    }
  }, [progress]);

  return (
    <View category="收藏">
      <div className="row mt-3 bg-white shadow signin-right-input">
        <div className="col card signin-right-input">
          <div className="card-body">
            <h4 className="pull-left" style={{ color: "#4a4a4a" }}><strong>我的收藏</strong></h4>
            <table className="table table-hover" style={{ color: "#4a4a4a" }}>
              <thead>
                <tr>
                  <th scope="col">序号</th>
                  <th scope="col">姓名</th>
                  <th scope="col">期望行业</th>
                  <th scope="col">期望职位</th>
                  <th scope="col">期望地点</th>
                  <th scope="col">毕业院校</th>
                  <th scope="col">学历</th>
                  <th scope="col">操作</th>
                </tr>
              </thead>
              <tbody>
                {list.map((item, index) => (
                  <tr key={item.id}>
                    <td>{index + 1}</td>
                    <td>{item["resume"]?.name}</td>
                    <td>{item["resume"]?.qiwanghangye}</td>
                    <td>{item["resume"]?.qiwangzhiwei}</td>
                    <td>{item["resume"]?.yixiangchengshi}</td>
                    <td>{item["resume"]?.school}</td>
                    <td>{item["resume"]?.education}</td>
                    <td>
                      <div className="btn-group btn-group-sm">
                        <a
                          className="btn btn-primary signin-right-input"
                          href={`#简历/收藏/详情/${item.ref_id2}?u_id=${item.ref_uuid2}`}
                        >
                          查看
                        </a>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </View>
  );
};

export default Favorite;
