import React from 'react';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';

// import Title from "../components/Title";
import FooterPhone from '../components/FooterPhone';
import RegisterPhone from './RegisterPhone';
import CertificationPhone from './CertificationPhone';
import JobFairPhone from './JobFairPhone';
import PostPhone from './PostPhone';
import ResumePhone from './ResumePhone';
import MessagePhone from './MessagePhone';
import RecordPhone from './RecordPhone';
import UserPhone from './UserPhone';

const Index = () => {
  return (
    <>
      <div className="container-fluid pb-4">
        <Router>
          <Switch>
            <Route exact path="/操作手册/注册">
              <RegisterPhone />
            </Route>
            <Route exact path="/操作手册/企业认证">
              <CertificationPhone />
            </Route>
            <Route exact path="/操作手册/报名招聘会">
              <JobFairPhone />
            </Route>
            <Route exact path="/操作手册/岗位管理">
              <PostPhone />
            </Route>
            <Route exact path="/操作手册/简历管理">
              <ResumePhone />
            </Route>
            <Route exact path="/操作手册/消息">
              <MessagePhone />
            </Route>
            <Route exact path="/操作手册/操作记录">
              <RecordPhone />
            </Route>
            <Route exact path="/操作手册/我的">
              <UserPhone />
            </Route>
          </Switch>
        </Router>
      </div>
      <FooterPhone />
    </>
  );
};

export default Index;
