import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { View, ResumeView } from "./ComponentsPhone";

const ResumeDetalis = () => {
    const { id, category } = useParams();
    const uuid = new URLSearchParams(useLocation().search).get("u_id");
    const { search } = useLocation();
    const [data, setData] = useState({ id: 0 });
    const [favorite_id, setFavoriteId] = useState(0);
    const auth = JSON.parse(sessionStorage.getItem("auth"));

    const handleFavorite = () => {
        if (favorite_id > 0) {
            fetch(`/api/miscellaneous/favorite/${favorite_id}`, {
                method: "DELETE",
            }).then((response) => {
                if (response.status === 200) setFavoriteId(0);
            });
        } else {
            fetch("/api/miscellaneous/favorite", {
                method: "POST",
                headers: { "content-type": "application/json" },
                body: JSON.stringify({
                    ref_id: auth.id,
                    ref_id2: id,
                    category: "简历",
                    tag: "企业用户",
                    ref_uuid: auth.uuid,
                    ref_uuid2: uuid,
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.insertId > 0) setFavoriteId(data.insertId);
                });
        }
    };

    const Bar = () => {
        return (
            <div className="pull-right">
                <button
                    className="btn btn-light rounded-0 text-muted"
                    style={{ fontSize: 12 }}
                    onClick={handleFavorite}
                    type="button"
                >
                    {favorite_id > 0 ? (
                        <FontAwesomeIcon icon={faStar} style={{ color: "#FFFF00" }} fixedWidth />
                    ) : (
                        <FontAwesomeIcon icon={faStar} fixedWidth />
                    )}
                    收藏
                </button>
            </div>
        );
    };

    useEffect(() => {
        if (auth === null) {
            location = "#登录";
        } else {
            // 简历
            fetch(`/gin-api/resume/${uuid}/${id}`)
                .then((response) => {
                    if (response.status < 400) return response.json();
                    else throw new Error("请求数据失败");
                })
                .then((data) => {
                    setData(data);
                })
                .catch((err) => window.console.error(err));
            let url = [
                "/api/miscellaneous/favorite",
                "?option=by-ref_id-ref_id2-category-tag",
                `&ref_id=${auth.id}`,
                `&ref_id2=${id}`,
                `&category=简历`,
                "&tag=企业用户",
            ];
            fetch(url.join(""))
                .then((response) => response.json())
                .then((data) => {
                    if (data.length > 0) setFavoriteId(data[0].id);
                });
        }
    }, [id, search]);

    return (
        <View category="检索">
            <div className="bg-white shadow signin-right-input-phone">
                <div className="card signin-right-input-phone">
                    <div className="card-body">
                        <ResumeView bar={<Bar />} {...data} />
                        <div className="row">
                            <div className="col">
                                <a href={`#简历/${category}/`} className="btn btn-primary signin-right-input-phone" style={{ fontSize: 12 }}>
                                    返回
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </View>
    );
};

export default ResumeDetalis;
