import React from "react"

export const MainContext = React.createContext(null);

export const reducer = (state, action) => {
    console.log("context", state, action);
    if (action.type === "setAuth") {
        return { ...state, auth: action.value };
    }
    if (action.type === "setEmployer") {
        return { ...state, employer: action.value };
    }
    if (action.type === "setJobList") {
        return { ...state, jobList: action.value };
    }
    return state;
}
