import React, { useContext, useEffect } from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { setAuth } from "../authSlice";
import Navbar from "../components/Navbar";
import ContentTitle from "../components/ContentTitle";
import List from "./List";
import Favorite from "./Favorite";
import ListDetails from "./ListDetails";
import ResumeDetalis from "./ResumeDetalis";
import Retrieval from "./Retrieval";
import Recommend from "./Recommend";
import Invitation from "./Invitation";
import { MainContext } from "../utility/context";

export default function Index() {
  const [context, dispatchContext] = useContext(MainContext);
  const reduxDispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (0 === auth.id) {
      let a = sessionStorage.getItem("auth");
      if (null === a) location.href = "#登录";
      else reduxDispatch(setAuth(JSON.parse(a)));
    } else {
      fetch(`/gin-api/employer/${auth.ref_uuid}/${auth.ref_id}`)
        .then((response) => {
          if (response.status < 400) return response.json();
          else throw new Error("请求数据失败");
        })
        .then((data) => {
          dispatchContext({ type: "setEmployer", value: data });
          if ("认证" !== data.status) {
            alert("您的企业尚未完成认证,请等待认证成功后在查看简历!");
            location.href = "#我的/信息/";
          } else {
            let a1 = JSON.parse(sessionStorage.getItem("auth"));
            if (!a1.phone) {
              alert("您还未填写手机号，请您将手机号填写完整");
              location.href = "#我的/用户/";
            }
          }
        })
        .catch((err) => window.console.error(err));
    }
  }, []);

  return (
    <>
      <div className="container-fluid pb-4">
        <ContentTitle />
        <Navbar category="简历" totalFlg />
        <Router>
          <Switch>
            <Route exact path="/简历/列表/">
              <List />
            </Route>
            <Route exact path="/简历/列表/详情/:id/">
              <ListDetails />
            </Route>
            <Route path="/简历/面试邀请/:id">
              <Invitation />
            </Route>
            <Route exact path="/简历/收藏/">
              <Favorite />
            </Route>
            <Route path="/简历/:category/详情/:id/">
              <ResumeDetalis />
            </Route>
            <Route exact path="/简历/检索/">
              <Retrieval />
            </Route>
            <Route exact path="/简历/推荐/">
              <Recommend />
            </Route>
          </Switch>
        </Router>
      </div>
      {/* <Footer /> */}
    </>
  );
}
