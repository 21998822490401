import dayjs from "dayjs";
import React, { useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { View1 } from "./Components";
import { setAuth } from "../authSlice";
import reducer from "../reducer";

export default function List() {
  const reduxDispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [state, dispatch] = useReducer(reducer, {
    progress: 0,
    list: [],
  });

  // 线上招聘会
  useEffect(() => {
    fetch(
      '/api/simple/bulletin?option=filterBy-tag-detail&tag=["线上招聘会"]&take=30&detail={"status":"启用"}'
    )
      .then((response) => response.json())
      .then((data) => {
        data.reverse();
        dispatch({ type: "set", payload: { key: "list", value: data } });
        dispatch({ type: "set", payload: { key: "progress", value: 1 } });
      });
  }, []);

  useEffect(() => {
    if (0 === auth.id) {
      let a = sessionStorage.getItem("auth");
      if (null === a) location.href = "#登录";
      else reduxDispatch(setAuth(JSON.parse(a)));
    }
    // if (0 === auth.id) location.href = '#/登录';
  }, []);

  useEffect(() => {
    if (1 === state.progress) {
      let lt = state.list.map((current) => `"${current.id}"`);
      const url = [
        "/gin-api/job",
        "?option=filterBy-refId-fairIds",
        `&refId=${auth.ref_id}`,
        `&fairIds=${lt.join(",")}`
      ];
      fetch(url.join(""))
        .then((response) => response.json())
        .then((data) => {
          if (data !== null) {
            let lt = [];
            let show = [];
            let id_list = [];
            data.forEach((current) => {
              let a = JSON.parse(current["fairId"]) === null ? [] : JSON.parse(current["fairId"]);
              a.forEach((fair_index) => {
                id_list.push(fair_index);
              });
            });
            state.list.forEach((current) => {
              if (id_list.indexOf(current.id) === -1) lt.push({ ...current, qty: 0 });
              else lt.push({ ...current, qty: 1 });
            });
            // lt.map((item, index) => {
            //   if (dayjs(item.detail.enroll_deadline).format("YYYY-MM-DD HH:mm:ss") <= dayjs().format("YYYY-MM-DD HH:mm:ss")) {
            //     lt.splice(index, 1);
            //   }
            // })
            dispatch({ type: "set", payload: { key: "list", value: lt } });
            dispatch({ type: "set", payload: { key: "progress", value: 2 } });
          }
        });
    }
  }, [state.progress]);

  return (
    <View1 category="校园招聘会场次">
      <div className="row bg-white shadow signin-right-input" style={{ fontFamily: "Microsoft YaHei" }}>
        <div className="col card signin-right-input">
          <div className="card-body">
            <h4 className="pull-left pb-2" style={{ color: "#4a4a4a" }}><strong>线上招聘会场次</strong></h4>
            <table className="table table-hover" style={{ color: "#4a4a4a" }}>
              <thead>
                <tr>
                  <th scope="col">序号</th>
                  <th scope="col">招聘会名称</th>
                  <th scope="col">预计报名截止时间</th>
                  <th scope="col">操作</th>
                </tr>
              </thead>
              <tbody>
                {state["list"]?.map((item, index) => (
                  <tr id="list" key={item.id}>
                    <td>{index + 1}</td>
                    <td>{item.title}</td>
                    <td>
                      {dayjs(item.expire_at).format("YYYY-MM-DD HH:mm:ss")}
                    </td>
                    <td>
                      <div className="btn-group btn-group-sm">
                        {item.qty ? (
                          <a
                            className="btn btn-info rounded-pill"
                            href={`#招聘会/报名/${item.id}`}
                          >
                            编辑
                          </a>
                        ) : (
                          <a
                            className="btn btn-primary rounded-pill"
                            href={`#招聘会/报名/${item.id}`}
                          >
                            报名
                          </a>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </View1>
  );
}
