import React from 'react';
import { ViewPhone } from './ComponentsPhone';

const UserPhone = () => {
  return (
    <ViewPhone category="我的">
      <div className="card bg-white border-0 shadow signin-right-input-phone" style={{ fontSize: 12 }}>
        <div className="card-body">
          <div className="row">
            <div className="register-font text-primary">
              <h1>01</h1>
            </div>
            <div className="pt-1">
              <strong>点击【我的】按钮后，进入企业信息页面,当企业认证通过后,点击编辑按钮，可修改企业部分信息。</strong>
            </div>
            <div className="register-img-phone pt-1">
              <img className='signin-right-input-phone' src="./lib/img/r-28.png" alt="" />
            </div>
          </div>
          <hr style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
          <div className="row">
            <div className="register-font text-primary">
              <h1>02</h1>
            </div>
            <div className="pt-1">
              <strong>认证后编辑的信息包括注资规模、员工数量、电话号码、企业网址以及公司简介。</strong>
            </div>
          </div>
          <div className="register-img-phone pt-1">
            <img className='signin-right-input-phone' src="./lib/img/r-29.png" alt="" />
          </div>
          <hr style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
          <div className="row">
            <div className="register-font text-primary">
              <h1>03</h1>
            </div>
            <div className="pt-1">
              <strong>
                点击【用户信息】按钮，用户可自行填写或更改自己的联系方式，填写成功后，用户可以将手机号作为账号进行登录操作。
              </strong>
            </div>
          </div>
          <div className="register-img-phone pt-1">
            <img className='signin-right-input-phone' src="./lib/img/r-30.png" alt="" />
          </div>
          <hr style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
          <div className="row">
            <div className="register-font text-primary">
              <h1>04</h1>
            </div>
            <div className="pt-1">
              <strong>
                用户可以点击【意见反馈/投诉】按钮对我们平台提出宝贵的意见或者投诉平台中出现的不规范的问题。
              </strong>
            </div>
          </div>
          <div className="register-img-phone pt-1">
            <img className='signin-right-input-phone' src="./lib/img/r-31.png" alt="" />
          </div>
          <hr style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
          <div className="row">
            <div className="register-font text-primary">
              <h1>05</h1>
            </div>
            <div className="pt-1">
              <strong>
                用户可以点击【修改密码】按钮修改该账号的登录密码，点击【退出登录】按钮，即可退出登录状态。
              </strong>
            </div>
          </div>
          <div className="register-img-phone pt-1">
            <img className='signin-right-input-phone' src="./lib/img/r-32.png" alt="" />
          </div>
        </div>
      </div>
    </ViewPhone>
  );
};

export default UserPhone;
