import React, { useEffect } from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAuth } from "../authSlice";
import Navbar from "../components/Navbar";
import ContentTitle from "../components/ContentTitle";
import List from "./List";
import Save from "./Save";
import Update from "./Update";

export default function Index() {
  const reduxDispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (0 === auth.id) {
      let a = sessionStorage.getItem("auth");
      if (null === a) location.href = "#登录";
      else reduxDispatch(setAuth(JSON.parse(a)));
    } else {
      fetch(`/gin-api/employer/${auth.ref_uuid}/${auth.ref_id}`)
        .then((response) => {
          if (response.status < 400) return response.json();
          else throw new Error("请求数据失败");
        })
        .then((data) => {
          if ("认证" !== data.status) {
            alert("您的企业尚未完成认证,请等待认证成功后在添加岗位!");
            location.href = "#我的/信息/";
          } else {
            let a1 = JSON.parse(sessionStorage.getItem("auth"));
            if (!a1.phone) {
              alert("您还未填写手机号，请您将手机号填写完整");
              location.href = "#我的/用户/";
            }
          }
        })
        .catch((err) => console.error(err));
    }
  }, []);

  return (
    <>
      <div className="flex-shrink-0">
        <div className="container-fluid pb-4">
          <ContentTitle />
          <Navbar category="岗位" totalFlg />
          <Router>
            <Switch>
              <Route exact path="/岗位/列表/">
                <List />
              </Route>
              <Route exact path="/岗位/新增/">
                <Save />
              </Route>
              <Route exact path="/岗位/编辑/:id/">
                <Update />
              </Route>
            </Switch>
          </Router>
        </div>
      </div>
    </>
  );
}
