import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { View } from "./Components";
import { MainContext } from "../utility/context";
import { faPenSquare } from "@fortawesome/free-solid-svg-icons";

export default function Enterprise() {
  const [_, dispatchContext] = useContext(MainContext);
  const auth = JSON.parse(sessionStorage.getItem("auth"));
  const [data, setData] = useState(0);

  useEffect(() => {
    if (auth === null) {
      location.href = "#登录";
    } else {
      if (!auth.phone) {
        window.alert("您还未填写手机号，请您将手机号填写完整");
        location.href = "#我的/用户/";
      }
    }
  }, []);

  useEffect(() => {
    fetch(`/gin-api/employer/${auth.ref_uuid}/${auth.ref_id}`)
      .then((response) => {
        if (response.status < 400) return response.json();
        else throw new Error("请求数据失败");
      })
      .then((data) => {
        setData(data);
        dispatchContext({ type: "setEmployer", value: data });
      })
      .catch((err) => window.console.error(err));
  }, []);

  return (
    <View category="企业信息">
      <div className="row bg-white shadow signin-right-input">
        <div className="col card signin-right-input">
          <div className="card-body">
            <div className="row">
              <div className="col">
                <h4 className="pull-left" style={{ color: "#4a4a4a" }}><strong>企业信息</strong></h4>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="col">
                    <h4 className="pull-left">
                      <strong>
                        {data.name}({data.yingyezhizhao}
                        )
                      </strong>
                      &nbsp;
                      <span
                        className={`badge ${data.status !== "认证"
                          ? "badge-secondary"
                          : "badge-success"
                          } `}
                      >
                        {data.status !== "认证" ? data.status : "已认证"}
                      </span>
                    </h4>
                    {data.status === "待认证" ? (
                      <div className="pull-right">
                        <a href="#我的/信息/编辑/">
                          <FontAwesomeIcon
                            icon={faPenSquare}
                            fixedWidth
                          />
                          编辑
                        </a>
                      </div>
                    ) : (
                      <div className="pull-right">
                        <a href="#我的/信息/认证后编辑/">
                          <FontAwesomeIcon
                            icon={faPenSquare}
                            fixedWidth
                          />
                          编辑
                        </a>
                      </div>
                    )}
                  </div>
                </div>
                <span className="text-muted">
                  {data.faren && (
                    <>
                      法人:
                      {data.faren}
                      &nbsp;&nbsp;&nbsp;
                    </>
                  )}
                  {data.yuangongshuliang && (
                    <>
                      员工数量:
                      {data.yuangongshuliang}
                      &nbsp;&nbsp;&nbsp;
                    </>
                  )}
                  <br />
                  {data.zhuziguimo && (
                    <>
                      注资规模:
                      {data.zhuziguimo}
                      &nbsp;&nbsp;&nbsp;
                    </>
                  )}
                  {data.zhuceriqi && (
                    <>
                      注册日期:
                      {data.zhuceriqi}
                    </>
                  )}
                </span>
                <br />
                <span className="text-muted">
                  公司地址:
                  {data.addressLevel1 && <>{data.addressLevel1}-</>}
                  {data.addressLevel2 && <>{data.addressLevel2}-</>}
                  {data.addressLevel3 && <>{data.addressLevel3}-</>}
                  {data.addressLevel4 && <>{data.addressLevel4}</>}
                </span>
                <br />
                <span className="text-muted">公司网址: {data.url}</span>
                <br />
                <span className="text-muted">公司简介:</span>
                <br />
                <span className="text-muted">{data.intro}</span>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col">
                <h5>营业执照</h5>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <img className="w-50" alt="" src={data.yingyezhizhao_tu} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </View>
  );
}
