import React from 'react';
import { View } from './Components';

// to-do: 恢复bulletin类消息
const Sys = () => {
  const [list, setList] = React.useState([]);
  const [sys, setSys] = React.useState([]);
  const auth = JSON.parse(sessionStorage.getItem('auth'));

  React.useEffect(() => {
    if (auth !== null) {
      // fetch(`/api/message/sys/ent/${_auth.id}`)
      fetch(
        `/api/miscellaneous/message?option=ref_id2-and-tag&ref_id2=${auth.id}&tag=企业用户`,
      )
        .then((response) => response.json())
        .then((data) => {
          setList(data);
        });
    } else {
      location = '#登录';
    }
  }, []);

  React.useEffect(() => {
    // 通知/公告
    fetch('/api/bulletin?option=bulletin-by-tag&tag=企业用户')
      .then((response) => response.json())
      .then((data) => {
        setSys(data);
      });
  }, []);

  React.useEffect(() => {
    // 设置消息状态为已读
    // to-do: 根据列表id-list修改，而不是全部修改
    if (!list) return;
    let timer = setTimeout(() => {
      fetch(
        `/api/miscellaneous/message/${auth.id}?option=status-by-ref_id2-and-tag`,
        {
          method: 'PUT',
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify({
            tag: '企业用户',
            status: '已读',
          }),
        },
      );
    }, 2000);
    return () => clearTimeout(timer);
  }, [list]);

  return (
    <View category="系统">
      <div className="row bg-white shadow">
        <div className="col card rounded-0">
          <div className="card-body">
            <div className="row mb-3">
              <h4 className="col pull-left" style={{ color: "#4a4a4a" }}><strong>系统消息</strong></h4>
            </div>
            {sys &&
              sys.map((item) => (
                <React.Fragment key={item.id}>
                  <div className="row">
                    <div className="col ">
                      <strong>{item.title}</strong>
                      <br />
                      <div className="text-secondary">{item.content}</div>
                    </div>
                  </div>
                  <hr />
                </React.Fragment>
              ))}
          </div>
        </div>
      </div>
    </View>
  );
};

export default Sys;
