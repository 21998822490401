import React, { useEffect } from 'react';
import PropTypes from 'prop-types';


export default function AutoCardsLogin() {

  useEffect(() => {
    let oDiv = document.getElementsByClassName('timeWrap')[0];
    let oUl = document.getElementsByClassName('picture_ul')[0];
    let oLis = oUl.getElementsByTagName('li'); // 获取ul下的所有li
    oUl.innerHTML = oUl.innerHTML + oUl.innerHTML; // 图片衔接
    oUl.style.width = oLis[0].offsetWidth * oLis.length + 'px'; // ul的宽度等于每个li的宽度乘以所有li的长度
    let speed = 2;
    // 主方法
    function move() {
      // 如果左边横向滚动了长度一半之后，回到初始位置
      if (oUl.offsetLeft < -oUl.offsetWidth / speed) {
        oUl.style.left = '0'
      }
      // 如果右边横向滚动的距离大于0 就让他的位置回到一半
      if (oUl.offsetLeft > 0) {
        oUl.style.left = -oUl.offsetWidth / speed + 'px';
      }
      oUl.style.left = oUl.offsetLeft - 2 + 'px'; // 进行左横向滚动
      // oUl.style.left = oUl.offsetLeft + speed + 'px'; // 进行右横向滚动
    }
    // 调用方法
    let timer = setInterval(move, 30);
    // 鼠标指向的时候 暂停
    oDiv.onmouseover = function () {
      // clearTimeout(timer);
      clearInterval(timer);
    }
    // 鼠标离开之后 继续滚动
    oDiv.onmouseout = function () {
      timer = setInterval(move, 30)
    }
  }, [])

  return (
    <div className="layout honor">
      <div className="honor_lunbo">
        <div className="timeWrap">
          <ul className="picture_ul">
            <li><img className="card" src="./lib/img/1.jpg" alt="" /></li>
            <li><img className="card" src="./lib/img/2.jpg" alt="" /></li>
            <li><img className="card" src="./lib/img/3.jpg" alt="" /></li>
            <li><img className="card" src="./lib/img/4.jpg" alt="" /></li>
            <li><img className="card" src="./lib/img/5.jpg" alt="" /></li>
            <li><img className="card" src="./lib/img/6.png" alt="" /></li>
            <li><img className="card" src="./lib/img/7.png" alt="" /></li>
            <li><img className="card" src="./lib/img/8.jpg" alt="" /></li>
            <li><img className="card" src="./lib/img/9.jpg" alt="" /></li>
            <li><img className="card" src="./lib/img/10.png" alt="" /></li>
            <li><img className="card" src="./lib/img/11.jpg" alt="" /></li>
            <li><img className="card" src="./lib/img/12.jpg" alt="" /></li>
            <li><img className="card" src="./lib/img/13.jpg" alt="" /></li>
            <li><img className="card" src="./lib/img/14.jpg" alt="" /></li>
            <li><img className="card" src="./lib/img/15.png" alt="" /></li>
            <li><img className="card" src="./lib/img/16.jpg" alt="" /></li>
            <li><img className="card" src="./lib/img/17.png" alt="" /></li>
            <li><img className="card" src="./lib/img/18.jpg" alt="" /></li>
            <li><img className="card" src="./lib/img/19.png" alt="" /></li>
            <li><img className="card" src="./lib/img/20.jpg" alt="" /></li>
            <li><img className="card" src="./lib/img/21.jpg" alt="" /></li>
            <li><img className="card" src="./lib/img/22.jpg" alt="" /></li>
            <li><img className="card" src="./lib/img/23.jpg" alt="" /></li>
            <li><img className="card" src="./lib/img/24.jpg" alt="" /></li>
          </ul>
        </div>
      </div>
    </div>
  );
}

AutoCardsLogin.propTypes = {
  list: PropTypes.array,
};
