import dayjs from "dayjs";
import React, { useContext, useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import reducer from "../reducer";
import { setResume, setJobList, setNumber } from "../resumeSlice";
import { View } from "./ComponentsPhone";

export default function ListPhone() {
  const rowCount = 10;
  const auth = JSON.parse(sessionStorage.getItem("auth"));
  const [state, dispatch] = useReducer(reducer, {
    PROGRESS: 0,
    JOB_LIST: useSelector((state) => state.resume.job_list),
    PAGE: useSelector((state) => state.resume.number),
    ADDRESS2: [],
    RESUME: useSelector((state) => state.resume.list),
    RESUME_LIST_ORIGIN: [],
    name: "",
    job: "",
    status: "",
    education: "",
    sendinList: [],
    deliveryJobList: [],
    job_address_level1: "",
    job_address_level2: "",
    job_address_level3: "",
  });
  const reduxDispatch = useDispatch();
  const [flag, setFlag] = React.useState(true);

  const filter = () => {
    let filter_rowCount = 500;
    const arr = state["JOB_LIST"].filter((current) => {
      return current.name.indexOf(state["job"]) > -1 &&
        current.addressLevel2.indexOf(state["job_address_level2"]) > -1
    });
    if (arr.length === 0) {
      window.alert("该条件下暂时还没有简历!");
      dispatch({ type: "set", payload: { key: "sendinList", value: [] } });
      setFlag(false);
      return;
    };
    const url = [
      "/gin-api/sendin",
      "?option=filterBy-ref1Ids-status-timeRange",
      `&ref1Ids=${arr.map((current) => current["id"]).join(",")}`,
      `&status=${state["status"]}`,
      `&timeBegin=${dayjs().subtract(2, "year").format("YYYY-MM-DD")}`,
      `&timeEnd=${dayjs().add(1, "day").format("YYYY-MM-DD")}`,
      `&take=${filter_rowCount}`,
      `&skip=${(state["PAGE"] - 1) * filter_rowCount}`,

    ]
    fetch(url.join(""))
      .then((response) => {
        if (response.status < 400) return response.json();
        else throw new Error("请求数据失败");
      })
      .then((data) => {
        if (data.length === 0) {
          window.alert("该条件下暂时还没有简历!");
          dispatch({ type: "set", payload: { key: "sendinList", value: [] } });
          setFlag(false);
        } else {
          const genResumeIds = (sa) => Array.from(new Set(sa)).sort((a, v) => a - v);
          const url = [
            "/gin-api/resume",
            "?option=filterBy-education-ids-name",
            `&education=${state["education"]}`,
            `&name=${state["name"]}`,
            `&take=${filter_rowCount}`,
            `&skip=${(state["PAGE"] - 1) * filter_rowCount}`,
            `&ids=${genResumeIds(data.map((s) => s["resumeId"])).join(",")}`
          ]
          fetch(url.join(""))
            .then((response) => {
              if (response.status < 400) return response.json();
              else throw new Error("请求数据失败");
            })
            .then((res) => {
              if (res !== null) {
                const lt = data.map((sendin) => {
                  const resume = res.find((r) => r["id"].toString() === sendin["resumeId"]);
                  const job = state["JOB_LIST"].find((j) => j["id"].toString() === sendin["jobId"]);
                  return Object.assign(sendin, { resume, job });

                });
                const lt1 = lt.filter(item => item.resume !== undefined);
                dispatch({ type: "set", payload: { key: "sendinList", value: lt1 } });
                reduxDispatch(setResume({ list: lt1 }));
                setFlag(false);
                window.alert("已为您展示所有符合条件的简历!");
              } else {
                window.alert("该条件下暂时还没有简历!");
                dispatch({ type: "set", payload: { key: "sendinList", value: [] } });
                setFlag(false);
              }
            })
            .catch((err) => window.console.error(err));
        }
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    const url = [
      "/gin-api/job",
      "?option=filterBy-refId-status",
      `&refId=${auth.ref_id}`,
      "&status="
    ];
    fetch(url.join(""))
      .then((response) => {
        if (response.status < 400) return response.json();
        else throw new Error("请求数据失败");
      })
      .then((data) => {
        if (data !== null) {
          dispatch({ type: "set", payload: { key: "JOB_LIST", value: data } });
          reduxDispatch(setJobList({ job_list: data }));
          dispatch({
            type: "set",
            payload: { key: "PROGRESS", value: state.PROGRESS + 1 },
          });
        }
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    // 投递记录
    if (1 === state.PROGRESS) {
      let arr = [];
      let arr1 = [];
      state["JOB_LIST"].forEach((item) => {
        arr.push(item["addressLevel2"]);
      });
      for (let i = 0; i < arr.length; i++) {
        if (arr1.indexOf(arr[i]) === -1) {
          arr1.push(arr[i]);
        }
      }
      dispatch({ type: "set", payload: { key: "ADDRESS2", value: arr1 } });
      // const url = [
      //   "/gin-api/sendin",
      //   "?option=filterBy-ref2Ids-timeRange",
      //   `&ref2Ids=${state["JOB_LIST"].map((current) => current["id"]).join(",")}`,
      //   `&timeBegin=${dayjs().subtract(2, "year").format("YYYY-MM-DD")}`,
      //   `&timeEnd=${dayjs().add(1, "day").format("YYYY-MM-DD")}`,
      // ];
      const url = [
        "/gin-api/sendin",
        "?option=filterBy-ref1Ids-status-timeRange",
        `&ref1Ids=${state["JOB_LIST"].map((current) => current["id"]).join(",")}`,
        `&status=`,
        `&timeBegin=${dayjs().subtract(2, "year").format("YYYY-MM-DD")}`,
        `&timeEnd=${dayjs().add(1, "day").format("YYYY-MM-DD")}`,
        `&take=${rowCount}`,
        `&skip=${(state["PAGE"] - 1) * rowCount}`,
      ]
      fetch(url.join(""))
        .then((response) => {
          if (response.status < 400) return response.json();
          else throw new Error("请求数据失败");
        })
        .then((data) => {
          if (state["PAGE"] !== 1 && data.length < rowCount) {
            setFlag(false);
            const lt = data.map((sendin) => {
              const job = state["JOB_LIST"].find((j) => j["id"] === sendin["jobId"]);
              return Object.assign(sendin, { job });
            });
            dispatch({ type: "set", payload: { key: "deliveryJobList", value: lt } });
            dispatch({
              type: "set",
              payload: { key: "PROGRESS", value: state.PROGRESS + 1 },
            });
          } else if (state["PAGE"] === 1 && data.length < rowCount) {
            setFlag(false);
            const lt = data.map((sendin) => {
              const job = state["JOB_LIST"].find((j) => j["id"] === sendin["jobId"]);
              return Object.assign(sendin, { job });
            });
            dispatch({ type: "set", payload: { key: "deliveryJobList", value: lt } });
            dispatch({
              type: "set",
              payload: { key: "PROGRESS", value: state.PROGRESS + 1 },
            });
          }
          else {
            const lt = data.map((sendin) => {
              const job = state["JOB_LIST"].find((j) => j["id"] === sendin["jobId"]);
              return Object.assign(sendin, { job });
            });
            dispatch({ type: "set", payload: { key: "deliveryJobList", value: lt } });
            dispatch({
              type: "set",
              payload: { key: "PROGRESS", value: state.PROGRESS + 1 },
            });
            setFlag(true);
          }
        })
        .catch((err) => console.error(err));
      // else {
      //   dispatch({ type: "set", payload: { key: "sendinList", value: state.RESUME } });
      // }
    }
    if (state["PROGRESS"] === 2) {
      const genResumeIds = (sa) => Array.from(new Set(sa)).sort((a, v) => a - v);
      const url = [
        "/gin-api/resume",
        "?option=filterBy-ids",
        `&ids=${genResumeIds(state["deliveryJobList"]?.map((s) => s["resumeId"])).join(",")}`
      ];
      fetch(url.join(""))
        .then((response) => {
          if (response.status < 400) return response.json();
          else throw new Error("请求数据失败");
        })
        .then((data) => {
          const lt = state["deliveryJobList"].map((sendin) => {
            const resume = data.find((r) => r["id"].toString() === sendin["resumeId"]);
            return Object.assign(sendin, { resume });
          });
          dispatch({ type: "set", payload: { key: "sendinList", value: lt } });
          reduxDispatch(setResume({ list: lt }));
          dispatch({
            type: "set",
            payload: { key: "RESUME_LIST_ORIGIN", value: lt },
          });
        })
        .catch((err) => window.console.error(err));
    }
  }, [state["PROGRESS"], state["PAGE"]]);

  const prev = () => {
    dispatch({ type: "set", payload: { key: "PAGE", value: state["PAGE"] - 1 } });
    reduxDispatch(setNumber({ number: state["PAGE"] - 1 }));
    dispatch({
      type: "set", payload: { key: "PROGRESS", value: 1 },
    });
  }
  const next = () => {
    dispatch({ type: "set", payload: { key: "PAGE", value: state["PAGE"] + 1 } });
    reduxDispatch(setNumber({ number: state.PAGE + 1 }));
    dispatch({
      type: "set", payload: { key: "PROGRESS", value: 1 },
    });
  }

  const refresh = () => {
    window.location.reload();
  }

  return (
    <View category="列表">
      <div className="px-1 pt-2 mt-2 bg-white shadow signin-right-input-phone" style={{ color: "#4a4a4a", fontSize: 10 }}>
        <div className="row">
          <div className="col mb-3">
            <label className="form-label">姓名</label>
            <input
              type="text"
              value={state.name}
              className="form-control signin-right-input-phone"
              style={{ fontSize: 10 }}
              onChange={(event) =>
                dispatch({
                  type: "set",
                  payload: { key: "name", value: event.target.value },
                })
              }
            />
          </div>
          <div className="col mb-3">
            <label className="form-label">职位</label>
            <select
              value={state.job}
              className="form-control signin-right-input-phone"
              style={{ fontSize: 10 }}
              onChange={(event) =>
                dispatch({
                  type: "set",
                  payload: { key: "job", value: event.target.value },
                })
              }
            >
              <option value="">不限</option>
              {state.JOB_LIST.map((current, i) => (
                <option key={i}>{current.name}</option>
              ))}
            </select>
          </div>
          <div className="col mb-3">
            <label className="form-label">职位地址</label>
            <select
              value={state.job_address_level2}
              className="form-control signin-right-input-phone"
              style={{ fontSize: 10 }}
              onChange={(event) =>
                dispatch({
                  type: "set",
                  payload: {
                    key: "job_address_level2",
                    value: event.target.value,
                  },
                })
              }
            >
              <option value="">不限</option>
              {state.ADDRESS2.map((current, i) => (
                <option key={i}>{current}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="row" style={{ fontSize: 10 }}>
          <div className="col mb-3">
            <label className="form-label">状态</label>
            <select
              value={state.status}
              className="form-control signin-right-input-phone"
              style={{ fontSize: 10 }}
              onChange={(event) =>
                dispatch({
                  type: "set",
                  payload: { key: "status", value: event.target.value },
                })
              }
            >
              <option value="">不限</option>
              <option value="已查看">已查看</option>
              <option value="已投递">已投递</option>
              <option value="已邀请">已邀请</option>
            </select>
          </div>
          <div className="col mb-3">
            <label className="form-label">学历</label>
            <select
              value={state.education}
              className="form-control signin-right-input-phone"
              style={{ fontSize: 10 }}
              onChange={(event) =>
                dispatch({
                  type: "set",
                  payload: { key: "education", value: event.target.value },
                })
              }
            >
              <option value="">不限</option>
              <option>高中及以下</option>
              <option>大专</option>
              <option>本科</option>
              <option>硕士</option>
              <option>博士</option>
            </select>
          </div>
          <div className="col-auto mb-3">
            <button
              type="button"
              className="btn btn-primary signin-right-input-phone"
              onClick={filter}
              style={{ marginTop: "2rem", fontSize: 10 }}
            >
              查询
            </button>
            &nbsp;&nbsp;
            <button
              type="button"
              className="btn btn-primary signin-right-input-phone"
              onClick={refresh}
              style={{ marginTop: "2rem", fontSize: 10 }}
            >
              刷新
            </button>
          </div>
        </div>
      </div>
      <div className="mt-3 bg-white shadow card signin-right-input-phone" style={{ color: "#4a4a4a" }}>
        <div className="signin-right-input-phone" style={{ padding: 0 }}>
          <div className="card-body" style={{ padding: "10px" }}>
            <h6 className="pull-left"><strong>已收到简历</strong></h6>
            <table className="table table-hover" style={{ color: "#4a4a4a" }}>
              <thead style={{ fontSize: 5 }}>
                <tr>
                  <th scope="col" style={{ padding: 8, textAlign: "center" }}>序号</th>
                  <th scope="col" style={{ padding: 8, textAlign: "center" }}>职位名称</th>
                  <th scope="col" style={{ padding: 8, textAlign: "center" }}>职位地址</th>
                  <th scope="col" style={{ padding: 8, textAlign: "center" }}>求职者姓名</th>
                  <th scope="col" style={{ padding: 8, textAlign: "center" }}>毕业院校</th>
                  <th scope="col" style={{ padding: 8, textAlign: "center" }}>学历</th>
                  <th scope="col" style={{ padding: 8, textAlign: "center" }}>投递时间</th>
                  <th scope="col" style={{ padding: 8, textAlign: "center" }}>状态</th>
                  <th scope="col" style={{ padding: 8, textAlign: "center" }}>操作</th>
                </tr>
              </thead>
              <tbody style={{ fontSize: 5 }}>
                {state["sendinList"]?.map((item, i) => (
                  <tr key={item.id}>
                    <td>{i + 1}</td>
                    <td style={{ padding: 8, textAlign: "center" }}>{item["job"]?.name}</td>
                    <td style={{ padding: 8, textAlign: "center" }}>{item["job"]?.addressLevel2}</td>
                    <td style={{ padding: 8, textAlign: "center" }}>{item["resume"]?.name}</td>
                    <td style={{ padding: 8, textAlign: "center" }}>{item["resume"]?.school}</td>
                    <td style={{ padding: 8, textAlign: "center" }}>{item["resume"]?.education}</td>
                    <td style={{ padding: 8, textAlign: "center" }}>{item["publishTime"]}</td>
                    <td style={{ padding: 8, textAlign: "center" }} className={item["status"] === "已投递" ? "text-danger" : "text-success"}>{item["status"]}</td>
                    <td style={{ padding: 8, textAlign: "center" }}>
                      <div className="btn-group btn-group-sm">
                        <a
                          className="btn btn-primary signin-right-input-phone"
                          style={{ fontSize: 8 }}
                          href={`#简历/列表/详情/${item["id"]}?u_id=${item["resumeUuid"] || ""}`}
                        >
                          查看
                        </a>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="col text-right">
            {state.PAGE === 1 ? (
              <button
                disabled="disabled"
                className="btn btn-outline-primary"
                style={{ fontSize: 8 }}
                onClick={prev}
                type="button"
              >
                上一页
              </button>
            ) : (
              <button className="btn btn-outline-primary" onClick={prev} type="button" style={{ fontSize: 8 }}>
                上一页
              </button>
            )}
          </div>
          <div className="col-1 text-center p-1" style={{ fontSize: 8 }}>第{state.PAGE}页</div>
          <div className="col">
            {flag ? (
              <button
                className="btn btn-outline-primary"
                style={{ fontSize: 8 }}
                type="button"
                onClick={next}
              >
                下一页
              </button>
            ) : (
              <button
                disabled="disabled"
                className="btn btn-outline-primary"
                style={{ fontSize: 8 }}
                type="button"
                onClick={next}
              >
                下一页
              </button>
            )}
          </div>
        </div>
      </div>
    </View>
  );
}
