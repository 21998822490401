import React from 'react';
import { View } from './Components';

const Message = () => {
  return (
    <View category="消息">
      <div className="card bg-white border-0 shadow signin-right-input">
        <div className="card-body">
          <div className="row">
            <div className="col-1 register-font text-primary">
              <h1>01</h1>
            </div>
            <div className="col-2 pt-3">
              <strong>点击【消息】按钮,即可进入【我的会话页面】查看求职者对企业咨询的问题。（注:为保护求职者权益,只有求职者主动沟通后企业才可与求职者用户进行沟通）</strong>
            </div>
            <div className="col-9 register-img">
              <img className='signin-right-input' src="./lib/img/r-23.png" alt="" />
            </div>
          </div>
          <hr style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
          <div className="row">
            <div className="col register-font text-primary">
              <h1>02</h1>
            </div>
            <div className="col-2 pt-3">
              <strong>点击左侧【面试邀请】按钮,即可查看到邀请的求职者是否收到邀请。</strong>
            </div>
            <div className="col-9 register-img">
              <img className='signin-right-input' src="./lib/img/r-24.png" alt="" />
            </div>
          </div>
          <hr style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
          <div className="row">
            <div className="col register-font text-primary">
              <h1>03</h1>
            </div>
            <div className="col-2 pt-3">
              <strong>
                当平台中发布系统消息时，用户可点击【平台消息】按钮进行查看。
              </strong>
            </div>
            <div className="col-9 register-img">
              <img className='signin-right-input' src="./lib/img/r-25.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </View>
  );
};

export default Message;
