import React from 'react';

import { ViewPhone } from './ComponentsPhone';

const RegisterPhone = () => {
  return (
    <ViewPhone category="注册">
      <div className="card bg-white border-0 shadow signin-right-input" style={{ fontSize: 12 }}>
        <div className="card-body">
          <div className="row">
            <div className="register-font text-primary">
              <h1>01</h1>
            </div>
            <div className="pt-1">
              <strong>点击上方企业注册按钮即可进入注册界面</strong>
            </div>
          </div>
          <div className="register-img-phone pt-1">
            <img className='signin-right-input-phone' src="./lib/img/r-1.png" alt="" />
          </div>
          <hr style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
          <div className="row">
            <div className="register-font text-primary">
              <h1>02</h1>
            </div>
            <div className="pt-1">
              <strong>进入注册界面后填写基本信息后即可完成注册</strong>
            </div>
          </div>
          <div className="register-img-phone pt-1">
            <img className='signin-right-input-phone' src="./lib/img/r-2.png" alt="" />
          </div>
          <hr style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
          <div className="row">
            <div className="register-font text-primary">
              <h1>03</h1>
            </div>
            <div className="pt-1">
              <strong>
                用户注册后即可输入电子邮箱和密码进行登录,登录后进入企业端首页
              </strong>
            </div>
          </div>
          <div className="register-img-phone pt-1">
            <img className='signin-right-input-phone' src="./lib/img/r-3.png" alt="" />
          </div>
        </div>
      </div>
    </ViewPhone>
  );
};

export default RegisterPhone;
