import React, { useReducer } from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { setAuth } from "./authSlice";
import HomeRouter from "./home/Index";
import JobRouter from "./job/Index";
import JobPhoneRouter from "./jobPhone/Index";
import RecruitmentRouter from "./recruitment/Index";
import RecruitmentPhoneRouter from "./recruitmentPhone/Index";
import ResumeRouter from "./resume/Index";
import ResumePhoneRouter from "./resumePhone/Index";
import MessageRouter from "./message/Index";
import MessagePhoneRouter from "./messagePhone/Index"
import EnterpriseRouter from "./enterprise/Index";
import EnterprisePhoneRouter from "./enterprisePhone/Index";
import JournalRouter from "./journal/Index";
import JournalPhoneRouter from "./journalPhone/Index";
import OperationRouter from "./operation/Index";
import OperationPhoneRouter from "./operationphone/Index";
import Login from "./Login";
import LoginPhone from "./LoginPhone";
import SignIn from "./SignIn";
import Recover from "./Recover";
import SiginPhone from "./SignInPhone";
import RecoverPhone from "./RecoverPhone";
import HomephoneRouter from "./homephone/Index";
import { MainContext, reducer } from "./utility/context";
import MediaQuery from 'react-responsive';

export default function App() {
  const [context, dispatchContext] = useReducer(reducer, {
    auth: null,
    employer: null,
    jobList: null,
  })
  const dispatch = useDispatch();
  let auth = sessionStorage.getItem("auth");
  if (auth) {
    auth = JSON.parse(auth);
    dispatch(
      setAuth({
        id: auth.id,
        uuid: auth.uuid,
        name: auth.name,
        email: auth.email,
        phone: auth.phone,
        ref_id: auth.ref_id,
        ref_uuid: auth.ref_uuid,
      })
    );
  }

  return (
    <MainContext.Provider value={[context, dispatchContext]}>
      <Router>
        <Switch>
          <Route exact path="/">
            <MediaQuery minWidth={1024}>
              <HomeRouter />
            </MediaQuery>
            <MediaQuery minWidth={768} maxWidth={1023}>
              <HomephoneRouter />
            </MediaQuery>
            <MediaQuery maxWidth={767}>
              <HomephoneRouter />
            </MediaQuery>
          </Route>
          <Route path="/首页">
            <MediaQuery minWidth={1024}>
              <HomeRouter />
            </MediaQuery>
            <MediaQuery minWidth={768} maxWidth={1023}>
              <HomephoneRouter />
            </MediaQuery>
            <MediaQuery maxWidth={767}>
              <HomephoneRouter />
            </MediaQuery>
          </Route>
          <Route exact path="/登录">
            <MediaQuery minWidth={1024}>
              <Login />
            </MediaQuery>
            <MediaQuery minWidth={768} maxWidth={1023}>
              <Login />
            </MediaQuery>
            <MediaQuery maxWidth={767}>
              <LoginPhone />
            </MediaQuery>
          </Route>
          <Route exact path="/注册">
            <MediaQuery minWidth={1024}>
              <SignIn />
            </MediaQuery>
            <MediaQuery minWidth={768} maxWidth={1023}>
              <SiginPhone />
            </MediaQuery>
            <MediaQuery maxWidth={767}>
              <SiginPhone />
            </MediaQuery>
          </Route>
          <Route exact path="/忘记密码">
            <MediaQuery minWidth={1024}>
              <Recover />
            </MediaQuery>
            <MediaQuery minWidth={768} maxWidth={1023}>
              <RecoverPhone />
            </MediaQuery>
            <MediaQuery maxWidth={767}>
              <RecoverPhone />
            </MediaQuery>
          </Route>
          <Route path="/招聘会/">
            <MediaQuery minWidth={1024}>
              <JobRouter />
            </MediaQuery>
            <MediaQuery minWidth={768} maxWidth={1023}>
              <JobPhoneRouter />
            </MediaQuery>
            <MediaQuery maxWidth={767}>
              <JobPhoneRouter />
            </MediaQuery>
          </Route>
          <Route path="/岗位/">
            <MediaQuery minWidth={1024}>
              <RecruitmentRouter />
            </MediaQuery>
            <MediaQuery minWidth={768} maxWidth={1023}>
              <RecruitmentPhoneRouter />
            </MediaQuery>
            <MediaQuery maxWidth={767}>
              <RecruitmentPhoneRouter />
            </MediaQuery>
          </Route>
          <Route path="/简历/">
            <MediaQuery minWidth={1024}>
              <ResumeRouter />
            </MediaQuery>
            <MediaQuery minWidth={768} maxWidth={1023}>
              <ResumePhoneRouter />
            </MediaQuery>
            <MediaQuery maxWidth={767}>
              <ResumePhoneRouter />
            </MediaQuery>
          </Route>
          <Route path="/消息/">
            <MediaQuery minWidth={1024}>
              <MessageRouter />
            </MediaQuery>
            <MediaQuery minWidth={768} maxWidth={1023}>
              <MessagePhoneRouter />
            </MediaQuery>
            <MediaQuery maxWidth={767}>
              <MessagePhoneRouter />
            </MediaQuery>
          </Route>
          <Route path="/操作记录/">
            <MediaQuery minWidth={1024}>
              <JournalRouter />
            </MediaQuery>
            <MediaQuery minWidth={768} maxWidth={1023}>
              <JournalPhoneRouter />
            </MediaQuery>
            <MediaQuery maxWidth={767}>
              <JournalPhoneRouter />
            </MediaQuery>
          </Route>
          <Route path="/我的/">
            <MediaQuery minWidth={1024}>
              <EnterpriseRouter />
            </MediaQuery>
            <MediaQuery minWidth={768} maxWidth={1023}>
              <EnterprisePhoneRouter />
            </MediaQuery>
            <MediaQuery maxWidth={767}>
              <EnterprisePhoneRouter />
            </MediaQuery>
          </Route>
          <Route path="/操作手册/">
            <MediaQuery minWidth={1024}>
              <OperationRouter />
            </MediaQuery>
            <MediaQuery minWidth={768} maxWidth={1023}>
              <OperationPhoneRouter />
            </MediaQuery>
            <MediaQuery maxWidth={767}>
              <OperationPhoneRouter />
            </MediaQuery>
          </Route>
        </Switch>
      </Router>
    </MainContext.Provider>
  );
}
