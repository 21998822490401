import { configureStore } from '@reduxjs/toolkit';

import authReducer from './authSlice';
import bannerReducer from './bannerSlice';
import chartReducer from './chartSlice';
import topicReducer from './topicSlice';
import resumeReducer from './resumeSlice';
import retrievalReducer from './retrievalSlice';
import pageReducer from './pageSlice';

export default configureStore({
  reducer: {
    auth: authReducer,
    banner: bannerReducer,
    chart: chartReducer,
    topic: topicReducer,
    resume: resumeReducer,
    retrieval: retrievalReducer,
    page: pageReducer,
  },
});
