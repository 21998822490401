import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";
import jsPDF from 'jspdf';
import dayjs from "dayjs";

export const ResumeTemplate = (props) => {
    const { modalShow, resume_name } = props;
    const { id } = useParams();
    const pdf = new jsPDF("p", "mm", "a4");
    const closeShare = () => {
        props.closeShare();
    };
    useEffect(() => {
        const canvas = document.getElementById('myCanvas');
        const ctx = canvas.getContext('2d');
        const image = document.getElementById('source');
        image.addEventListener('load', (e) => {
            ctx.drawImage(image, 0, 0, 596, 842);
            createUser(ctx);
        });
    }, []);

    const age = (birthday1) => {
        if (birthday1 && birthday1 !== "") {
            return `${parseInt(dayjs().format("YYYY"), 10) - parseInt(birthday1.slice(0, 4), 10)
                }岁`;
        } else {
            return "0岁";
        }
    };

    const wrapText = (context, text, x, y, maxWidth, lineHeight) => {
        if (text.length > 135) {
            let text1 = text.substring(0, 135) + "..."
            let words = text1.split('');
            let line = '';
            for (let n = 0; n < words.length; n++) {
                let testLine = line + words[n] + ' ';
                let metrics = context.measureText(testLine);
                let testWidth = metrics.width;
                if (testWidth > maxWidth && n > 0) {
                    context.fillText(line, x, y);
                    line = words[n];
                    y += lineHeight;
                } else {
                    line = testLine;
                }
            }
            context.fillText(line, x, y);
        } else {
            let words = text.split('');
            let line = '';
            for (let n = 0; n < words.length; n++) {
                let testLine = line + words[n] + ' ';
                let metrics = context.measureText(testLine);
                let testWidth = metrics.width;
                if (testWidth > maxWidth && n > 0) {
                    context.fillText(line, x, y);
                    line = words[n];
                    y += lineHeight;
                } else {
                    line = testLine;
                }
            }
            context.fillText(line, x, y);
        }
    }

    const wrapText1 = (context, text, x, y, maxWidth, lineHeight) => {
        if (text.length > 300) {
            let text1 = text.substring(0, 300) + "..."
            let words = text1.split('');
            let line = '';
            for (let n = 0; n < words.length; n++) {
                let testLine = line + words[n] + ' ';
                let metrics = context.measureText(testLine);
                let testWidth = metrics.width;
                if (testWidth > maxWidth && n > 0) {
                    context.fillText(line, x, y);
                    line = words[n];
                    y += lineHeight;
                } else {
                    line = testLine;
                }
            }
            context.fillText(line, x, y);
        } else {
            let words = text.split('');
            let line = '';
            for (let n = 0; n < words.length; n++) {
                let testLine = line + words[n] + ' ';
                let metrics = context.measureText(testLine);
                let testWidth = metrics.width;
                if (testWidth > maxWidth && n > 0) {
                    context.fillText(line, x, y);
                    line = words[n];
                    y += lineHeight;
                } else {
                    line = testLine;
                }
            }
            context.fillText(line, x, y);
        }
    }

    const createUser = (ctx) => {
        fetch(`/api/simple/biz/sendin/${id}`)
            .then((response) => {
                if (response.status < 400) return response.json();
                else throw new Error("请求数据失败");
            })
            .then((data) => {
                if (data.id > 0) {
                    fetch(`/api/simple/biz/resume/${data.resumeId}/${data.resumeUuid}`)
                        .then((response) => {
                            if (response.status < 400) return response.json();
                            else throw new Error("请求数据失败");
                        })
                        .then((data1) => {
                            console.log(data1);
                            ctx.font = "11px Arial";
                            ctx.fillStyle = "white";
                            ctx.fillText(`姓名：${data1.name}`, 25, 90);
                            ctx.fillText(`性别：${data1.gender}`, 25, 114);
                            ctx.fillText(`年龄：${age(data1.birthday)}`, 25, 138);
                            ctx.fillText(`现居住地：${data1.addressLevel2}`, 25, 162, 130);
                            ctx.fillText(`手机号：${data1.phone}`, 25, 186);
                            ctx.fillText(`邮箱号：${data1.email.substring(0, 15)}`, 25, 210);
                            ctx.fillText(`${data1.email.substring(15)}`, 25, 222);
                            ctx.fillText(`学校名称：${data1.school.substring(0, 7)}`, 25, 265);
                            ctx.fillText(`${data1.school.substring(7)}`, 25, 280);
                            ctx.fillText(`学历：${data1.education}`, 25, 295);
                            ctx.fillText(`入学时间：${data1.dateBegin}`, 25, 315);
                            ctx.fillText(`毕业时间：${data1.dateEnd}`, 25, 335);
                            ctx.fillText(`专业：${data1.major.substring(0, 9)}`, 25, 355);
                            ctx.fillText(`${data1.major.substring(9)}`, 25, 370);
                            ctx.fillText(`期望行业：${data1.qiwanghangye.substring(0, 8)}`, 25, 420);
                            ctx.fillText(`${data1.qiwanghangye.substring(8)}`, 25, 435);
                            ctx.fillText(`期望行业：${data1.yixiangchengshi}`, 25, 455);
                            ctx.fillText(`求职状态：${data1.status.substring(0, 7)}`, 25, 520);
                            ctx.fillText(`${data1.status.substring(7)}`, 25, 535);
                            ctx.font = "9px Arial";
                            ctx.fillStyle = "black";
                            wrapText1(ctx, `${data1.ziwopingjia.replace(/<p[^>]*>|<\/p>/gi, '')}`, 200, 720, 400, 15)
                            if (data1.career !== undefined) {
                                const arr = [];
                                let career_y = 842 * 28.5 / 100;
                                const career = JSON.parse(data1.career);
                                career.forEach((item) => {
                                    arr.push(JSON.parse(item));
                                });
                                arr.map((item, index) => {
                                    if (index === 0) {
                                        ctx.font = "9px Arial";
                                        ctx.fillStyle = "black";
                                        ctx.fillText(`公司名称：${item.employer}`, 200, career_y);
                                        ctx.fillText(`职位名称：${item.title}`, 200, career_y + 13);
                                        ctx.fillText(`在职时间：${item.date_begin}--${item.date_end}`, 200, career_y + 26);
                                        wrapText(ctx, `工作描述：${item.description}`, 200, career_y + 39, 400, 13);
                                    } else if (index > 0 && index < 2) {
                                        ctx.font = "9px Arial";
                                        ctx.fillStyle = "black";
                                        ctx.fillText(`公司名称：${item.employer}`, 200, career_y + 100 * index);
                                        ctx.fillText(`职位名称：${item.title}`, 200, career_y + 100 * index + 13);
                                        ctx.fillText(`在职时间：${item.date_begin}--${item.date_end}`, 200, career_y + 100 * index + 26);
                                        wrapText(ctx, `工作描述：${item.description}`, 200, career_y + 100 * index + 39, 400, 13);
                                        // ctx.fillText(`工作描述：${item.description.substring(0, 38)}`, 200, career_y + 75 * index + 39);
                                        // ctx.fillText(`${item.description.substring(38, 76)}`, 200, career_y + 75 * index + 52);
                                        // ctx.fillText(`${item.description.substring(76)}`, 200, career_y + 75 * index + 65);
                                    }
                                })
                            }
                            if (data1.record !== undefined) {
                                const arr1 = [];
                                let record_y = 842 * 57 / 100
                                const record = JSON.parse(data1.record);
                                record.forEach((item) => {
                                    arr1.push(JSON.parse(item));
                                });
                                arr1.map((item, index) => {
                                    if (index === 0) {
                                        ctx.font = "9px Arial";
                                        ctx.fillStyle = "black";
                                        ctx.fillText(`项目名称：${item.name}`, 200, record_y);
                                        ctx.fillText(`职位名称：${item.title}`, 200, record_y + 13);
                                        ctx.fillText(`项目时间：${item.date_begin}--${item.date_end}`, 200, record_y + 26);
                                        wrapText(ctx, `项目描述：${item.description}`, 200, record_y + 39, 400, 13)
                                    } else if (index > 0 && index < 2) {
                                        ctx.font = "9px Arial";
                                        ctx.fillStyle = "black";
                                        ctx.fillText(`项目名称：${item.name}`, 200, record_y + 100 * index);
                                        ctx.fillText(`职位名称：${item.title}`, 200, record_y + 100 * index + 13);
                                        ctx.fillText(`项目时间：${item.date_begin}--${item.date_end}`, 200, record_y + 100 * index + 26);
                                        wrapText(ctx, `项目描述：${item.description}`, 200, record_y + 100 * index + 39, 400, 13)
                                    }
                                })
                            }
                            if (data1.certificate !== undefined) {
                                const certificate_arr = [];
                                const certificate = JSON.parse(data1.certificate);
                                certificate.forEach((item) => {
                                    certificate_arr.push(JSON.parse(item));
                                });
                                let y = 842 * 72 / 100
                                certificate_arr.map((item, index) => {
                                    if (index === 0) {
                                        ctx.font = "11px Arial";
                                        ctx.fillStyle = "white";
                                        ctx.fillText(`证书名称：${item.certificate.substring(0, 7)}`, 25, y);
                                        ctx.fillText(`${item.certificate.substring(7)}`, 25, y + 15);
                                        ctx.fillText(`获得时间：${item.time}`, 25, y + 30);
                                    } else if (index > 0) {
                                        ctx.font = "11px Arial";
                                        ctx.fillStyle = "white";
                                        ctx.fillText(`证书名称：${item.certificate.substring(0, 7)}`, 25, y + 60 * index);
                                        ctx.fillText(`${item.certificate.substring(7)}`, 25, y + 60 * index + 15);
                                        ctx.fillText(`获得时间：${item.time}`, 25, y + 60 * index + 30);
                                    }
                                })
                            }
                            if (data1.skill !== undefined && data1.skill) {
                                const skill_arr = [];
                                const skill = JSON.parse(data1.skill);
                                skill.forEach((item) => {
                                    skill_arr.push(JSON.parse(item));
                                });
                                skill_arr.map((item, index) => {
                                    if (index === 0) {
                                        ctx.font = "11px Arial";
                                        ctx.fillStyle = "black";
                                        ctx.fillText(`技能名称：${item.name}`, 200, 90);
                                        ctx.fillText(`使用时长：${item.length}个月`, 340, 90);
                                        ctx.fillText(`掌握程度：${item.level}`, 480, 90);
                                    } else if (index > 0 && index < 5) {
                                        ctx.font = "11px Arial";
                                        ctx.fillStyle = "black";
                                        ctx.fillText(`技能名称：${item.name}`, 200, 90 + 24 * index);
                                        ctx.fillText(`使用时长：${item.length}个月`, 340, 90 + 24 * index);
                                        ctx.fillText(`掌握程度：${item.level}`, 480, 90 + 24 * index);
                                    }
                                })
                            }
                        })
                        .catch((err) => window.console.error(err));
                }
            })
            .catch((err) => window.console.error(err));
    };

    const Save = () => {
        const canvas = document.getElementById('myCanvas');
        const imgData = canvas.toDataURL('image/png');
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save(`${resume_name}.pdf`);
        // let name = `${resume_name}.png`;
        // var a = document.createElement("a");
        // a.href = canvas.toDataURL();
        // a.download = name;
        // a.click();
        // window.alert("图片已经下载到您的电脑中,请注意查收！");
    };

    return (
        <div>
            {/* <div className={`bg-white shadow rounded-lg poster ${modalShow === true ? "canvas-block" : "canvas-none"}`}> */}
            <div className={`bg-white shadow rounded-lg resume-template ${modalShow === true ? "canvas-block" : "canvas-none"}`}>
                <div className='container1'>
                    <canvas id="myCanvas" width="596" height="842"></canvas>
                    <div className='saveImg'>
                        <button type="button" className="btn btn-success" onClick={Save}>下载文件</button>
                        &nbsp;&nbsp;
                        <button type="button" className="btn btn-danger" onClick={closeShare}>关闭</button>
                    </div>
                </div>
            </div>
            <div style={{ display: "none" }}>
                <img id="source" src="./lib/img/resume-template_00.png" alt="" style={{ width: "40%" }} />
            </div>
        </div>
    )
};
