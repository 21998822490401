import React, { useState } from 'react';

const HoverPhone = () => {
  const [flag, setFlag] = useState('btn1');

  return (
    <div className="card mt-3 border-0 shadow">
      <div className="tab-title">
        <div className="tab-title-nav-phone" style={{ textAlign: "center" }}>
          <ul>
            <li>
              <div
                className={`tab-button-phone ${flag === 'btn1' ? 'tab-btn1' : 'tab-btn'
                  }`}
                onMouseOver={() => setFlag('btn1')}
                onFocus={() => setFlag('btn1')}
              >
                平台简介
              </div>
            </li>
            <li>
              <div
                className={`tab-button-phone ${flag === 'btn2' ? 'tab-btn1' : 'tab-btn'
                  }`}
                onMouseOver={() => setFlag('btn2')}
                onFocus={() => setFlag('btn2')}
              >
                人才招聘
              </div>
            </li>
            <li>
              <div
                className={`tab-button-phone ${flag === 'btn3' ? 'tab-btn1' : 'tab-btn'
                  }`}
                onMouseOver={() => setFlag('btn3')}
                onFocus={() => setFlag('btn3')}
              >
                商业活动
              </div>
            </li>
            <li>
              <div
                className={`tab-button-phone ${flag === 'btn4' ? 'tab-btn1' : 'tab-btn'
                  }`}
                onMouseOver={() => setFlag('btn4')}
                onFocus={() => setFlag('btn4')}
              >
                咨询培训
              </div>
            </li>
            <li>
              <div
                className={`tab-button-phone ${flag === 'btn5' ? 'tab-btn1' : 'tab-btn'
                  }`}
                onMouseOver={() => setFlag('btn5')}
                onFocus={() => setFlag('btn5')}
              >
                产教融合
              </div>
            </li>
          </ul>
        </div>
        <div className="tab-title-con-phone">
          <div className={`${flag === 'btn1' ? 'tab-con' : 'tab-con1'}`}>
            <h6 className="title-line-phone">简要介绍</h6>
            <div className="hover-list-left-phone">
              <ul>
                <li>
                  <div className="hover-list-phone">运营满 10 年</div>
                </li>
                <li>
                  <div className="hover-list-phone">线上服务 3010 天</div>
                </li>
                <li>
                  <div className="hover-list-phone">平台活跃270,000优质求职者</div>
                </li>
                <li>
                  <div className="hover-list-phone">累计发布85,000,000个职位需求</div>
                </li>
              </ul>
            </div>
            <img src="./lib/img/xixun.jpg" alt="" style={{ width: "100%", paddingTop: "10px" }} />
          </div>
          <div className={`${flag === 'btn2' ? 'tab-con' : 'tab-con1'}`}>
            <h6 className="title-line-phone">主要服务</h6>
            <div className="hover-list-left-phone">
              <ul>
                <li>
                  <div className="hover-list-phone">校园招聘</div>
                </li>
                <li>
                  <div className="hover-list-phone">社会招聘</div>
                </li>
                <li>
                  <div className="hover-list-phone">RPO服务</div>
                </li>
                <li>
                  <div className="hover-list-phone">猎头服务</div>
                </li>
              </ul>
            </div>
            <img src="./lib/img/2021zhaopin.jpg" alt="" style={{ width: "100%", paddingTop: "10px" }} />
          </div>
          <div className={`${flag === 'btn3' ? 'tab-con' : 'tab-con1'}`}>
            <h6 className="title-line-phone">活动策划</h6>
            <div className="hover-list-left-phone">
              <ul>
                <li>
                  <div className="hover-list-phone">赛事策划</div>
                </li>
                <li>
                  <div className="hover-list-phone">论坛会议</div>
                </li>
                <li>
                  <div className="hover-list-phone">行业沙龙</div>
                </li>
                <li>
                  <div className="hover-list-phone">展示展览</div>
                </li>
              </ul>
            </div>
            <img src="./lib/img/2021business.jpg" alt="" style={{ width: "100%", paddingTop: "10px" }} />
          </div>
          <div className={`${flag === 'btn4' ? 'tab-con' : 'tab-con1'}`}>
            <h6 className="title-line-phone">规划指导</h6>
            <div className="hover-list-left-phone">
              <ul>
                <li>
                  <div className="hover-list-phone">学涯指导</div>
                </li>
                <li>
                  <div className="hover-list-phone">职涯规划</div>
                </li>
                <li>
                  <div className="hover-list-phone">素质提升</div>
                </li>
                <li>
                  <div className="hover-list-phone">职业指导</div>
                </li>
              </ul>
            </div>
            <img src="./lib/img/consultation.png" alt="" style={{ width: "100%", paddingTop: "10px" }} />
          </div>
          <div className={`${flag === 'btn5' ? 'tab-con' : 'tab-con1'}`}>
            <h6 className="title-line-phone">教学培养</h6>
            <div className="hover-list-left-phone">
              <ul>
                <li>
                  <div className="hover-list-phone">联合办学</div>
                </li>
                <li>
                  <div className="hover-list-phone">订单培养</div>
                </li>
                <li>
                  <div className="hover-list-phone">定制培养</div>
                </li>
                <li>
                  <div className="hover-list-phone">产业学院</div>
                </li>
              </ul>
            </div>
            <img src="./lib/img/education.png" alt="" style={{ width: "100%", paddingTop: "10px" }} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default HoverPhone;
