import React, { useEffect, useState } from 'react';

import md5 from 'blueimp-md5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const Recover = () => {
  const [data, setData] = useState({
    code: '',
    email: '',
    password1: '',
    password2: '',
  });

  const [employer, setEmployer] = useState({
    id: 0,
    uuid: 0,
  });

  const [count, setCount] = useState({
    countdown: 60,
    flag: true,
  });

  const [err, setErr] = useState({
    code: '',
    email: '',
    password1: '',
    password2: '',
  });

  useEffect(() => {
    sessionStorage.removeItem('auth');
  }, []);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSigin = async () => {
    const errData = {};

    Object.getOwnPropertyNames(data).forEach((key) => {
      if (data[key].trim() === '') {
        errData[key] = '请填写内容';
      }
    });

    if (Object.getOwnPropertyNames(errData).length !== 0) {
      setErr(errData);
      window.console.info(errData);
      return;
    }

    if (data.password1 !== data.password2) {
      setErr(() => ({
        password1: '请确认密码',
        password2: '请确认密码',
      }));

      return;
    }
    fetch(`/crate-api/staging?option=filterBy-refId-tag-detail&refId=${employer.id}&tag=验证码&detail=${JSON.stringify({ category: "企业用户" })}`)
      .then((response) => response.json())
      .then((res) => {
        res.forEach((item) => {
          if (data.code === item.detail.code) {
            fetch(`/gin-api/employer-user/${employer.uuid}/${employer.id}?option=resetPassword`, {
              method: 'PATCH',
              headers: { 'content-type': 'application/json' },
              body: JSON.stringify({
                password: md5(data.password1),
                code: data.code,
                email: data.email,
                category: '企业用户',
              })
            })
              .then((response) => {
                if (response.status > 400) {
                  window.alert("操作失败，请稍后重试!");
                  return;
                } else {
                  window.alert('操作成功');
                  location.href = '#登录';
                }
              })
          } else {
            console.log("验证码错误");
          }
        })
      })
    // const response = await fetch('/api/ent-user/recover/', {
    //   method: 'PUT',
    //   headers: { 'content-type': 'application/json' },
    //   body: JSON.stringify({
    //     password: md5(data.password1),
    //     code: data.code,
    //     email: data.email,
    //     user_category: '企业用户',
    //   }),
    // });
    // const res = await response.json();
    // if (res.message) {
    //   let alertFlg = false;
    //   if (typeof res.message === 'object') {
    //     Object.getOwnPropertyNames(res.message).forEach((key) => {
    //       switch (key) {
    //         case 'code':
    //           errData[key] = '验证码错误';
    //           break;
    //         default:
    //           alertFlg = true;
    //       }
    //     });
    //   } else {
    //     alertFlg = true;
    //   }
    //   if (alertFlg) {
    //     window.alert('服务器错误，请稍后重试');
    //   }
    //   setErr(errData);
    // } else {
    //   window.alert('操作成功');
    //   location.href = '#登录';
    // }
  };

  const handleCode = () => {
    const reg =
      /^[a-zA-Z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/;
    if (reg.test(data.email) === false) {
      window.alert('请检查邮箱格式是否输入正确');
      return;
    }
    fetch(`/gin-api/employer-user?option=filterBy-email&email=${data.email}`)
      .then((response) => response.json())
      .then((data) => {
        setEmployer({
          id: data.id,
          uuid: data.uuid,
        });
        if (data.id !== 0) {
          const code = setInterval(() => {
            if (count.countdown === 1) {
              clearInterval(code);
              setCount({
                flag: true,
                countdown: 60,
              });
            } else {
              setCount({
                flag: false,
                countdown: (count.countdown -= 1),
              });
            }
          }, 1000);
          fetch("/crate-api/email/actions/send-mail-2-reset-password", {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({
              refId: data.id,
              email: data.email,
              detail: { category: "企业用户" }
            }),
          })
            .then((response) => {
              if (response.status === 200) {
                alert("验证码已经到邮箱，请注意查收！");
              } else {
                alert("验证码发送失败，请您稍后重试");
              }
            })
        } else {
          window.alert("您输入的邮箱还没有注册本平台,请您重新输入！");
        }
      })

    // fetch('./api/ent-user/checkRecover/', {
    //   method: 'PUT',
    //   headers: { 'content-type': 'application/json' },
    //   body: JSON.stringify({
    //     email: data.email,
    //   }),
    // })
    //   .then((res) => res.json())
    //   .then((res) => {
    //     if (res.message) {
    //       window.alert(res.message);
    //     } else {
    //       fetch('./api/email/', {
    //         method: 'PUT',
    //         headers: { 'content-type': 'application/json' },
    //         body: JSON.stringify({
    //           email: data.email,
    //           code: data.code,
    //           user_category: '企业用户',
    //         }),
    //       })
    //         .then((res1) => res1.json())
    //         .then((res1) => {
    //           if (res1.message) {
    //             window.alert('验证码发送失败，请您稍后重试');
    //           } else {
    //             window.alert('验证码已发送到公司邮箱');
    //           }
    //         });
    //     }
    //   });

  };

  const checkEmail = () => {
    if (data.email === '') {
      return true;
    }
  };

  return (
    <div className="container-fluid bg-white body-login">
      <div className='row'>
        <div className="col-5 signin-left">
          <img
            className="img-fluid pull-left signIn-logo"
            alt=""
            src="https://ljxzjypt-1305084203.cos.ap-beijing.myqcloud.com/signIn_logo.png"
          />
        </div>
        <div className='col-7'>
          <div className="pull-right">
            <a href="#登录" style={{ color: "#4a4a4a" }}>
              <FontAwesomeIcon icon={faTimes} fixedWidth size="lg" />
            </a>
          </div>
          <div className="signin-right">
            <div className="signin-right-inside">
              <div className="text-center">
                <h4><strong>忘记密码</strong></h4>
              </div>
              <form>
                <div className="form-group">
                  <label>企业邮箱</label>
                  <input
                    className="form-control signin-right-input"
                    type="email"
                    placeholder="企业邮箱"
                    name="email"
                    value={data.email}
                    onChange={handleChange}
                  />
                  {err.email && (
                    <small className="form-text text-danger">{err.phone}</small>
                  )}
                </div>

                <div className="form-group">
                  <label>验证码</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      value={data.code || ''}
                      name="code"
                      placeholder="验证码"
                      onChange={handleChange}
                      className="form-control signin-right-input"
                    />
                    <div className="input-group-append">
                      {count.flag ? (
                        <button
                          className="btn btn-primary signin-right-input"
                          type="button"
                          onClick={handleCode}
                          disabled={checkEmail()}
                        >
                          发送验证码
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary signin-right-input"
                          type="button"
                          disabled="disabled"
                        >
                          已发送{count.countdown}s
                        </button>
                      )}
                    </div>
                  </div>
                  {err.code && (
                    <small className="form-text text-danger">{err.code}</small>
                  )}
                </div>

                <div className="form-group">
                  <label>新密码</label>
                  <input
                    className="form-control signin-right-input"
                    type="password"
                    placeholder="密码"
                    name="password1"
                    autoComplete="off"
                    value={data.password1}
                    onChange={handleChange}
                  />
                  {err.password1 && (
                    <small className="form-text text-danger">
                      {err.password1}
                    </small>
                  )}
                </div>
                <div className="form-group">
                  <label>确认密码</label>
                  <input
                    className="form-control signin-right-input"
                    type="password"
                    placeholder="确认密码"
                    name="password2"
                    autoComplete="off"
                    value={data.password2}
                    onChange={handleChange}
                  />
                  {err.password2 && (
                    <small className="form-text text-danger">
                      {err.password2}
                    </small>
                  )}
                </div>
              </form>
              <div className="row mt-3 px-4 ">
                <div className="col">
                  <button
                    type="button"
                    className="mt-2 btn btn-login signin-right-input"
                    style={{ backgroundColor: '#fac93e' }}
                    onClick={handleSigin}
                  >
                    提交
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recover;
